import { useEffect, useState } from 'react';
import {
  getFirestore,
  collection,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import type { DatabaseTraySeason } from '@phyllome/common';
import type { FromFirebase } from '../../../types/types';

const useTraySeasonsCurrentByColID = ({
  brokerId,
  columnId,
  seasonId = undefined,
}: {
  brokerId: string;
  columnId: number;
  seasonId?: string;
}) => {
  const firestore = getFirestore();
  const [data, setData] = useState<FromFirebase<DatabaseTraySeason>[]>([]);

  useEffect(() => {
    const fetchTraySeasons = async () => {
      try {
        const documentReference = query(
          collection(firestore, 'traySeason'),
          where('brokerId', '==', brokerId),
          where('locationColumn', '==', Number(columnId)),
          seasonId
            ? where('seasonId', '==', seasonId)
            : where('isComplete', '==', false),
        );

        const querySnapshot = await getDocs(documentReference);
        const seasons: FromFirebase<DatabaseTraySeason>[] =
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as FromFirebase<DatabaseTraySeason>[];

        setData(seasons);
      } catch (err) {
        console.error(err);
      }
    };

    fetchTraySeasons();
  }, [firestore, brokerId, columnId, seasonId]);
  return data;
};

export default useTraySeasonsCurrentByColID;
