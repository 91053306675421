import type { Farm } from '@phyllome/common';
import useFarms from './useFarms';

const useFarm = (brokerId: string): Farm | undefined => {
  const farms = useFarms();

  return farms.find((farm) => {
    return farm.brokerId === brokerId;
  });
};

export default useFarm;
