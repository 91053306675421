import type { BadgeProps } from '@mui/material';
import { Tabs, Tab, Box, Badge, styled } from '@mui/material';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useControlQueue from './Tasks/hooks/useControlQueue';

interface IProps {
  children: React.ReactNode;
  selected: number;
  brokerId: string;
}

const Logs: FC<IProps> = ({ brokerId, children, selected }) => {
  const navigate = useNavigate();
  const controlQueue = useControlQueue(brokerId);
  const changesNum = controlQueue.length;

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <>
      <Box mb={2}>
        <Tabs value={selected} aria-label="Seasons Navigation">
          <Tab
            label="Alerts"
            value={0}
            onClick={() => navigate(`/broker/${brokerId}/logs/alerts`)}
          />
          <StyledBadge badgeContent={changesNum} color="primary" max={999}>
            <Tab
              label="Hardware Tasks"
              value={1}
              onClick={() => navigate(`/broker/${brokerId}/logs/tasks`)}
            />
          </StyledBadge>
          <Tab
            label="Activity"
            value={2}
            onClick={() => navigate(`/broker/${brokerId}/logs/activity`)}
          />
        </Tabs>
      </Box>
      <Box>{children}</Box>
    </>
  );
};

export default Logs;
