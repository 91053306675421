import type { DeviceType } from '@phyllome/common';
import { colors } from '@phyllome/common';

export const deviceTypeActiveColor = (
  deviceType: DeviceType,
  properties: any,
) => {
  switch (deviceType) {
    case 'nds':
      return properties?.valve ? colors.river : colors.ripening;
    case 'zone':
      return properties?.active ? colors.sunlight : colors.ripening;
    // add additional cases here as new devices are attached
    default:
      return colors.ripening;
  }
};

export default deviceTypeActiveColor;
