import type { FC } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { PhyconMUI } from '../../../components/puregui/Phycon/Phycon';
import { useAuth } from 'reactfire';
import useCustomConsole from '../../../components/Favourites/useCustomConsole';
import { ChartProvider } from '../../../components/Rechart/ChartContext/ChartContext';
import TimeSlider from '../../../components/TimeSlider/TimeSlider';
import Graph from '../../../components/Favourites/Graph';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Alert, AlertTitle, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDevices from '../../../data/hooks/collections/useDevices';
import CustomConsoleDeviceStatesAlexandria from './CustomConsoleDeviceStates/CustomConsoleDeviceStatesAlexandria';
import CustomConsoleDeviceStatesNursery from './CustomConsoleDeviceStates/CustomConsoleDeviceStatesNursery';
import Stacker from '../../../components/Stacker/Stacker';

const CustomConsole: FC<{ brokerId: string }> = ({ brokerId }) => {
  const auth = useAuth();
  const nav = useNavigate();

  const deviceData = useDevices(brokerId);

  const uid = auth.currentUser?.uid;
  const customComponents = useCustomConsole({
    brokerId,
    uid: uid || '',
    location: 'general',
  });

  // const cols = deviceType.isDesktop ? 2 : 1;
  // const rowHeight = deviceType.isDesktop ? 500 : 440;
  // const width = deviceType.isDesktop ? 1000 : 800;

  return (
    <ContainerPrimary
      fullWidth={true}
      title="Dashboard"
      icon={<PhyconMUI icon={ViewModuleIcon} />}
      button={
        customComponents.length > 0 && (
          <IconButton
            onClick={() => {
              nav(`/broker/${brokerId}/dashboard/edit`);
            }}
          >
            <SettingsIcon />
          </IconButton>
        )
      }
    >
      {brokerId === 'mcp-alexandria' && (
        <CustomConsoleDeviceStatesAlexandria deviceData={deviceData} />
      )}
      {brokerId === 'mcp-nursery' && (
        <CustomConsoleDeviceStatesNursery deviceData={deviceData} />
      )}
      {customComponents.length === 0 && (
        <Box>
          <Alert severity="info" variant="outlined">
            <AlertTitle>No favourites saved yet</AlertTitle>
            Please add some graphs to your dashboard by{' '}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => nav(`/broker/${brokerId}/device`)}
            >
              navigating to the device screen
            </span>
            , going to a graph and clicking on the star.
          </Alert>
        </Box>
      )}
      <ChartProvider>
        {customComponents.length > 0 && <TimeSlider />}
        <Stacker>
          {customComponents.map((item) => {
            return (
              <Graph
                key={item.id}
                brokerId={brokerId}
                deviceId={item.deviceId}
                name={item.name}
                heading={item.heading}
              />
            );
          })}
        </Stacker>
      </ChartProvider>
    </ContainerPrimary>
  );
};

export default CustomConsole;
