import type { TransferDeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import { Alert } from '@mui/material';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';

const GraphAir = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {
  return (
    <PaperSection heading="Transfer Device Graphs" mb={2}>
      <Alert severity="info">
        Graphs are not yet available for this device
      </Alert>
    </PaperSection>
  );
};

export default GraphAir;
