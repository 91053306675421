import { useEffect, useState, type FC } from 'react';
import { useUser } from 'reactfire';

type IsDeveloperProps = {
  children?: React.ReactNode;
};

const IsDeveloper: FC<IsDeveloperProps> = ({ children }) => {
  const user = useUser();
  const [isDeveloper, setIsDeveloper] = useState(false);

  useEffect(() => {
    const email = user.data?.email;

    if (email?.includes('mick@phyllome')
      || email?.includes('seb@phyllome.com')
      || email?.includes('indrawannonmuhamad@gmail')
    ) {
      setIsDeveloper(true);
    }
  }, [user]);

  if (!isDeveloper) return null;
  return <>{children}</>;
};

export default IsDeveloper;
