import React, { type Dispatch, type FC, type Reducer } from 'react';
import type { NDSDeviceFromAction } from '../../../types/types';

type SprinkerContextType = {
  filterForm: {
    firmwares: string[];
    waterRates: string[];
    nightOffFactors: string[];
    currentDays: number[];
  };
  firmwares: string[];
  waterRates: string[];
  nightOffFactors: string[];
  currentDays: number[];
  layers: {
    primaryLayer:
      | 'SIMPLE'
      | 'FIRMWARE'
      | 'VALVES'
      | 'WATERRATE'
      | 'NIGHTOFFFACTOR'
      | 'CURRENTDAY';
    secondaryLayer: string[];
  };
  sprinklers: NDSDeviceFromAction[];
};

const initialValues: SprinkerContextType = {
  filterForm: {
    firmwares: [],
    waterRates: [],
    nightOffFactors: [],
    currentDays: [],
  },
  firmwares: [],
  waterRates: [],
  nightOffFactors: [],
  currentDays: [],
  layers: {
    primaryLayer: 'SIMPLE',
    secondaryLayer: [],
  },
  sprinklers: [],
};

type SprinklerContextState = {
  state: SprinkerContextType;
  dispatch: Dispatch<SprinklerContextAction>;
};

type SprinklerContextAction =
  | {
    type: 'SET_PRIMARY_LAYER';
    payload:
        | 'SIMPLE'
        | 'FIRMWARE'
        | 'VALVES'
        | 'WATERRATE'
        | 'CURRENTDAY'
        | 'NIGHTOFFFACTOR';
  }
  | { type: 'SET_SPRINKLER_STATS'; payload: NDSDeviceFromAction[] }
  | { type: 'SET_FIRMWARES'; payload: string[] }
  // | { type: "SET_WATERRATES"; payload: number[] }
  | { type: 'SET_SECONDARY_LAYER'; payload: string[] };

const sprinklerContextReducer: Reducer<
  SprinkerContextType,
  SprinklerContextAction
> = (_state, action) => {
  switch (action.type) {

    case 'SET_PRIMARY_LAYER': {
      return {
        ..._state,
        layers: {
          ..._state.layers,
          primaryLayer: action.payload,
        },
      };
    }
    case 'SET_SECONDARY_LAYER': {
      return {
        ..._state,
        layers: {
          ..._state.layers,
          secondaryLayer: action.payload,
        },
      };
    }
    case 'SET_SPRINKLER_STATS': {
      const currentFirmwares = _state.firmwares.reduce(
        (acc, firmware) => {
          acc[firmware] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
      const currentWaterRates = _state.waterRates.reduce(
        (acc, waterRate) => {
          acc[waterRate] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
      const currentNightOffFactors = _state.nightOffFactors.reduce(
        (acc, nightOffFactor) => {
          acc[nightOffFactor] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      action.payload.forEach((sprinkler) => {
        currentFirmwares[sprinkler.properties.firmware] = true;
        currentWaterRates[sprinkler.properties.waterRate] = true;
        currentNightOffFactors[sprinkler.properties.nightOffFactor] = true;
      });
      return {
        ..._state,
        firmwares: Object.keys(currentFirmwares),
        waterRates: Object.keys(currentWaterRates),
        nightOffFactors: Object.keys(currentNightOffFactors),
      };
    }
    case 'SET_FIRMWARES': {
      return {
        ..._state,
        firmwares: action.payload,
      };
    }
    // case "SET_WATERRATES": {
    //   return {
    //     ..._state,
    //     waterRates: action.payload,
    //   };
    // }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

export const SprinklerContext = React.createContext<SprinklerContextState>({
  state: initialValues,
  dispatch: () => null,
});

export const SprinklerProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(
    sprinklerContextReducer,
    initialValues,
  );

  return (
    <SprinklerContext.Provider value={{ state, dispatch }}>
      {children}
    </SprinklerContext.Provider>
  );
};
