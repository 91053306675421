import type { Timestamp } from 'firebase/firestore';

const calculateAgeInDays = (
  start?: Timestamp | null,
  end?: Timestamp | null,
): number => {
  if (!start) return 0;
  const jsDate = start.toDate();
  const now = end ? end.toDate() : new Date();

  return Math.ceil((now.getTime() - jsDate.getTime()) / (1000 * 60 * 60 * 24));
};

export default calculateAgeInDays;
