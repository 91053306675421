import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { LiftDeviceState } from '@phyllome/common';
import { mqttDefinitionLiftConfigSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormLiftConfigSetSpeeds = ({
  latest,
}: {
  latest: FromFirebase<LiftDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionLiftConfigSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/lift/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionLiftConfigSet, {
    speed: latest?.speed || 0,
  });

  return (
    <PaperSection
      heading="Lift Conveyor Speed Configuration"
      subheading={mqttDefinitionLiftConfigSet.topic}
      mb={2}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              speed: data.speed,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set lift ${deviceId} conveyor speed default to ${data.speed}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          speed: yup.number().min(10).max(100).required(),
        })}
      >
        <p>Default conveyor speed (percentage).</p>
        <Stack spacing={2}>
          <TextFieldElement label="Speed" name="speed" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLiftConfigSetSpeeds;
