import type { DatabaseTraySeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import LatestDeviceData from '../../../../components/LatestDeviceData/LatestDeviceData';

const SideBarTraySeasonDebug = ({
  traySeasonData,
}: {
  traySeasonData: FromFirebase<DatabaseTraySeason>;
}) => {
  return (
    <>
      <PaperSection heading="Debug (trayseason data)" mt={2}>
        <LatestDeviceData data={traySeasonData} />
      </PaperSection>
    </>
  );
};

export default SideBarTraySeasonDebug;
