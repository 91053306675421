import type { TraybotDeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../../types/types';
import type { FC } from 'react';
import TraybotBlock from './TraybotBlock';

type ITraybotBlockGroupProps = {
  latest: FromFirebase<TraybotDeviceState>;
  rows: number;
};

const TraybotBlockGroup: FC<ITraybotBlockGroupProps> = ({ latest, rows }) => {
  const rowList = [...Array(rows).keys()].map((i) => i + 1); // note lift is row = 0

  return (
    <div>
      <TraybotBlock latest={latest} row={0} />
      {rowList.map((row, item) => (
        <TraybotBlock latest={latest} row={row} key={item} />
      ))}
    </div>
  );
};

export default TraybotBlockGroup;
