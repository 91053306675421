import { Tooltip } from '@mui/material';
import type { HTMLAttributes, CSSProperties } from 'react';
import React, { useState } from 'react';

type IProps = {
  shelf: number;
  row: number;
  shelves: number;
  zIndex?: number;
  children?: React.ReactNode;
  isSelected?: boolean;
  isGroupSelected?: boolean;
  rackBoxStyle?: React.CSSProperties;
  rackBoxStyleHover?: React.CSSProperties;
  rackBoxStyleSelected?: React.CSSProperties;
  rackBoxStyleGroupSelected?: React.CSSProperties;
  toolTip?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;
export const rackBoxCSS: CSSProperties = {
  boxSizing: 'border-box',
  width: '55px',
  height: '15px',
  fontSize: '8px',
  lineHeight: '15px',
  textAlign: 'center',
  border: '1px solid transparent',
  fontFamily: 'Azeret Mono, monospace',
};

const RackBox = ({
  shelf,
  row,
  shelves,
  children,
  zIndex = 10,
  style,
  isGroupSelected = false,
  rackBoxStyle = {},
  rackBoxStyleHover = {},
  rackBoxStyleSelected = {},
  rackBoxStyleGroupSelected = {},
  isSelected = false,
  toolTip,
  onClick = () => null,
}: IProps) => {
  const [hover, setHover] = useState(false);

  const height = shelves * 16;

  if (toolTip) {
    return (
      <Tooltip title={toolTip} arrow>
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onClick}
          style={{
            ...rackBoxCSS,
            zIndex: `${zIndex}`,
            overflow: 'hidden',
            position: 'absolute',
            top: `${height - shelf * 16}px`,
            left: `${row * 56}px`,
            userSelect: 'none',
            ...style,
            ...rackBoxStyle,
            ...(isGroupSelected ? rackBoxStyleGroupSelected : {}),
            ...(isSelected ? rackBoxStyleSelected : {}),
            ...(hover ? rackBoxStyleHover : {}),
          }}
        >
          {children}
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
        style={{
          ...rackBoxCSS,
          zIndex: `${zIndex}`,
          overflow: 'hidden',
          position: 'absolute',
          top: `${height - shelf * 16}px`,
          left: `${row * 56}px`,
          userSelect: 'none',
          ...style,
          ...rackBoxStyle,
          ...(isGroupSelected ? rackBoxStyleGroupSelected : {}),
          ...(isSelected ? rackBoxStyleSelected : {}),
          ...(hover ? rackBoxStyleHover : {}),
        }}
      >
        {children}
      </div>
    );
  }
};

export default RackBox;
