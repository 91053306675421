import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { AirDeviceState } from '@phyllome/common';
import { mqttDefinitionAirConfigSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormAirConfigSetHumidityOffset = ({
  state,
}: {
  state: FromFirebase<AirDeviceState>;
}) => {
  const { deviceId } = state;

  const controlLifecycle = useControlSend(
    mqttDefinitionAirConfigSet,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/air/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionAirConfigSet, {
    offsets: { humidity: state?.offsets_humidity || 0 },
  });

  return (
    <PaperSection
      mb={2}
      heading="Humidity Calibration Offset"
      subheading={mqttDefinitionAirConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              offsets: { humidity: data.offsets.humidity },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set humidity offset config to ${data.offsets.humidity}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          offsets: yup.object({ humidity: yup.number() }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="body1">
            Set the humidity offset against a calibrated measured reading.
          </Typography>
          <TextFieldElement
            label="Humidity Offset"
            name="offsets.humidity"
            type="text"
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormAirConfigSetHumidityOffset;
