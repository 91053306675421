import type { FC } from 'react';
import React from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import { CompassCalibration } from '@mui/icons-material';
import { PhyconMUI } from '../../../components/puregui/Phycon/Phycon';
import { useAuth, useFirestore } from 'reactfire';
import useCustomConsole from '../../../components/Favourites/useCustomConsole';
import { ChartProvider } from '../../../components/Rechart/ChartContext/ChartContext';
import Graph from '../../../components/Favourites/Graph';
import GridLayout from 'react-grid-layout';
import { Box, Button } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { colors } from '@phyllome/common';
import useNavSide from '../../../data/hooks/useNavSide';

const CustomConsoleEdit: FC<{ brokerId: string }> = ({ brokerId }) => {
  const auth = useAuth();
  const sidenav = useNavSide();
  const nav = useNavigate();
  const firestore = useFirestore();
  const uid = auth.currentUser?.uid;
  const customComponents = useCustomConsole({
    brokerId,
    uid: uid || '',
    location: 'general',
  });

  const cols = 2;
  const rowHeight = 38;
  const width = 1200;

  return (
    <>
      <ContainerPrimary
        title="Farm Dashboard Edit"
        icon={<PhyconMUI icon={CompassCalibration} />}
      >
        <p>Drag the graphs around to change the order of display</p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            nav(`/broker/${brokerId}`);
          }}
        >
          Done
        </Button>
        <ChartProvider>
          <GridLayout
            cols={cols}
            rowHeight={rowHeight}
            margin={[0, 0]}
            width={width}

            onLayoutChange={(layout) => {
              layout.forEach((item) => {
                const idx = item.y * cols + item.x;
                const ref = doc(firestore, 'customConsole', item.i);

                updateDoc(ref, {
                  order: idx / 10,
                });
              });
            }}
          >
            {customComponents.map((item, key) => {
              const row = Math.floor(key / cols);
              const col = key % cols;

              return (
                <Box
                  key={item.id}
                  data-grid={{
                    i: item.deviceType,
                    isDraggable: true,
                    isResizable: false,
                    x: col,
                    y: row,
                    w: 1,
                    h: 10,
                  }}
                >
                  <Box>
                    <EditWrapper
                      onClick={() => {
                        sidenav(`dashboard/graph/edit/${item.id}`);
                      }}
                    >
                      <Graph
                        brokerId={brokerId}
                        deviceId={item.deviceId}
                        name={item.name}
                        heading={item.heading}
                      />
                    </EditWrapper>
                  </Box>
                </Box>
              );
            })}
          </GridLayout>
        </ChartProvider>
      </ContainerPrimary>
    </>
  );
};

type EditWrapperProps = {
  onClick: () => void;
  children: React.ReactNode;
};

const EditWrapper: React.FC<EditWrapperProps> = ({ onClick, children }) => {
  return (
    <>

      <Box sx={{ position: 'relative', top: '40px'  }}>
        <EditWrapperButton onClick={() => onClick()}>Modify</EditWrapperButton>
      </Box>
      {children}
    </>
  );
};

const EditWrapperButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: '20px',
        lineHeight: '20px',
        fontSize: '11px',
        background: colors.earth2,
        color: 'white',
        display: 'inline-block',
        padding: '2px',
        cursor: 'pointer',

      }}
      onMouseDown={onClick}
    >
      {children}
    </Box>
  );
};

export default CustomConsoleEdit;
