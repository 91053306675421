import { useUser } from 'reactfire';

const useIsDeveloper = ():boolean => {

  const user = useUser();

  const email = user.data?.email;

  const isDev = !!(email?.includes('mick') || email?.includes('seb') || email?.includes('indrawannonmuhamad'));

  return isDev;

};

export default useIsDeveloper;