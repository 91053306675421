import { useEffect, useState } from 'react';
import type { DatabaseTraySeasonPhotos } from '@phyllome/common';
import {
  query,
  collection,
  where,
  orderBy,
  limit,
  getFirestore,
  getDocs,
} from 'firebase/firestore';
import type { FromFirebase } from '../../../../types/types';

type DatabasePhotoType = 'visible' | 'ir';

const useSeasonPhotos = ({
  seasonId,
  type = 'visible',
}: {
  seasonId: string;
  type: DatabasePhotoType;
}): FromFirebase<DatabaseTraySeasonPhotos>[] => {
  const [data, setData] = useState<FromFirebase<DatabaseTraySeasonPhotos>[]>(
    [],
  );
  const fs = getFirestore();

  useEffect(() => {
    const fetchTrayPhotos = async () => {
      const documentReference = query(
        collection(fs, 'traySeasonPhotos'),
        where('seasonId', '==', seasonId),
        where('type', '==', type),
        orderBy('timestamp', 'asc'),
        limit(1000),
      );

      try {
        const querySnapshot = await getDocs(documentReference);
        const trayPhotos = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as FromFirebase<DatabaseTraySeasonPhotos>[];

        setData(trayPhotos); // Set the fetched data
      } catch (error) {
        console.error('Error fetching tray photos:', error);
        setData([]); // Set to null in case of error
      }
    };

    fetchTrayPhotos();
  }, [fs, seasonId, type]); // Dependency array ensures this runs when fs or seasonId changes
  return data || []; // Return the data or an empty array
};

export default useSeasonPhotos;
