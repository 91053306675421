import type { LiftDeviceState } from '@phyllome/common';
import { colors } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HourglassTopSharpIcon from '@mui/icons-material/HourglassTopSharp';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import DoDisturbAltSharpIcon from '@mui/icons-material/DoDisturbAltSharp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RadioButtonUncheckedSharp from '@mui/icons-material/RadioButtonUncheckedSharp';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import type { ChipEnumData } from '../../components/puregui/ChipEnum/types';
import ChipEnum from '../../components/puregui/ChipEnum/ChipEnum';
import { Alert, Divider } from '@mui/material';

const liftTraybotEnumStates: ChipEnumData = [
  { label: 'Traybot', value: 0, icon: <RadioButtonUncheckedSharp /> },
  {
    label: 'Loading',
    value: 1,
    icon: <HourglassTopSharpIcon />,
    color: 'earth3',
  },
  {
    label: 'Loaded',
    value: 2,
    icon: <RadioButtonUncheckedSharp />,
    color: 'primary',
  },
];

const liftChipEnumStates: ChipEnumData = [
  {
    label: 'Lift Idle',
    value: 0,
    icon: <RadioButtonUncheckedSharp />,
  },
  {
    label: 'Transfer Mode',
    value: 1,
    color: 'primary',
    icon: <AutoModeSharpIcon />,
  },
  {
    label: 'Moving',
    value: 2,
    color: 'primary',
    icon: <PlayCircleOutlineIcon />,
  },
  {
    label: 'Homing',
    value: 3,
    color: 'sunlight',
    icon: <ReportSharpIcon />,
  },
  {
    label: 'Loading Mode',
    value: 4,
    color: 'primary',
    icon: <AutoModeSharpIcon />,
  },
  {
    label: 'Unloading Mode',
    value: 5,
    color: 'primary',
    icon: <AutoModeSharpIcon />,
  },
  {
    label: 'Disabled',
    value: 6,
    color: 'earth3',
    icon: <ReportSharpIcon />,
  },
];

const LatestLiftInfo = ({
  latest,
}: {
  latest: FromFirebase<LiftDeviceState>;
}) => {
  const location = latest.shelf === undefined ? '-' : latest.shelf.toString();
  const destination =
    latest.destination === undefined ? '-' : latest.destination.toString();

  const latestShelfText =
    location +
    (latest.destination !== latest.shelf ? ' to ' + destination : '');

  const heroItems = [
    {
      label: 'Shelf',
      text: latestShelfText,
    },
  ];

  const paperSectionColor =
    latest.inhibit !== undefined && latest.inhibit === true
      ? colors.earth3
      : colors.night;

  return (
    <PaperSection
      mb={2}
      heading="Latest Lift Info"
      headerSx={{
        background: paperSectionColor,
      }}
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box
        mb={2}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
        }}
      >
        {latest.alias &&
          latest.alias.map((alias) => (
            <>
              <Box mr={2} ml={2}>
                <span>Column {alias}</span>
                <Divider sx={{ marginBottom: 1 }} />
                <ChipBool
                  data={latest.trays[alias]}
                  label="Tray"
                  color="primary"
                  tooltip="Tray present in column"
                />
                <ChipEnum
                  data={liftTraybotEnumStates}
                  value={
                    latest.shelf > 0 ? latest.traybots[alias as number] || 0 : 0
                  }
                  tooltip="State of traybot in column"
                />
                <ChipBool
                  data={latest.blocked[alias as number]}
                  label="Blocked"
                  color="sunlight"
                  icon={<DoDisturbAltSharpIcon />}
                  tooltip="Column blocked by traybot or software block"
                />
              </Box>
            </>
          ))}
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
      <Box mb={2}>
        <ChipBool
          data={latest.busy}
          label="Busy"
          color="earth3"
          icon={<HourglassTopSharpIcon />}
          tooltip="state of lift busy flag"
        />
        <ChipBool
          data={latest.conveyors_infeed || latest.conveyors_middle}
          label="Conveyors"
          color="primary"
          tooltip="Are conveyors running"
        />
        <ChipBool data={latest.lift_reference} label="Homed" color="primary" />
        <ChipBool data={latest.lift_motor} label="Motor" color="primary" />
        <ChipBool data={!latest.lift_brakeReleased} label="Brake" />
        <ChipBool
          data={latest.limits_bottom || latest.limits_top}
          label="Limit"
          color="earth3"
          icon={<ReportSharpIcon />}
        />
        <ChipBool
          data={latest.trays['infeed']}
          label="Infeed"
          color="primary"
        />
        <ChipBool
          data={latest.inhibit !== undefined && latest.inhibit === true}
          label="Inhibit"
          color="earth3"
          icon={<ErrorIcon />}
        />
      </Box>
      <Box mb={2}>
        <ChipEnum
          data={liftChipEnumStates}
          value={latest.state}
          tooltip="Lift controller state"
        />
      </Box>
      {latest.shelf === -1 && (
        <Box mb={2}>
          <Alert severity="info">
            Note! Traybots are not shown in console when lift shelf is -1 (i.e.
            lift is not in a known position).
          </Alert>
        </Box>
      )}
    </PaperSection>
  );
};

export default LatestLiftInfo;
