import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import { isEmpty } from 'lodash';

export const getErrorProps = (field: string, errors?: FieldErrors) => {
  if (!errors) {
    return {};
  } else {
    if (errors[field]) {
      return {
        error: true,
      };
    }
  }
};

export const formHasErrors = (errors: FieldErrors) => {
  return !isEmpty(errors);
};
