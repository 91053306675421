import type { TraybotDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestTraybot = (brokerId: string, deviceId: string) => {
  return useDeviceState<TraybotDeviceState>({
    brokerId,
    deviceId,
    deviceType: 'traybot',
  });
};

export default useLatestTraybot;
