/*
    These are constants that will eventually be replaced by hardware functions or database defintions.
    The goal of this file is to remove magic numbers from the app
*/

export const Constants = {
  NURSERY_FERTIGATOR_TANK_CAPACITY: 25000, // 25L in mL - replace with hardware level
  ALEXANDRIA_FERTIGATOR_TANK_CAPACITY: 250000, // 250L in mL - replace with hardware level
};

export default Constants;
