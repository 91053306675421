import { useContext } from 'react';
import RackBox from '../../../../components/factoryui/components/RackBox';
import type { NDSDeviceFromAction } from '../../../../types/types';
import getUIInfoBasedOnAge from '../helpers/getUIInfoBasedOnAge';
import { SprinklerContext } from '../SprinkerContext';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import CoordOrZone from '../helpers/CoordOrZone';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
};

const OverlaySpinklerSimple = ({ shelves, data }: OverlayTraySimpleProps) => {
  const now = new Date();

  const farmConfig = useContext(FarmContext);

  const sprinklerContext = useContext(SprinklerContext);

  const secondaryLayer = sprinklerContext?.state?.layers?.secondaryLayer;

  return (
    <>
      {data.map((row) => {
        const { timestamp, location_column, location_row, location_shelf } =
          row.properties;
        const { color } = getUIInfoBasedOnAge(now, new Date(timestamp));

        return (
          <RackBox
            key={row.deviceId}
            zIndex={10}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
            style={{
              textAlign: 'center',
              backgroundColor: color,
              color: 'white',
            }}
          >
            <CoordOrZone
              farmConfig={farmConfig} location={{
                column: location_column,
                row: location_row,
                shelf: location_shelf,
              }}
              useZone={secondaryLayer?.includes('Zones')}
            />

          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySpinklerSimple;
