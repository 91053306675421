import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import { Alert, Stack, AlertTitle } from '@mui/material';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { IrrigationDeviceState } from '@phyllome/common';
import {
  mqttDefinitionIrrigationSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormIrrigationSetModesTransfer = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionIrrigationSet, {
    modes: {
      transfer:
        (latest?.valves_transfer || false) && (latest?.pumps_transfer || false),
    },
  });

  return (
    <PaperSection
      heading="Manual transfer mode control"
      mb={2}
      subheading={mqttDefinitionIrrigationSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              modes: { transfer: data.modes.transfer },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set transfer mode to ${data.modes.transfer ? 'On' : 'Off'}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          modes: yup.object({
            transfer: yup.boolean().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <Alert severity="success">
            <AlertTitle>Transfer Auto-Shutoff Always On</AlertTitle>
            Transfer mode will automatically turn off when the return tank
            reaches the minimum tank level of{' '}
            <strong>{latest.return_min}%</strong>. (This limit can be changed in
            CONFIG).
          </Alert>
          <SwitchElement label="Transfer Mode" name="modes.transfer" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to Device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationSetModesTransfer;
