import type { DatabaseSeason, NDSSchedule } from '@phyllome/common';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { FromFirebase } from '../../../../types/types';
import useSeed from '../../../../data/hooks/collections/useSeed';
import { useEndpointSeasonUpdate } from '../../../../data/endpointHooks/endpointHooks';
import { Box, Button } from '@mui/material';
import NDSScheduleEdit from '../../../../components/NDSScheduleEdit/NDSScheduleEdit';
import useAction from '../../../../data/hooks/useAction';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import calculateAgeInDays from '../../../../helpers/calculateAgeInDays';
import ScheduleViewNDS from '../../../../components/ScheduleView/ScheduleViewNDS';
import ScheduleTableNDS from '../../../../components/ScheduleTable/ScheduleTableNDS';

type IProps = {
  seasonData: FromFirebase<DatabaseSeason>;
};

const SideBarSeasonNDSSchedule: FC<IProps> = ({ seasonData }) => {
  const [sched, setSched] = useState<NDSSchedule[]>([]);
  const [spinning, setSpinning] = useState<boolean>(false);
  const seedData = useSeed(seasonData.seedId || 'nothing');
  const trayUpdate = useEndpointSeasonUpdate();

  const traySeasonWriteNDSSchedule = useAction('traySeasonWriteNDSSchedule');

  useEffect(() => {
    setSched(seasonData?.defaultNDSSchedule || []);
  }, [seasonData?.defaultNDSSchedule]);
  if (seasonData?.state === 'completed') {
    return (
      <PaperSection
        heading="Irrigation Schedule"
        mb={2}
      >
        <ScheduleTableNDS scheduleDataDB={seasonData?.defaultNDSSchedule || []} />
      </PaperSection>);
  } else {

    return (
      <PaperSection
        heading="Irrigation Schedule"
        mb={2}
      >
        <Box mb={2}>
          <ScheduleViewNDS
            seedSchedule={seedData?.defaultIrrigationSchedule}
            seasonSchedule={seasonData?.defaultNDSSchedule}
            context="season"
          />
        </Box>
        {
          <>
            <Box mb={2} textAlign="center">
              <Button
                disabled={spinning}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSpinning(true);
                  traySeasonWriteNDSSchedule.run({
                    brokerId: seasonData.brokerId,
                    seasonId: seasonData.id,
                    schedule: sched,
                    currentDay: calculateAgeInDays(seasonData.growthStarted),
                  });
                }}
              >
                send schedule to all sprinklers in season
              </Button>
              {/* <ControlConnect data={lifeCycleRequest} /> */}
            </Box>
          </>
        }
        <NDSScheduleEdit
          scheduleDataDB={sched}
          onUpdate={(data) => {
            trayUpdate.run({
              brokerId: seasonData.brokerId,
              id: seasonData.id,
              defaultNDSSchedule: data,
            });
          }}
        />
      </PaperSection>
    );
  }
};

export default SideBarSeasonNDSSchedule;
