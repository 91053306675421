import type { DatabaseSeed } from '@phyllome/common';
import {
  type DatabaseSeason,
  type DatabaseTraySeason,
  compareSchedules,
  mqttDefinitionNDSSet,
} from '@phyllome/common';
import { type FC, useEffect, useState } from 'react';
import type { FromFirebase } from '../../../../types/types';
import useSeed from '../../../../data/hooks/collections/useSeed';
import { useEndpointTraySeasonUpdate } from '../../../../data/endpointHooks/endpointHooks';
import { Box, Button } from '@mui/material';
import useControlSend from '../../../../data/hooks/useControlSend';
import ControlConnect from '../../../../components/ControlConnect/ControlConnect';
import NDSScheduleEdit from '../../../../components/NDSScheduleEdit/NDSScheduleEdit';
import calculateAgeInDays from '../../../../helpers/calculateAgeInDays';
import useNDSByLocation from '../../../../devicespecific/NDS/hooks/useNDSByLocation';
import ScheduleViewNDS from '../../../../components/ScheduleView/ScheduleViewNDS';

type IProps = {
  traySeasonId: string;
  seasonData: FromFirebase<DatabaseSeason>;
  locationColumn: number;
  locationShelf: number;
  locationRow: number;
  traySeasonData: FromFirebase<DatabaseTraySeason>;
};

const TraySeasonNDSSchedule: FC<IProps> = ({
  traySeasonId,
  seasonData,
  traySeasonData,
}) => {
  const lifeCycleRequest = useControlSend(
    mqttDefinitionNDSSet,
    `${traySeasonId}`,
  );
  const [sched, setSched] = useState<DatabaseSeed['defaultIrrigationSchedule']>(
    [],
  );
  const [needsToBeUpdated, setNeedsToBeUpdated] = useState<boolean>(false);
  const seedData = useSeed(seasonData.seedId || 'nothing');
  const trayUpdate = useEndpointTraySeasonUpdate();

  const { msg } = useNDSByLocation({
    column: traySeasonData.locationColumn,
    shelf: traySeasonData.locationShelf,
    row: traySeasonData.locationRow,
  });

  const sprinklerData = msg?.properties;

  useEffect(() => {
    // there's no default schedule
    let s4;

    if (!traySeasonData?.defaultNDSSchedule?.length) {
      s4 = compareSchedules(
        seedData?.defaultIrrigationSchedule,
        sprinklerData?.schedule as any,
      );
    } else {
      s4 = compareSchedules(
        sprinklerData?.schedule as any,
        traySeasonData?.defaultNDSSchedule,
      );
    }

    setNeedsToBeUpdated(!s4);
    if (traySeasonData?.defaultNDSSchedule?.length) {
      setSched(traySeasonData.defaultNDSSchedule);
    } else {
      setSched(seedData?.defaultIrrigationSchedule || []);
    }
  }, [
    seedData?.defaultIrrigationSchedule,
    sprinklerData?.schedule,
    traySeasonData?.defaultNDSSchedule,
  ]);
  return (
    <>
      <Box mb={2}>
        <ScheduleViewNDS
          seedSchedule={seedData?.defaultIrrigationSchedule}
          seasonSchedule={seasonData?.defaultNDSSchedule}
          traySchedule={traySeasonData?.defaultNDSSchedule}
          sprinklerSchedule={sprinklerData?.schedule}
          context="tray"
        />
      </Box>
      {needsToBeUpdated && (
        <Box mb={4} textAlign="center">
          <Button
            disabled={
              lifeCycleRequest.status === 'started' ||
              lifeCycleRequest.status === 'mcp'
            }
            variant="contained"
            color="secondary"
            onClick={() => {
              console.log(
                JSON.stringify({
                  payload: {
                    timestamp: new Date().toISOString(),
                    schedule: sched,
                    currentDay: calculateAgeInDays(seasonData.growthStarted),
                  },
                  topicParams: {
                    column: traySeasonData.locationColumn,
                    shelf: traySeasonData.locationShelf,
                    row: traySeasonData.locationRow,
                  },
                }),
              );
              lifeCycleRequest.run({
                payload: {
                  schedule: sched,
                  currentDay: calculateAgeInDays(seasonData.growthStarted),
                },
                topicParams: {
                  column: String(traySeasonData.locationColumn),
                  shelf: String(traySeasonData.locationShelf),
                  row: String(traySeasonData.locationRow),
                },
              });
            }}
          >
            send schedule to single sprinkler
          </Button>
          <ControlConnect data={lifeCycleRequest} />
        </Box>
      )}
      <NDSScheduleEdit
        scheduleDataDB={sched}
        onUpdate={(data) => {
          trayUpdate.run({
            brokerId: traySeasonData.brokerId,
            id: traySeasonId,
            defaultNDSSchedule: data,
          });
        }}
      />
    </>
  );
};

export default TraySeasonNDSSchedule;
