import type { LiftDeviceState } from '@phyllome/common';
import { mqttDefinitionLiftConfigGet } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormLiftConfigSetColumn from './forms/FormLiftConfigSetColumn';
import FormLiftConfigSetSensors from './forms/FormLiftConfigSetSensors';
import FormLiftConfigSetSpeeds from './forms/FormLiftConfigSetSpeeds';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigLift = ({ latest }: { latest: FromFirebase<LiftDeviceState> }) => {
  return (
    <>
      <PaperSection heading="Lift Controller Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Aliases">{String(latest.alias)}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">TBD</LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormLiftConfigSetSensors latest={latest} />
      <FormLiftConfigSetSpeeds latest={latest} />
      <FormLiftConfigSetColumn latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionLiftConfigGet}
      />
    </>
  );
};

export default ConfigLift;
