import type { Co2DeviceState } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import unflatten from '../../helpers/unflatten';
import LatestDeviceData from '../../components/LatestDeviceData/LatestDeviceData';

const LatestCo2 = ({ latest }: { latest: FromFirebase<Co2DeviceState> }) => {

  const unflattened = unflatten(latest);

  return (
    <PaperSection
      heading="Latest Co2 Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <LatestDeviceData data={unflattened as any} />

    </PaperSection>
  );
};

export default LatestCo2;
