import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, query } from 'firebase/firestore';
import type { DatabaseSeed } from '@phyllome/common';
import type { FromFirebase } from '../../../types/types';

type UseSeedParams = {
  validatedOnly?: boolean;
};

const useSeeds = (
  props: UseSeedParams = {
    validatedOnly: false,
  },
): FromFirebase<DatabaseSeed>[] => {
  const firestore = getFirestore();
  const [data, setData] = useState<FromFirebase<DatabaseSeed>[]>([]);

  useEffect(() => {
    const fetchSeeds = async () => {
      try {
        const documentReference = query(collection(firestore, 'seed'));
        const querySnapshot = await getDocs(documentReference);
        const seeds: FromFirebase<DatabaseSeed>[] = querySnapshot.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          }),
        ) as FromFirebase<DatabaseSeed>[];

        if (props.validatedOnly) {
          const filteredSeeds = seeds.filter(
            (seed) => seed.defaultIrrigationSchedule.length > 0,
          );

          filteredSeeds.sort((a, b) => {
            if (a.name === b.name) {
              return a.variety.localeCompare(b.variety);
            }
            return a.name.localeCompare(b.name);
          });
          setData(filteredSeeds);
        } else {
          seeds.sort((a, b) => {
            if (a.name === b.name) {
              return a.variety.localeCompare(b.variety);
            }
            return a.name.localeCompare(b.name);
          });
          setData(seeds);
        }
      } catch {
        setData([]); // In case of an error, set data to an empty array
      }
    };

    fetchSeeds();
  }, [firestore, props.validatedOnly]);
  return data; // Return the fetched data or an empty array
};

export default useSeeds;
