import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import type { AnyObject, PubSubDefinitionAny } from '@phyllome/common';
import { doc, type Timestamp } from 'firebase/firestore';
import getRid from '../../helpers/getRid';
import FarmContext from '../../components/FarmProvider/FarmContext';
import { useSnackbar } from 'notistack';
import useAction from './useAction';
import { floor } from 'lodash';

type Status = 'started' | 'idle' | 'timeout' | 'done' | 'error' | 'mcp';

const TIMEOUT_MS = 20000;

type PhormPayload<T extends PubSubDefinitionAny> = Omit<T['payload'], 'rid'>;
type RunFunc<T extends PubSubDefinitionAny> = {
  payload: PhormPayload<T> & { timestamp?: string };
  topicParams?: T['_topicParams'];
};
export type ControlSend = {
  run: (arg: RunFunc<any>) => Promise<string>;
  status: Status;
  payload: AnyObject;
  isReady: boolean;
  runtime: number;
  rid: number;
  retryCount: number;
};

const useControlSend = <T extends PubSubDefinitionAny>(
  def: T,
  hash: string,
): ControlSend => {
  const { brokerId } = useContext(FarmContext);

  const [rid, setRid] = useState<number>(0);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [status, setStatus] = useState<Status>('idle');
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const action = useAction('controlSend');

  const documentReference = doc(
    useFirestore(),
    'control',
    `${brokerId}.${rid}`,
  );
  const { data } = useFirestoreDocData(documentReference, {
    idField: 'id',
  });

  useEffect(() => {
    const thisRid = getRid();

    setRid(thisRid);
    setRetryCount(0);
  }, [hash]);

  useEffect(() => {
    if (!data) {
      setStatus('idle');
      return;
    }

    setStatus(data.status);
    setRetryCount(data.retryCount || 0);
  }, [data]);

  useEffect(() => {
    if (status === 'started') {
      // Clear any existing timeout to prevent multiple instances
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      const id = setTimeout(() => {
        setStatus('timeout');
        const text = `Control message timed out after ${floor(TIMEOUT_MS / 1000)} seconds. Please try again.`;

        enqueueSnackbar(text, { variant: 'error' });
      }, TIMEOUT_MS);

      timeoutIdRef.current = id;
    } else {
      // Clear the timeout if status is not 'started'
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null; // Clear the ref
      }
    }

    if (status === 'done') {
      const text = `Control message successfully completed in ${runtime}ms`;

      enqueueSnackbar(text, { variant: 'success' });
    }
    // Cleanup on unmount
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]); // Removed timeoutId from dependencies

  /**
   *

            lift: {
              raisedLimit: data.lift.raisedLimit,
              loweredLimit: data.lift.loweredLimit,
            drive: {
              velocityLimit: data.drive.velocityLimit,
              homingLimit: data.drive.homingLimit,
              positionWidth: data.drive.positionWidth,
              liftOffset: data.drive.liftOffset,
              driveGearRatio: data.drive.driveGearRatio,
              chargerOffset: data.drive.chargerOffset,
              forwardDirection: data.drive.forwardDirection,
              homeOnStartup: data.drive.homeOnStartup,
              holdOnLift: data.drive.holdOnLift,
              homeOnEdge: data.drive.homeOnEdge,
              updateOnTray: data.drive.updateOnTray,
              accelFast: data.drive.accelFast,
              accelSlow: data.drive.accelSlow,
            },
            imu: {
              saveReferenceAttitude: data.imu.saveReferenceAttitude, // Corrected property name
              calibrate: data.imu.calibrate, // Corrected property name
              crashThreshold: data.imu.crashThreshold, // Corrected property name
              enableTelemetry: data.imu.enableTelemetry, // Corrected property name
            },
   */
  const run = useCallback(
    async (data: RunFunc<T>): Promise<string> => {
      // artifically add the timestamp
      data.payload.timestamp = new Date().toISOString();

      setStatus('started');

      const payload = action.run({
        brokerId,
        rid,
        topicId: def.id,
        topicParams: data?.topicParams || {},
        payload: data.payload,
      });

      return payload;
    },
    [def.id, brokerId, rid, action],
  );

  let runtime = 0;

  if (data?.timestampBegin && data?.timestampEnd) {
    runtime = calculateTimestampDifferenceInMilliseconds(
      data.timestampBegin,
      data.timestampEnd,
    );
  }

  const isReady =
    status === 'timeout' ||
    status === 'error' ||
    status === 'idle' ||
    status === 'done';

  return {
    isReady,
    run,
    status,
    payload: data as AnyObject,
    runtime,
    rid: rid,
    retryCount,
  };
};

function calculateTimestampDifferenceInMilliseconds(
  timestamp1: Timestamp,
  timestamp2: Timestamp,
): number {
  const milliseconds1 = timestamp1.toMillis();
  const milliseconds2 = timestamp2.toMillis();

  return Math.abs(milliseconds1 - milliseconds2);
}
export default useControlSend;
