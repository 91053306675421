import type { DeviceType } from '@phyllome/common';
import type { FC } from 'react';
import { useContext, useMemo } from 'react';
import PaperSection from '../puregui/PaperSection/PaperSection';
import RechartVictoryEnum from './RechartVictoryEnum';
import FavGraph, { FavGraphWrap } from '../FavGraph/FavGraph';
import type { FavouriteName } from '../Favourites/collectionFavourites';
import { Alert, AlertTitle, Box, Skeleton } from '@mui/material';
import useTimeSeriesPoller from './useTimeSeriesPoller';
import { ChartContext } from './ChartContext/ChartContext';

export type RechartVictoryHeadingProps = {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
  field: string;
  color: string;
  heading: string;
  mapping: { [key: string]: string };
  name: FavouriteName;
};

const RechartVictoryEnumHeading: FC<RechartVictoryHeadingProps> = ({
  brokerId,
  deviceId,
  deviceType,
  field,
  color,
  heading,
  mapping,
  name,
}) => {
  const {
    state: { beginDate, endDate },
  } = useContext(ChartContext);

  const timeSeries = useTimeSeriesPoller({
    brokerId,
    deviceId,
    deviceType,
    origin: field,
    type: 'enum',
  });

  const mappings = useMemo(() => {
    return [' ', ...Object.values(mapping), '  '];
  }, [mapping]);

  const height = useMemo(() => {
    return (mappings.length - 1) * 20;
  }, [mappings]);

  if (timeSeries.isInitiating) {
    return (
      <PaperSection heading={heading}>
        <Skeleton
          variant="rectangular"
          height={height || 300}
          animation="wave"
        />
      </PaperSection>
    );
  }

  if (timeSeries.isUnsufficientData) {
    <PaperSection heading={heading}>
      <Box sx={{ height: height || 300 }}>
        <Alert severity="error">
          <AlertTitle>Unable to load chart</AlertTitle>Insufficient data
          available in timeSeries.
        </Alert>
      </Box>
    </PaperSection>;
  }
  return (
    <PaperSection heading={heading}>
      <FavGraphWrap isUpdating={timeSeries.isUpdating}>
        <FavGraph deviceId={deviceId} brokerId={brokerId} name={name} />
      </FavGraphWrap>
      <RechartVictoryEnum
        color={color}
        timeSeries={timeSeries}
        mapping={mapping}
        beginDate={beginDate}
        endDate={endDate}
      />
    </PaperSection>
  );
};

export default RechartVictoryEnumHeading;
