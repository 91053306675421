import { useEffect, useState } from 'react';
import { getFirestore, collection, doc, onSnapshot } from 'firebase/firestore';
import type { AnyObject, DeviceType } from '@phyllome/common';
import type { DeviceStateFirestore } from '../../devicespecific/deviceTypes';

const useDeviceState = <T extends AnyObject>({
  brokerId,
  deviceId,
  deviceType,
}: {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
}): DeviceStateFirestore<T> => {
  const [state, setState] = useState<DeviceStateFirestore<T>>();

  useEffect(() => {
    const id = `${brokerId}.${deviceType}.${deviceId}`;
    const db = getFirestore();
    const documentReference = doc(collection(db, 'devices'), id);

    const unsubscribe = onSnapshot(
      documentReference,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = {
            id: snapshot.id,
            ...snapshot.data(),
          } as DeviceStateFirestore<T>;

          setState(data);
        } else {
          setState(undefined);
        }
      },
      () => {
        setState(undefined);
      },
    );

    // Cleanup subscription
    return () => unsubscribe();
  }, [brokerId, deviceId, deviceType]);

  const ret = state?.properties as DeviceStateFirestore<T>;

  return ret;
};

export default useDeviceState;
