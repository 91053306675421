import type { NDSDeviceFromAction } from '../../types/types';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import FormNDSConfigSetWaterRate from './forms/FormNDSConfigSetWaterRate';
import FormNDSConfigSetWithDeviceColours from './forms/FormNDSConfigSetColours';
import FormNDSConfigSetWithDeviceHeartBeat from './forms/FormNDSConfigSetHeartBeat';
import FormNDSConfigSetWithDeviceNightFactor from './forms/FormNDSConfigSetNightFactor';
import { mqttDefinitionNDSConfigGet } from '@phyllome/common';
import FormNDSDeviceConfigGet from '../../components/FormNDSDeviceConfigGet/FormNDSDeviceConfigGet';

const ConfigNDS = ({ deviceState }: { deviceState: NDSDeviceFromAction }) => {
  const firmware = deviceState.properties.firmware || 'Unknown';

  return (
    <>
      <PaperSection heading="NDS Device Configuration" mb={2}>
        <LabelValue label="Device ID">{deviceState.deviceId}</LabelValue>
        <LabelValue label="Firmware">{firmware}</LabelValue>
        <LabelValue label="Build date">TBD</LabelValue>
        <LabelValue label="Network IP">TBD</LabelValue>
      </PaperSection>
      <FormNDSConfigSetWaterRate deviceState={deviceState} />
      <FormNDSConfigSetWithDeviceNightFactor deviceState={deviceState} />
      <FormNDSConfigSetWithDeviceHeartBeat deviceState={deviceState} />
      <FormNDSConfigSetWithDeviceColours deviceState={deviceState} />
      <FormNDSDeviceConfigGet
        location={{
          column: deviceState.properties.location_column,
          shelf: deviceState.properties.location_shelf,
          row: deviceState.properties.location_row,
        }}
        definition={mqttDefinitionNDSConfigGet}
      />
    </>
  );
};

export default ConfigNDS;
