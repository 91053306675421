// TODO move this to a database setting / page config
// Cost object per season

import type { ConsumableDataType } from './ConsumablesTable';

const ConsumableDefaultCosts: ConsumableDataType = {
  nutrientA: 0.0016,
  nutrientB: 0.0016,
  nutrientPeroxide: 0.00155,
  nutrientAcid: 0.0016,
  nutrientBase: 0.0016,
  energy: 0.1521711,
  water: 0.0025,
  co2: 1.45,
  seeds: 500,
};

export default ConsumableDefaultCosts;
