import LatestDeviceData from '../../components/LatestDeviceData/LatestDeviceData';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import unflatten from '../../helpers/unflatten';
import type { FromFirebase } from '../../types/types';
import type { IrrigationDeviceState } from '@phyllome/common';

const LatestIrrigation = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const unflattened = unflatten(latest);

  return (
    <PaperSection
      heading="Latest Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >

      <LatestDeviceData data={unflattened as any} />

    </PaperSection>
  );
};

export default LatestIrrigation;
