import type { DatabaseSeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import gramsToKgs from '../../../../helpers/gramsToKgs';
import co2LitresToKilos from '../../../../helpers/co2LitresToKilos';
import useSeed from '../../../../data/hooks/collections/useSeed';
import ConsumableDefaultCosts from '../../../../components/ConsumablesTable/ConsumableDefaultCosts';
import type { ConsumableDataType } from '../../../../components/ConsumablesTable/ConsumablesTable';

const RevenuePerKg = 20; // TODO: Move to Season end / setup

const SideBarSeasonProfit = ({
  seasonData,
}: {
  seasonData?: FromFirebase<DatabaseSeason>;
}) => {
  const seedData = useSeed(seasonData?.seedId);
  const seedCost =
    (seedData?.pricePerMillion || ConsumableDefaultCosts.seeds) / 1000000;

  // csd = computed season data
  const csd = {
    totalYieldHarvested: seasonData?.totalYieldHarvested || 0,
    totalYieldLost: seasonData?.totalYieldLost || 0,
    productiveTrays:
      (seasonData?.trayNumberActual || 0) -
      (seasonData?.trayNumberRejected || 0),
    trayNumberTarget: seasonData?.trayNumberTarget || 0,
    trayNumberActual: seasonData?.trayNumberActual || 0,
    aveYieldPerTray:
      (seasonData?.totalYieldHarvested || 0) /
      ((seasonData?.trayNumberCompleted || 0) -
        (seasonData?.trayNumberRejected || 0)),
  };

  const ConsumableData: ConsumableDataType = {
    energy: Number(seasonData?.totalConsumedEnergy),
    water: Number(seasonData?.totalConsumedWater),
    nutrientA: Number(seasonData?.totalConsumedNutrientA),
    nutrientB: Number(seasonData?.totalConsumedNutrientB),
    co2: co2LitresToKilos(Number(seasonData?.totalConsumedCO2) / 1000),
    seeds: Number(seasonData?.totalConsumedSeedQuantity),
    nutrientPeroxide: Number(seasonData?.totalConsumedPeroxide),
    nutrientAcid: Number(seasonData?.totalConsumedAcid),
    nutrientBase: Number(seasonData?.totalConsumedBase),
  };

  const totalCost =
    (ConsumableData.energy / 1000) * ConsumableDefaultCosts.energy +
    ConsumableData.water * ConsumableDefaultCosts.water +
    ConsumableData.nutrientA * ConsumableDefaultCosts.nutrientA +
    ConsumableData.nutrientB * ConsumableDefaultCosts.nutrientA +
    ConsumableData.nutrientPeroxide * ConsumableDefaultCosts.nutrientPeroxide +
    ConsumableData.nutrientAcid * ConsumableDefaultCosts.nutrientAcid +
    ConsumableData.nutrientBase * ConsumableDefaultCosts.nutrientBase +
    ConsumableData.co2 * ConsumableDefaultCosts.co2 +
    ConsumableData.seeds * seedCost;

  const revenue = (csd.totalYieldHarvested / 1000) * RevenuePerKg;
  const profit = revenue - totalCost;

  if (seasonData?.state !== 'completed') return null;
  return (
    <PaperSection heading="Season Profit" mb={2}>
      <TableContainer>
        <Table aria-label="Sprinkler Schedule" size="small">
          <TableBody>
            <TableRow>
              <TableCell>Yield Packaged</TableCell>
              <TableCell>{gramsToKgs(csd.totalYieldHarvested)} kg</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Yield Lost</TableCell>
              <TableCell>
                ({gramsToKgs(csd.totalYieldLost)} kg) (
                {((csd.totalYieldLost / csd.totalYieldHarvested) * 100).toFixed(
                  1,
                )}
                %)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Productive Trays</TableCell>
              <TableCell>{csd.productiveTrays}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Yield Per Tray</TableCell>
              <TableCell> {gramsToKgs(csd.aveYieldPerTray)} kg</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Harvested Revenue</strong> (at ${RevenuePerKg}/kg)
              </TableCell>
              <TableCell>
                <strong>${revenue.toFixed(2)}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Less cost of consumables</TableCell>
              <TableCell>(${totalCost.toFixed(2)})</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Season gross profit</strong>
              </TableCell>
              <TableCell>
                <strong>${profit.toFixed(2)}</strong> (
                {((profit / revenue) * 100).toFixed(1)}%)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PaperSection>
  );
};

export default SideBarSeasonProfit;
