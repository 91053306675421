import type { FC } from 'react';
import React, { useEffect } from 'react';
import type { SxProps } from '@mui/material';
import { Box, Button, Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { colors } from '@phyllome/common';

interface IProps {
  children?: React.ReactNode;
  heading?: string;
  subheading?: string;
  mt?: number;
  mb?: number;
  minHeight?: string;
  sx?: SxProps;
  headerSx?: SxProps;
  hidden?: boolean;
  collapsable?: boolean;
  buttonText?: string;
  buttonClickEvent?: () => void;
}

const PaperSection: FC<IProps> = ({
  mt = 0,
  mb = 0,
  heading,
  subheading,
  children,
  buttonText,
  buttonClickEvent = () => null,
  minHeight = 0,
  sx = {},
  headerSx = {},
  hidden = false,
  collapsable = false,
}) => {
  const [collapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    if (collapsable === true) {
      setCollapsed(true);
    }
  }, [collapsable]);
  if (hidden) return null;
  return (
    <Box mt={mt} mb={mb}>
      <Paper sx={{ minHeight, ...sx }}>
        {heading && (
          <Box
            sx={{
              color: '#eeeeee',
              background: colors.night,
              padding: '8px 8px 8px 16px',
              borderRadius: '5px 5px 0 0',
              ...headerSx,
            }}
          >
            {collapsable && (
              <Box
                sx={{
                  color: 'white',
                  position: 'absolute',
                  right: '11px',
                }}
              >
                {collapsed ? (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ position: 'relative', top: '-5px' }}
                    onClick={() => setCollapsed(false)}
                  >
                    open
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ position: 'relative', top: '-5px' }}
                    onClick={() => setCollapsed(true)}
                  >
                    close
                  </Button>
                )}
              </Box>
            )}
            <Typography
              variant="h2"
              sx={{
                fontWeight: 'normal',
                fontSize: '16px',
              }}
            >
              {buttonText && (
                <div
                  style={{
                    float: 'right',
                    position: 'relative',
                    top: '-5px',
                    left: '3px',
                    lineHeight: '28px',
                  }}
                >
                  <Button
                    onClick={buttonClickEvent}
                    sx={{ height: '25px' }}
                    variant="contained"
                    size="small"
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
              {heading}
              {subheading && (
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'normal',
                    fontSize: '80%',
                    opacity: '0.75',
                  }}
                >
                  {subheading}
                </Typography>
              )}
            </Typography>
          </Box>
        )}
        {!collapsed && <Box p={2}>{children}</Box>}
      </Paper>
    </Box>
  );
};

export default PaperSection;
