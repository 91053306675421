import type { FC } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
//import useNavSide from '../../../data/hooks/useNavSide';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import useSeasonPhotos from './Hooks/useSeasonPhotos';
import { useParams } from 'react-router-dom';
import useSeason from '../Seasons/useSeason';

const Satellite: FC<unknown> = () => {
  //const navSide = useNavSide();
  //const { brokerId } = useContext(FarmContext);
  const { seasonId } = useParams();
  const season = useSeason(seasonId);
  const photos = useSeasonPhotos({ seasonId: seasonId?.toString() || '', type: 'visible' });

  return (
    <ContainerPrimary title="Satellite View" icon={<CameraAltIcon />}>
      <Box pb={2}>
        <h2>{season?.name}</h2>
        <ImageList cols={2} sx={{ width: '100%' }}>
          {photos.map((photo) => (
            <ImageListItem key={photo.id} >
              <img
                src={`${photo.url}`}
                srcSet={`${photo.url}`}
                alt={photo.id}
                loading="lazy"
              />
              <ImageListItemBar
                title={photo.id}
                subtitle={<span>taken: {photo.timestamp?.toDate().toLocaleDateString('en-AU')}</span>}
              />
            </ImageListItem>
          ))}
        </ImageList>

      </Box>

    </ContainerPrimary>
  );
};

export default Satellite;
