import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const air: FavouriteDefinition = {
  airTemperature: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'air',
    subType: 'linear',
    heading: 'Temperature',
    showHeading: ({ deviceId }) => `[AIR] ${deviceId}/temperature`,
    color: colors.earth1,
    field: 'temperature',
    brokerId,
    deviceId,
  }),
  airHumidity: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'air',
    subType: 'linear',
    heading: 'Humidity',
    showHeading: ({ deviceId }) => `[AIR] ${deviceId}/humidity`,
    color: colors.ocean,
    field: 'humidity',
    brokerId,
    deviceId,
  }),
  airCo2: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'air',
    subType: 'linear',
    heading: 'CO2',
    showHeading: ({ deviceId }) => `[AIR] ${deviceId}/CO2`,
    color: colors.ripening,
    field: 'co2',
    brokerId,
    deviceId,
  }),
  airVPD: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'air',
    subType: 'linear',
    heading: 'Vapour Pressure Deficit',
    showHeading: ({ deviceId }) => `[AIR] ${deviceId}/vpd`,
    color: colors.ocean,
    field: 'vpd',
    brokerId,
    deviceId,
  }),
  airDewPoint: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'air',
    subType: 'linear',
    heading: 'Dew Point',
    showHeading: ({ deviceId }) => `[AIR] ${deviceId}/dewPoint`,
    color: colors.ocean,
    field: 'dewPoint',
    brokerId,
    deviceId,
  }),
  airRssi: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'air',
    subType: 'linear',
    heading: 'Wifi RSSI',
    showHeading: ({ deviceId }) => `[AIR] ${deviceId}/RSSI`,
    color: colors.earth3,
    field: 'wifi_rssi',
    brokerId,
    deviceId,
    constraints: {
      min: -90,
      max: -30,
    },
    limits: {
      min: -75,
      max: -30,
    },
  }),
};

export default air;
