import type { PackagerDeviceState } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import type { FromFirebase } from '../../types/types';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphPackager = ({
  latest,
}: {
  latest: FromFirebase<PackagerDeviceState>;
}) => {
  const { deviceId, brokerId } = latest;

  console.log(deviceId, brokerId);
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphPackager;
