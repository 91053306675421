import muiTheme from './styles/muiTheme';
import {
  AuthProvider,
  DatabaseProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from 'reactfire';
import firebaseConfig from './helpers/firebaseConfig';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import { AppStateProvider } from './data/AppState/useAppState';
import BrokerEntry from './pages/Broker/BrokerEntry';
import Sus from './components/Sus/Sus';
import AuthCheck from './pages/auth/AuthCheck';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
//import { getApp } from "firebase/app";
import { CssBaseline, ThemeProvider } from '@mui/material';
import Seeds from './pages/Broker/Seeds/Seeds';
import AppDrawerProvider from './sidebar/AppDrawer/useAppDrawer';
import FarmProvider from './components/FarmProvider/FarmProvider';
import { PollerCacheProvider } from './data/hooks/PollerCacheProvider';
import { SnackbarProvider } from 'notistack';

const MainRoutes = () => {
  return (
    <Router>
      <FarmProvider>
        <AppDrawerProvider>
          <Routes>

            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="/broker/:brokerId/*"
              element={<BrokerEntry />}
            />
            <Route
              path="seeds/"
              element={<Seeds />}
            />
          </Routes>
        </AppDrawerProvider>
      </FarmProvider>
    </Router>
  );
};

function AppRun() {
  const app = useFirebaseApp();
  const database = getDatabase(app);
  const auth = getAuth(app);

  const firestoreInstance = getFirestore(useFirebaseApp());

  // if (process.env.NODE_ENV === "development") {
  //   console.warn("LOCAL DEVELOPMENT USED.");

  //   const functions = getFunctions(getApp());
  //   connectFunctionsEmulator(functions, "localhost", 5001);
  // }

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>
        <SnackbarProvider>
          <FunctionsProvider sdk={getFunctions(app)}>
            <AppStateProvider>
              <FirestoreProvider sdk={firestoreInstance}>
                <PollerCacheProvider>
                  <CssBaseline />
                  <ThemeProvider theme={muiTheme}>
                    <Sus>
                      <AuthCheck>
                        <MainRoutes />
                      </AuthCheck>
                    </Sus>
                  </ThemeProvider>
                </PollerCacheProvider>
              </FirestoreProvider>
            </AppStateProvider>
          </FunctionsProvider>
        </SnackbarProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <FirebaseAppProvider
      firebaseConfig={firebaseConfig}
      suspense={true}
    >
      <AppRun />
    </FirebaseAppProvider>
  );
}
export default App;
