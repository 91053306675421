import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const zoneGraph: FavouriteDefinition = {
  zoneEnergy: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'zone',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[ZONE] ${deviceId}/energy`,
    color: colors.earth1,
    field: 'energy',
    brokerId,
    deviceId,
  }),
};

export default zoneGraph;
