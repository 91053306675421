import { useRef, useState, useEffect } from 'react';
import RackBox from '../../../../components/factoryui/components/RackBox';
import ScaffoldRackContainer from '../../../../components/factoryui/components/ScaffoldRackContainer';
import type {
  Coordinate,
} from '../../../../components/factoryui/useRackSelect';
import useRackSelect from '../../../../components/factoryui/useRackSelect';
import RackBoundingBox from '../../../../components/factoryui/components/RackBoundingBox';
import { Box } from '@mui/material';

const getBoundingBoxCoordinates = (
  points: Coordinate[],
): { topLeft: Coordinate; bottomRight: Coordinate } | null => {
  if (points.length === 0) {
    return null; // Return null for an empty array
  }

  let minX = points[0].x;
  let minY = points[0].y;
  let maxX = points[0].x;
  let maxY = points[0].y;

  for (const { x, y } of points) {
    if (x < minX) {
      minX = x;
    }
    if (y < minY) {
      minY = y;
    }
    if (x > maxX) {
      maxX = x;
    }
    if (y > maxY) {
      maxY = y;
    }
  }

  const topLeft: Coordinate = { x: minX, y: minY };
  const bottomRight: Coordinate = { x: maxX, y: maxY };

  return { topLeft, bottomRight };
};

type OverlayTraySelectProps = {
  rows: number;
  shelves: number;
  onSelect?: (selection: { x: number; y: number }[]) => void;
  selectedColumn?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  blackList?: Record<string, boolean>;
  hideChildren?: boolean;
};

const OverlayTraySelect = ({
  shelves,
  rows,
  onSelect,
  children = null,
  disabled = false,
  blackList = {},
  hideChildren = false,
}: OverlayTraySelectProps) => {
  const ref = useRef(null);
  const { selection, count } = useRackSelect(
    ref,
    shelves,
    rows,
    disabled,
    blackList,
  );
  const [boundingBox, setBoundingBox] = useState<{
    topLeft: Coordinate;
    bottomRight: Coordinate;
  } | null>(null);
  const [lastCount, setLastCount] = useState(0);

  useEffect(() => {
    if (lastCount === count) return;

    if (selection.length === 0) {
      setBoundingBox(null);
      return;
    }

    setLastCount(count);

    const boundingBoxCoordinates = getBoundingBoxCoordinates(selection);

    setBoundingBox(boundingBoxCoordinates);

    if (onSelect) {
      onSelect(selection);
    }
  }, [selection, count, lastCount, onSelect]);
  return (
    <ScaffoldRackContainer shelves={shelves} rows={rows} ref={ref} zIndex={99}>
      <>
        {selection.map((row, index) => {
          return (
            <>
              <RackBox
                key={index}
                row={row.x}
                shelf={row.y}
                shelves={shelves}
                style={{ backgroundColor: 'rgb(255 129 0 / 50%)' }}
                zIndex={100}
              ></RackBox>
            </>
          );
        })}
        {boundingBox && selection.length && (
          <>
            <RackBoundingBox
              zIndex={101}
              topLeftX={boundingBox.topLeft.x}
              topLeftY={boundingBox.topLeft.y}
              bottomRightX={boundingBox.bottomRight.x}
              bottomRightY={boundingBox.bottomRight.y}
              shelves={shelves}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                {!hideChildren && children}
              </Box>
            </RackBoundingBox>
          </>
        )}
      </>
    </ScaffoldRackContainer>
  );
};

export default OverlayTraySelect;
