import { Stack } from '@mui/system';
import { CheckboxElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { TraybotDeviceState } from '@phyllome/common';
import {
  mqttDefinitionTraybotConfigSet,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormTraybotConfigSetOperating = ({
  latest,
}: {
  latest: FromFirebase<TraybotDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const columnId = latest.columnId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotConfigSet,
    String(columnId),
  );
  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotConfigSet,
    {
      drive: {
        homeOnStartup: latest?.drive_homeOnStartup || false,
        holdOnLift: latest?.drive_holdOnLift || false,
        homeOnEdge: latest?.drive_homeOnEdge || false,
        updateOnTray: latest?.drive_updateOnTray || false,
      },
    },
  );

  return (
    <PaperSection
      heading="Traybot Operating Parameters"
      mb={2}
      subheading={mqttDefinitionTraybotConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              drive: {
                homeOnStartup: data?.drive?.homeOnStartup,
                holdOnLift: data?.drive?.holdOnLift,
                homeOnEdge: data?.drive?.homeOnEdge,
                updateOnTray: data?.drive?.updateOnTray,
              },
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });

          activityLog.add('Updated traybot operating config', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          drive: yup.object({
            homeOnStartup: yup.boolean(),
            holdOnLift: yup.boolean(),
            holdOnEdge: yup.boolean(),
            updateOnTray: yup.boolean(),
          }),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement label="Home on Startup" name="drive.homeOnStartup" />
          <CheckboxElement label="Hold on lift" name="drive.holdOnLift" />
          <CheckboxElement label="Home on edge" name="drive.holdOnEdge" />
          <CheckboxElement label="Update on tray" name="drive.updateOnTray" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotConfigSetOperating;
