import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { IrrigationDeviceState } from '@phyllome/common';
import {
  mqttDefinitionIrrigationConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormIrrigationConfigTransfer = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationConfigSet,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionIrrigationConfigSet,
    {
      return: {
        min: latest?.return_min || 0,
        max: latest?.return_max || 0,
      },

      automation: {
        transfer: latest?.automation_transfer || false,
      },
    },
  );

  return (
    <PaperSection
      heading="Transfer Automation"
      mb={2}
      subheading={mqttDefinitionIrrigationConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              return: {
                min: data.return.min,
                max: data.return.max,
              },
              automation: {
                transfer: data.automation.transfer,
              },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set transfer automation to ${data.automation.transfer} and min/max to ${data.return.min}/${data.return.max}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          return: yup.object({
            min: yup
              .number()
              .min(0)
              .max(100)
              .lessThan(yup.ref('max'))
              .required(),
            max: yup
              .number()
              .min(0)
              .max(100)
              .moreThan(yup.ref('min'))
              .required(),
          }),
          automation: yup.object({
            transfer: yup.boolean().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement
            label="Automation Transfer"
            name="automation.transfer"
          />
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Return MIN" name="return.min" />
            <TextFieldElement label="Return MAX" name="return.max" />
          </Stack>
          <p>
            When transfer automation is enabled, the transfer system will turn
            on at MAX and turn off at MIN
          </p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationConfigTransfer;
