import { colors } from '@phyllome/common';

export const getMinutesDifference = (date1: Date, date2?: Date): number =>
  date2 ? Math.floor(Math.abs(date2.getTime() - date1.getTime()) / 60000) : 0;

export const getSecondsDifference = (date1: Date, date2?: Date): number =>
  date2 ? Math.floor(Math.abs(date2.getTime() - date1.getTime()) / 1000) : 0;

const getUIInfoBasedOnAge = (
  now: Date,
  lastUpdated?: Date,
): { age: number; ageSeconds: number; color: string } => {
  if (!lastUpdated) return { age: 0, ageSeconds: 0, color: 'transparent' };

  const age = getMinutesDifference(now, lastUpdated);
  const ageSeconds = getSecondsDifference(now, lastUpdated);

  if (age < 10) return { age, ageSeconds, color: colors.ripening };
  if (age < 60) return { age, ageSeconds, color: colors.budding };
  if (age < 120) return { age, ageSeconds, color: colors.vegetative };
  return { age, ageSeconds, color: colors.sunlight };
};

export default getUIInfoBasedOnAge;
