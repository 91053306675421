import type { CloudAction } from '@phyllome/common';
import { httpsCallableFromURL } from 'firebase/functions';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useFunctions } from 'reactfire';
import { useSnackbar } from 'notistack';

type Status = 'IDLE' | 'RUNNING' | 'DONE' | 'ERROR';

const createEndpoint = (name: string) => {
  return process.env.NODE_ENV === 'production'
    ? `https://${name}-hbtwsngtia-ts.a.run.app`
    : `http://127.0.0.1:5001/phyllome-core/us-central1/${name}`;
};

type ExtractByAction<U, T> = U extends { action: T } ? U : never;

type RunFunc<ActionName extends CloudAction['action']> = (
  payload: ExtractByAction<CloudAction, ActionName>['payload'],
) => Promise<ExtractByAction<CloudAction, ActionName>['returnMsg']>;
export type UseActionReturn<ActionName extends CloudAction['action']> = {
  run: RunFunc<ActionName>;
  status: Status;
  msg: CloudAction['returnMsg'] | undefined;
  error: string | undefined;
};

const useAction = <ActionName extends CloudAction['action']>(
  action: ActionName,
): UseActionReturn<ActionName> => {
  const [status, setStatus] = useState<Status>('IDLE');
  const [msg, setMessage] = useState<CloudAction['returnMsg']>();
  const [error, setError] = useState<string>();
  const functions = useFunctions();
  const { enqueueSnackbar } = useSnackbar();

  const func = useMemo(
    () => httpsCallableFromURL<any, any>(functions, createEndpoint('action')),
    [functions],
  );

  useEffect(() => {
    if (status === 'ERROR') {
      enqueueSnackbar('Error: ' + msg, { variant: 'error' });
    }
  }, [status, msg, enqueueSnackbar]);

  const run = useCallback(
    async (payload: ExtractByAction<CloudAction, ActionName>['payload']) => {
      setStatus('RUNNING');
      setMessage(undefined);

      try {
        const request = await func({
          action,
          payload,
        });

        setMessage(request.data.msg);
        setStatus(request.data.state === 'success' ? 'DONE' : 'ERROR');
        return request.data as ExtractByAction<
          CloudAction,
          ActionName
        >['returnMsg'];
      } catch (error) {
        setStatus('ERROR');
        setError(JSON.stringify(error));
        throw error;
      }
    },
    [func, action],
  );

  // const startPeriodicRun = useCallback(
  //   (args: Payload, options?: RunOptions): Promise<ActionReturn<ReturnMsg>> => {
  //     const initialRunPromise = run({
  //       action,
  //       payload: args,
  //     }); // Execute immediately and return the promise

  //     if (!options || !options.refresh) return initialRunPromise;

  //     if (options.refresh && options.refresh > 0) {
  //       if (intervalRef.current) clearInterval(intervalRef.current);

  //       intervalRef.current = setInterval(() => {
  //         run({
  //           action,
  //           payload: args,
  //         }); // Subsequent runs are not awaited here
  //       }, options.refresh * 1000);
  //     }

  //     return initialRunPromise; // Return the promise from the initial run
  //   },
  //   [run, action],
  // );

  return {
    run: run as unknown as RunFunc<ActionName>,
    status,
    msg,
    error,
  };
};

export default useAction;
