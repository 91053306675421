import type { SentryDeviceState } from '@phyllome/common';
import type { FC } from 'react';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import LatestDeviceData from '../../components/LatestDeviceData/LatestDeviceData';
import unflatten from '../../helpers/unflatten';

type IProps = {
  latest: FromFirebase<SentryDeviceState>;
};

const LatestSentry: FC<IProps> = ({ latest }) => {
  return (
    <PaperSection
      heading="Latest Sentry Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <LatestDeviceData data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestSentry;
