import type { DatabaseSeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import { Box, Button, Divider, Typography } from '@mui/material';
import { colors } from '@phyllome/common';
import useNavSide from '../../../../data/hooks/useNavSide';
import { useNavigate } from 'react-router-dom';
import HeroGroup from '../../../../components/puregui/HeroItem/HeroGroup';
import TimelineView from '../../../../components/TimelineView/TimelineView';
import { useEndpointTraySeasonUpdateAllTrays } from '../../../../data/endpointHooks/endpointHooks';

const SideBarSeasonInfo = ({
  seasonData,
  brokerId,
  seasonId,
}: {
  seasonData: FromFirebase<DatabaseSeason>;
  brokerId: string;
  seasonId: string;
}) => {
  const navSide = useNavSide();
  const nav = useNavigate();

  const updateMultipleTrays = useEndpointTraySeasonUpdateAllTrays();

  const heroItems = [
    {
      label: 'Trays',
      text: (seasonData?.trayNumberActual || 0).toString(),
    },
    {
      label: 'Rejected',
      text: (seasonData?.trayNumberRejected || 0).toString(),
    },
    {
      label: 'Flagged',
      text: (seasonData?.trayNumberFlagged || 0).toString(),
    },
    {
      label: 'Completed',
      text: (seasonData?.trayNumberCompleted || 0).toString(),
    },
    {
      label: 'Productive',
      text: (
        (seasonData?.trayNumberActual || 0) -
        (seasonData?.trayNumberRejected || 0) -
        (seasonData?.trayNumberFlagged || 0)
      ).toString(),
    },
  ];

  const backgroundColor =
    seasonData?.state === 'completed'
      ? colors.ripening
      : seasonData.state === 'paused'
        ? colors.sunlight
        : colors.night;

  return (
    <PaperSection
      heading="Season Info"
      mb={2}
      headerSx={{
        backgroundColor: { backgroundColor },
      }}
    >
      <Box mb={2} textAlign="center">
        <Typography variant="h6">{seasonData?.name || 'unknown'}</Typography>
      </Box>
      <Box mb={2}>
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb={2}>
        <TimelineView
          duration={seasonData.durationDays}
          sowed={seasonData.traySeedEarliest}
          started={seasonData.growthStarted}
          harvested={seasonData.trayHarvestEarliest}
          ended={seasonData.growthEnded}
          paused={seasonData.state === 'paused'}
        />
      </Box>
      <Divider style={{ marginBottom: 2 }} />
      <Box sx={{ textAlign: 'right', mt: 2 }}>
        {seasonData?.state !== 'completed' && (
          <Button
            sx={{ ml: 1 }}
            size="small"
            variant="outlined"
            onClick={() => {
              navSide(`season/trays/${seasonData.id}`);
            }}
          >
            Assign Trays
          </Button>
        )}
        {seasonData?.state == 'completed' && (
          <Button
            sx={{ ml: 1 }}
            size="small"
            color="warning"
            variant="outlined"
            onClick={() => {
              updateMultipleTrays.run({
                brokerId,
                seasonId,
                isComplete: false,
              });
            }}
          >
            Re-enable season
          </Button>
        )}
        <Button
          sx={{ ml: 1 }}
          size="small"
          variant="contained"
          onClick={() => {
            nav(`/broker/${brokerId}/trays/${seasonData.id}`);
          }}
        >
          View Trays
        </Button>
      </Box>
    </PaperSection>
  );
};

export default SideBarSeasonInfo;
