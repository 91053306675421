import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import OverlaySprinklerValves from '../../Sprinklers/overlays/OverlaySprinklerValves';
import useNDSLatestByColumnPoller from '../../../../devicespecific/NDS/hooks/useNDSLatestByColumnPoller';

const OverlayEntryPointValues = ({ column }: { column: number }) => {
  const { columnSpec, brokerId } = useContext(FarmContext);
  const ndsData = useNDSLatestByColumnPoller(brokerId, column);
  const shelves = columnSpec[column - 1].shelves;

  return (
    <>
      <OverlaySprinklerValves
        brokerId={brokerId}
        data={ndsData.msg}
        shelves={shelves}
        column={column}
      />
    </>
  );
};

export default OverlayEntryPointValues;
