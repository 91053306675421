import { useEffect, useState } from 'react';
import {
  collection,
  query,
  limit,
  where,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import { orderBy } from 'lodash';
import type { DatabaseControlQueue } from '@phyllome/common';
import type { FromFirebase } from '../../../../../types/types';

const useControlQueue = (brokerId: string) => {
  const [data, setData] = useState<FromFirebase<DatabaseControlQueue>[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const firestore = getFirestore();
    const documentReference = query(
      collection(firestore, 'controlQueue'),
      where('brokerId', '==', brokerId),
      limit(1000),
    );

    const unsubscribe = onSnapshot(
      documentReference,
      (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as FromFirebase<DatabaseControlQueue>[];

        setData(newData);
      },
      (err) => {
        console.error(err);
        setError(err);
      },
    );

    return () => unsubscribe(); // Clean up on unmount
  }, [brokerId]);

  if (error) {
    console.error(error.toString());
  }

  const sortedData = orderBy(data, ['status', 'retries'], ['desc', 'asc']);

  return sortedData;
};

export default useControlQueue;
