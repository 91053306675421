import type { CloudActionGetNDSByColumn } from '@phyllome/common';
import type { StatusPoller } from '../../../data/hooks/usePoller';
import usePoller, { type UsePollerReturn } from '../../../data/hooks/usePoller';
import type { NDSDeviceFromAction } from '../../../types/types';

export type UseNDSLatestReturn = UsePollerReturn<CloudActionGetNDSByColumn>;

const useNDSLatestByColumnPoller = (
  brokerId: string,
  column: number,
) => {
  // const [data, setData] = useState<PollerReturnType>();

  const ndsState = usePoller<any>(
    {
      action: 'getNDSByColumn',
      payload: {
        brokerId,
        column: column,
      },
    },
    30,
    [],
  );

  return ndsState as {
    status: StatusPoller;
    msg: NDSDeviceFromAction[]
  };
};

export default useNDSLatestByColumnPoller;
