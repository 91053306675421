import type { FertigatorDeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import FormFertigatorSetEc from './forms/FormFertigatorSetEc';
import FormFertigatorSetPeroxide from './forms/FormFertigatorSetPeroxide';
import FormFertigatorSetPump from './forms/FormFertigatorSetPump';
import FormFertigatorSetAcid from './forms/FormFertigatorSetAcid';
import FormFertigatorSetBase from './forms/FormFertigatorSetBase';
import LatestFertigatorInfo from './LatestFertigatorInfo';
import FormFertigatorManual from './forms/FormFertigatorManual';
import GenericPing from '../Generic/control/GenericPing';

const ControlFerigator = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  return (
    <>
      <LatestFertigatorInfo latest={latest} />
      <FormFertigatorSetEc latest={latest} />
      <FormFertigatorSetAcid latest={latest} />
      <FormFertigatorSetBase latest={latest} />
      <FormFertigatorSetPeroxide latest={latest} />
      <FormFertigatorSetPump latest={latest} />
      <FormFertigatorManual latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="fertigator"
        />
      </div>
    </>
  );
};

export default ControlFerigator;
