import type { DatabaseTraySeason } from '@phyllome/common';
import { colors } from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import makeColorSelected from '../../../../helpers/makeColorSelected';
import Gradient from 'javascript-color-gradient';
import type { FromFirebase } from '../../../../types/types';
import opacitySimulateColour from '../../../../helpers/opacitySimulateColour';
import calculateAgeInDays from '../../../../helpers/calculateAgeInDays';
import type { UseNDSLatestReturn } from '../../../../devicespecific/NDS/hooks/useNDSLatestByColumnPoller';
import { useDeepCompareMemo } from 'use-deep-compare';

type OverlayTrayCurrentDayProps = {
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
  onClick?: (traySeasonData: FromFirebase<DatabaseTraySeason>) => void;
  data: FromFirebase<DatabaseTraySeason>[];
  ndsData: UseNDSLatestReturn;
};

const coloursByAge = new Gradient()
  .setColorGradient(colors.seedling, colors.ripening)
  .setMidpoint(21)
  .getColors();

coloursByAge[0] = colors.seedling;

const OverlayTrayCurrentDay = ({
  shelves,
  selectedTray,
  selectedSeason,
  data,
  ndsData,
  onClick = () => null,
}: OverlayTrayCurrentDayProps) => {
  const ndsDataMap = useDeepCompareMemo(() => {
    return ndsData.msg.reduce<Record<string, string>>(
      (accumulator: any, row: any) => {
        const key = `${row.location_column}.${row.location_shelf}.${row.location_row}`;

        accumulator[key] = String(row.currentDay || '');
        return accumulator;
      },
      {},
    );
  }, [ndsData.msg]);

  return (
    <>
      {data.map((row, index) => {
        const isRejected = row.isRejected === true;
        const isFlagged = row.isFlagged === true;
        const isStarted = row.timestampGrowthStart ? true : false;

        const age = calculateAgeInDays(
          row.timestampGrowthStart,
          row.timestampGrowthEnd,
        );

        let background = coloursByAge[age - 1] || colors.ripening;

        if (isFlagged) background = colors.sunlight;
        if (isRejected) background = colors.earth3;
        if (!isStarted) background = colors.earth1;

        if (selectedSeason && row.seasonId !== selectedSeason) {
          background = opacitySimulateColour(background, 0.8);
        }
        const key = `${row.locationColumn}.${row.locationShelf}.${row.locationRow}`;

        return (
          <RackBox
            onClick={() => onClick(row)}
            key={index}
            zIndex={10}
            row={row.locationRow}
            shelf={row.locationShelf}
            shelves={shelves}
            isSelected={row.id === selectedTray}
            isGroupSelected={row.seasonId === selectedSeason}
            rackBoxStyleSelected={{
              border: '1px solid black',
              background: colors.ocean,
            }}
            rackBoxStyleHover={{
              background: makeColorSelected(background),
              transition: 'background 0.2s linear',
            }}
            rackBoxStyleGroupSelected={{
              opacity: 1,
              color: '#ffffff',
            }}
            style={{
              //opacity: selectedSeason ? 0.2 : 1,
              border: '1px solid transparent',
              transition: 'background 0.5s linear',
              cursor: 'pointer',
              background: background,
              color: selectedSeason ? '#aaa' : '#ffffff',
              textAlign: 'center',
            }}
          >
            {ndsDataMap[key] || ''}({age})
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlayTrayCurrentDay;
