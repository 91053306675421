import useNDSLatestByColumnPoller from '../../../../devicespecific/NDS/hooks/useNDSLatestByColumnPoller';
import useNavSide from '../../../../data/hooks/useNavSide';
import OverlaySprinklerSimple from './OverlaySprinklerSimple';
import OverlaySprinklerValves from './OverlaySprinklerValves';
import OverlaySprinklerSelect from './OverlaySprinklerSelect';
import { useContext } from 'react';
import { SprinklerContext } from '../SprinkerContext';
import OverlaySprinklerFirmware from './OverlaySprinklerFirmware';
import OverlaySprinklerEnabled from './OverlaySprinklerEnabled';
import OverlaySpinklerWaterRate from './OverlaySprinklerWaterRates';
import OverlaySprinklerNightOffFactor from './OverlaySprinklerNightOffFactor';
import { useDeepCompareEffect } from 'use-deep-compare';
import type { NDSDeviceFromAction } from '../../../../types/types';
import OverlaySprinklerCurrentDay from './OverlaySprinklerCurrentDay';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  shelves: number;
};

const OverLaySprinklerEntry = ({
  brokerId,
  column,
  shelves,
}: OverlayTraySimpleProps) => {
  const sprinklerContext = useContext(SprinklerContext);

  const navSide = useNavSide();
  const nds = useNDSLatestByColumnPoller(brokerId, column);

  const clickHandler = (row: NDSDeviceFromAction) => {
    const { location_column, location_shelf, location_row } = row.properties;

    navSide(`sprinklers/${location_column}.${location_shelf}.${location_row}`);
  };

  useDeepCompareEffect(() => {
    if (nds.status === 'ERROR') return;
    sprinklerContext.dispatch({
      type: 'SET_SPRINKLER_STATS',
      payload: nds.msg,
    });

  }, [nds.msg, nds.status]);

  const commonProps = {
    brokerId: brokerId,
    column: column,
    shelves,
    data: nds.msg || [],
  };

  const layer = sprinklerContext?.state?.layers?.primaryLayer || 'SIMPLE';
  const secondaryLayer = sprinklerContext?.state?.layers?.secondaryLayer;

  if (!nds.msg) return <div>Populating</div>;
  if (nds.status === 'ERROR') return <div>Error {String(nds.msg)}</div>;
  return (
    <>
      <OverlaySprinklerSelect {...commonProps} onClick={clickHandler} />
      {layer === 'SIMPLE' && <OverlaySprinklerSimple {...commonProps} />}
      {layer === 'FIRMWARE' && <OverlaySprinklerFirmware {...commonProps} />}
      {layer === 'WATERRATE' && <OverlaySpinklerWaterRate {...commonProps} />}
      {layer === 'NIGHTOFFFACTOR' && (
        <OverlaySprinklerNightOffFactor {...commonProps} />
      )}
      {layer === 'CURRENTDAY' && (
        <OverlaySprinklerCurrentDay {...commonProps} />
      )}
      {secondaryLayer.includes('ENABLED') && (
        <OverlaySprinklerEnabled {...commonProps} />
      )}
      {secondaryLayer.includes('VALVES') && (
        <OverlaySprinklerValves {...commonProps} />
      )}
    </>
  );
};

export default OverLaySprinklerEntry;
