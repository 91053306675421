import type { FC } from 'react';
import Phorm from '../../../../components/Form/Components/Phorm';
import PhormSubmit from '../../../../components/Form/PhormSubmit/PhormSubmit';
import type { DatabaseSeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import PhormTextField from '../../../../components/Form/Components/PhormTextField';
import PhormSelect from '../../../../components/Form/Components/PhormSelect';
import type { PhormDropDownData } from '../../../../components/Form/types';
import useSeeds from '../../../../data/hooks/collections/useSeeds';
import { string, number } from 'yup';
import * as yup from 'yup';
import useAction from '../../../../data/hooks/useAction';
import PhormActionErrors from '../../../../components/Form/Components/PhormActionErrors.';
import PhormErrors from '../../../../components/Form/Components/PhormErrors';
import { Stack } from '@mui/system';
import useActivityLog from '../../../../data/hooks/useActivityLog';
import { InputAdornment } from '@mui/material';

interface IProps {
  seasonData: Partial<FromFirebase<DatabaseSeason>> & { brokerId: string };
  seasonId: string;
}

const FormSetSeasonDetails: FC<IProps> = ({ seasonData, seasonId }) => {
  const seasonUpdate = useAction('seasonUpdate');
  const seeds = useSeeds({ validatedOnly: true });
  const activityLog = useActivityLog(`season/${seasonData.id}/notes`);

  const ddData: PhormDropDownData[] = [
    ...seeds.map((seed) => {
      return {
        label: `${seed.name}/${seed.variety}`,
        value: seed.id || '',
      };
    }),
  ];

  // const seasonStates = ["growing", "paused", "stopped", "completed"].map(
  //   (item) => {
  //     return { label: item, value: item };
  //   },
  // );

  console.log('SEASON', seasonData);
  return (
    <Phorm
      defaultValues={{
        name: seasonData.name,
        notes: seasonData.notes,
        seedId: seasonData.seedId,
        durationDays: seasonData.durationDays,
        defaultSeedCost: seasonData.defaultSeedCost,
        defaultSeedDensity: seasonData.defaultSeedDensity,
        trayNumberTarget: seasonData.trayNumberTarget,
        seedBatch: seasonData.seedBatch ? seasonData.seedBatch[0] : '',
      }}
      onSubmit={(values) => {
        seasonUpdate.run({
          brokerId: seasonData.brokerId,
          id: seasonId,
          name: values.name,
          notes: values.notes,
          seedId: values.seedId,
          durationDays: values.durationDays,
          defaultSeedCost: values.defaultSeedCost,
          defaultSeedDensity: values.defaultSeedDensity,
          trayNumberTarget: values.trayNumberTarget,
          seedBatch: [values.seedBatch],
        });

        activityLog.add('Updated season details', 'OPERATION');
      }}
      schema={yup.object().shape({
        name: string().required(),
        notes: string(),
        defaultSeedDensity: number().required(),
        defaultSeedCost: number().required(),
        trayNumberTarget: number().required(),
        seedId: string().required(),
        durationDays: number().required(),
        seedBatch: string(),
      })}
      busy={false}
    >
      <Stack spacing={2} mb={2}>
        <PhormTextField name="name" label="Season Name" width="full" />
        <PhormSelect name="seedId" label="Seed Type" options={ddData} />
      </Stack>
      <Stack width="50%" spacing={2} mb={2}>
        <PhormTextField
          name="durationDays"
          label="Season Duration"
          InputProps={{ endAdornment: <InputAdornment position="end">days</InputAdornment> }}
        />
        <PhormTextField
          name="defaultSeedDensity"
          label="Seed Quantity"
          InputProps={{ endAdornment: <InputAdornment position="end">per tray</InputAdornment> }}

        />
        <PhormTextField name="seedBatch" label="Seed Batch Number" />
      </Stack>
      <Stack spacing={2} mb={2}>
        <PhormTextField
          fullWidth={true}
          name="notes"
          label="Notes"
          multiline={true}
          minRows={4}
          maxRows={10}
        />
      </Stack>
      <PhormErrors />
      <PhormActionErrors actionObject={seasonUpdate} />
      <PhormSubmit busy={seasonUpdate.status === 'RUNNING'}>
        Update Season Details
      </PhormSubmit>
    </Phorm>
  );
};

export default FormSetSeasonDetails;
