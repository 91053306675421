import type { ObservableStatus } from 'reactfire';
import {
  useFirestore,
  useFirestoreDocDataOnce,
} from 'reactfire';
import { collection, doc } from 'firebase/firestore';
import type { FromFirebase } from '../../../../../types/types';
import type { DatabaseControlQueue } from '@phyllome/common';

// gets the singular control queue change
const useControlAction = (id: string) => {
  const documentReference = doc(collection(useFirestore(), 'controlQueue'), id);

  const data = useFirestoreDocDataOnce(documentReference, {
    idField: 'id',
  }) as ObservableStatus<FromFirebase<DatabaseControlQueue>>;

  if (data?.error) {
    console.error(data?.error?.toString());
  }
  return data.data;
};

export default useControlAction;
