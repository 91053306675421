import type { FC } from 'react';

type ScaffoldRackSectionProps = {
  rows: number;
  shelves: number;
};

const ScaffoldRackSection: FC<ScaffoldRackSectionProps> = ({
  rows,
  shelves,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: -1,
        zIndex: 0,
        left: '57px',
        height: `${16 * shelves + 1}px`,
        width: `${56 * rows}px`,
        background: '#fff',
      }}
    />
  );
};

export default ScaffoldRackSection;
