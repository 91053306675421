import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';
import { colors } from '@phyllome/common';
import type { FC } from 'react';

interface IProps extends ChipProps {
  status: string;
}

const ControlQueueStatus: FC<IProps> = ({ status, size = 'small' }) => {
  if (status === 'queued') {
    return <Chip label="Queued" size={size} />;
  }

  if (status === 'waiting') {
    return (
      <Chip
        size={size}
        label="Waiting"
        style={{ background: colors.budding, color: 'white' }}
      />
    );
  }
  if (status === 'timeout') {
    return (
      <Chip
        size={size}
        label="Timeout"
        style={{ background: colors.earth1, color: 'white' }}
      />
    );
  }
};

export default ControlQueueStatus;
