import { Stack } from '@mui/system';
import {  TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { FertigatorDeviceState } from '@phyllome/common';
import {
  mqttDefinitionFertigatorCalibrate,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import {  Typography } from '@mui/material';
import type { FromFirebase } from '../../../types/types';
import ChipBool from '../../../components/puregui/ChipBool/ChipBool';

const FormFertigatorCalibrateTemperature = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorCalibrate,
    deviceId,
  );

  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = {
    temperature: '20',
  };

  return (
    <PaperSection
      heading="Calibrate Temperature Probe"
      subheading={mqttDefinitionFertigatorCalibrate.topic}
      mb={2}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              temperature: data.temperature,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Calibrated temperature probe to ${data.temperature} C`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          temperature: yup.number().min(0).max(100).required(),
        })}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <ChipBool data={latest.calibration_temperature} label="Calibrated"/>
          </Stack>
          <Typography>
            Enter the current actual temperature of the probe to calibrate. (Single Point Calibration)
          </Typography>
          <TextFieldElement label="Temperature" name="temperature" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorCalibrateTemperature;
