import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Alert, AlertTitle, Box } from '@mui/material';

function isEmpty(obj: any) {
  for (const _x in obj) {
    return false;
  }
  return true;
}

const PhormErrors = () => {
  const { formState } = useFormContext();
  const errors = formState.errors;

  if (isEmpty(errors)) return null;
  return (
    <Box mb={2}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <>
          {Object.entries(errors).map((item, key) => {
            return (
              <div key={key}>
                <>- {item[1]?.message?.toString()}</>
              </div>
            );
          })}
        </>
      </Alert>
    </Box>
  );
};

export default PhormErrors;
