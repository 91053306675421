import { useLocation } from 'react-router-dom';
import routes from './routes';
import Route from 'route-parser';
import { Box, Alert, AlertTitle } from '@mui/material';

const GenerateComponent = (route: string) => {
  const matchedRoute = routes.find((sb) => {
    const routeParser = new Route(sb.path);

    return routeParser.match(route);
  });

  if (matchedRoute) {
    const cacheSeed = String(Math.random());
    const props = new Route(matchedRoute.path).match(route);
    const propsAndCache = { ...props, __cacheSeed: cacheSeed };
    const Component = matchedRoute.component;

    return <Component {...propsAndCache} />;
  }
  return (
    <Box p={2}>
      <Alert severity="error">
        <AlertTitle>Route Error</AlertTitle>
        Route &rsquo;<code>{route}</code>&rsquo; not found
      </Alert>
    </Box>
  );
};

const SideBar = () => {
  const location = useLocation();
  const hash = location.hash.substring(1);

  return GenerateComponent(hash);
};

export default SideBar;
