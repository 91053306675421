import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { IrrigationDeviceState } from '@phyllome/common';
import {
  mqttDefinitionIrrigationConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import { Alert } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormIrrigationConfigPressure = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationConfigSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionIrrigationConfigSet,
    {
      pressure: {
        min: latest?.pressure_min || 0,
        max: latest?.pressure_max || 0,
      },
    },
  );

  return (
    <PaperSection
      heading="Sump pressure alert levels"
      mb={2}
      subheading={mqttDefinitionIrrigationConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              pressure: {
                min: data.pressure.min,
                max: data.pressure.max,
              },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });

          activityLog.add(
            `Set sump pressure alert levels to min: ${data.pressure.min} and max: ${data.pressure.max}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          pressure: yup.object({
            min: yup
              .number()
              .min(0)
              .max(1000)
              .lessThan(yup.ref('max'))
              .required(),
            max: yup
              .number()
              .min(0)
              .max(1000)
              .moreThan(yup.ref('min'))
              .required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <p>
            Set the upper and lower bounds for sump return pressure alerts (in
            Kpa).
          </p>
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Pressure MIN" name="pressure.min" />
            <TextFieldElement label="Pressure MAX" name="pressure.max" />
          </Stack>
          <Alert severity="info">
            Note requries restart of controller to take effect.
          </Alert>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationConfigPressure;
