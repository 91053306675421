import GenericPing from '../Generic/control/GenericPing';
import type { FromFirebase } from '../../types/types';
import LatestTraybotInfo from './LatestTraybotInfo';
import type { TraybotDeviceState } from '@phyllome/common';
import FormTraybotControlScan from './forms/FormTraybotControlScan';
import FormTraybotControlFan from './forms/FormTraybotControlFan';

const ControlTraybot = ({
  latest,
}: {
  latest: FromFirebase<TraybotDeviceState>;
}) => {
  return (
    <>
      <LatestTraybotInfo latest={latest} />
      <FormTraybotControlScan latest={latest} />
      <FormTraybotControlFan latest={latest} />
      <div>
        <GenericPing
          deviceId={String(latest.columnId)}
          brokerId={latest.brokerId}
          deviceType="traybot"
        />
      </div>
    </>
  );
};

export default ControlTraybot;
