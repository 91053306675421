import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { LightingDeviceState } from '@phyllome/common';
import {
  mqttDefinitionLightingConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormLightingConfigSet = ({
  latest,
}: {
  latest: FromFirebase<LightingDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionLightingConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/lighting/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionLightingConfigSet,
    {
      calibration: latest?.calibration || 0,
      firstZoneID: latest?.firstZoneID || 0,
    },
  );

  return (
    <PaperSection
      heading="Lighting Config"
      mb={2}
      subheading={mqttDefinitionLightingConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              calibration: data.calibration,
              firstZoneID: data.firstZoneID,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set lighting ${deviceId} calibration to ${data.calibration} and first zone ID to ${data.firstZoneID}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          calibration: yup.number().min(0).required(),
          firstZoneID: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement label="Calibration" name="calibration" required />
          <TextFieldElement label="First Zone ID" name="firstZoneID" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLightingConfigSet;
