import type { colors } from '@phyllome/common';
import type { FC } from 'react';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { NDSDeviceFromAction } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import ChipEnum from '../../components/puregui/ChipEnum/ChipEnum';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/system';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';

type IProps = {
  latest: NDSDeviceFromAction;
};

type NDSStates = {
  label: string;
  value: number;
  color?: keyof typeof colors | 'primary' | 'secondary';
  icon?: JSX.Element;
}[];

const LatestNDSInfo: FC<IProps> = ({ latest }) => {
  const { state, enable, valve, button, timestamp, currentDay, activations } =
    latest.properties;

  const ndsStates: NDSStates = [
    {
      label: 'No Mesh Provisioned',
      value: 0,
      color: 'earth3',
      icon: <WrongLocationIcon />,
    },
    {
      label: 'Unprovisioned',
      value: 1,
      color: 'earth3',
      icon: <WrongLocationIcon />,
    },
    {
      label: 'Idle',
      value: 2,
    },
    {
      label: 'Idle', // idle + enabled
      value: 3,
    },

    {
      label: 'Ping',
      value: 4,
      color: 'sunlight',
      icon: <LocationSearchingIcon />,
    },

    {
      label: 'Ping', // ping + enabled
      value: 5,
      color: 'sunlight',
      icon: <LocationSearchingIcon />,
    },
    {
      label: 'Irrigating ',
      value: 6,
      icon: <WaterDropIcon />,
    },
    {
      label: 'Fault ',
      value: 7,
      color: 'earth3',
      icon: <ErrorIcon />,
    },
  ];

  const heroItems = [
    {
      label: 'Current Day',
      text: currentDay?.toString(),
    },
    {
      label: 'Activations',
      text: activations?.toString(),
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest NDS Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        <ChipEnum data={ndsStates} value={Number(state)} />
        <ChipBool data={enable} label="Enabled" color="primary" />
        <ChipBool
          data={valve}
          label="Irrigating"
          color="primary"
          icon={<WaterDropIcon />}
        />
        <ChipBool data={button} label="Pressed" color="primary" />
      </Box>
    </PaperSection>
  );
};

export default LatestNDSInfo;
