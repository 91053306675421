import { useState, useEffect } from 'react';
import versionData from '../version.json';

const version = versionData.version;

const useVersion = (url: string) => {
  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = new Date().getTime();
        const nocacheUrl = `${url}?_=${timestamp}`;
        const response = await fetch(nocacheUrl);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();

        setData(text);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchData();

    // Check new version once a minute
    const intervalId = setInterval(fetchData, 60000);

    // Cleanup function
    return () => clearInterval(intervalId);
  }, [url]);

  // Effect to monitor changes from base version
  useEffect(() => {
    if (data !== null && data !== version) {
      setHasChanged(true);
    }
  }, [data]);
  return { data, error, loading, hasChanged };
};

export default useVersion;