import { genArrayOfNumbers } from '@phyllome/common';
import type { FC } from 'react';
import RackPosition from './RackPosition';
import { Box } from '@mui/material';
import { rackBoxCSS } from './RackBox';

type RenderProps = {
  shelf: number;
  row: number;
};

const DefaultRender = ({ shelf, row }: RenderProps) => {
  return (
    <Box sx={{ ...rackBoxCSS, border: '1px solid red' }}>
      {shelf}, {row}
    </Box>
  );
};

type RackIteratorProps = {
  rows: number;
  shelves: number;
  render?: (p: RenderProps) => React.ReactElement;
  onClick?: (row: number, shelf: number) => void;
};

const RackIterator: FC<RackIteratorProps> = ({
  rows,
  shelves,
  render: Render = DefaultRender,
  onClick = () => null,
}) => {
  const rowArray = genArrayOfNumbers(rows).map((row) => row + 1);
  const shelfArray = genArrayOfNumbers(shelves).map((shelf) => shelf + 1);

  return (
    <>
      {shelfArray.map((shelf) =>
        rowArray.map((row, index) => (
          <RackPosition
            shelf={shelf}
            row={row}
            shelves={shelves}
            key={index}
            onClick={() => {
              onClick(row, shelf);
            }}
          >
            <Render shelf={shelf} row={row} />
          </RackPosition>
        )),
      )}
    </>
  );
};

export default RackIterator;
