import type { GuardDeviceState } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import LatestDeviceData from '../../components/LatestDeviceData/LatestDeviceData';
import unflatten from '../../helpers/unflatten';

const LatestGuard = ({
  latest,
}: {
  latest: FromFirebase<GuardDeviceState>;
}) => {
  return (
    <PaperSection
      heading="Latest Guard Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <LatestDeviceData data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestGuard;
