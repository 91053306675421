import type { CameraDeviceState } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import { Box } from '@mui/material';
import { formatBytes } from '../../helpers/formatBytes';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const LatestCameraInfo = ({
  latest,
}: {
  latest: FromFirebase<CameraDeviceState>;
}) => {
  const heroItems = [
    {
      label: 'Pending Images',
      text: latest.images_pending.toString(),
    },
    {
      label: 'Total Images',
      text: latest.images_total.toString(),
    },
    {
      label: 'Free space',
      text: formatBytes(latest.freespace),
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Camera Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mt={2}>
        <ChipBool
          label="Pending Upload"
          data={Number(latest.images_pending) > 0}
          color="sunlight"
          icon={<CloudUploadIcon />}
        />
      </Box>
    </PaperSection>
  );
};

export default LatestCameraInfo;
