import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

//https://date-fns.org/v2.16.1/docs/format

export enum dateFormatTypes {
  FULL,
  TRUNCATED,
  TRUNCATEDNOSECONDS,
  TRUNCATEDNOSECONDS2,
  DAYMONTHHOURSSECONDS,
  DAYMONTH,
  TIMEONLY,
  DAY,
}

const formatMap = {
  [dateFormatTypes.FULL]: 'dd/MM/yyyy hh:mm:ss a O',
  [dateFormatTypes.TRUNCATED]: 'd/M H:mm:ss',
  [dateFormatTypes.TRUNCATEDNOSECONDS]: 'd/M H:mm',
  [dateFormatTypes.TRUNCATEDNOSECONDS2]: 'd/M/yy H:mm',
  [dateFormatTypes.DAYMONTHHOURSSECONDS]: 'd MMM, H:mm',
  [dateFormatTypes.DAYMONTH]: 'd MMM',
  [dateFormatTypes.DAY]: 'd/M',
  [dateFormatTypes.TIMEONLY]: 'H:mm',
};

const dateToString = (
  timestamp?: Date | Timestamp | null,
  type = dateFormatTypes.FULL,
): string => {
  if (!timestamp) return '';

  // check if timestamp is a string
  if (typeof timestamp === 'string') {
    return format(new Date(timestamp), formatMap[type]);
  }

  if (timestamp instanceof Timestamp) {
    return format(timestamp.toDate(), formatMap[type]);
  } else {
    return format(timestamp, formatMap[type]);
  }
};

export default dateToString;
