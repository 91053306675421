import type { HarvesterDeviceState } from '@phyllome/common';
import {
  mqttDefinitionHarvesterConfigGet,
} from '@phyllome/common';
import { Box, Alert, AlertTitle } from '@mui/material';
import type { FromFirebase } from '../../types/types';
import FormHarvesterConfigSet from './forms/FormHarvesterConfigSet';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigHarvester = ({
  latest,
}: {
  latest: FromFirebase<HarvesterDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Harvester Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">TBD</LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <Box mb={2}>
        <Alert severity="warning">
          <AlertTitle>Form not connected</AlertTitle>Do not use these forms
          until they have been whitelisted on the MCP
        </Alert>
      </Box>
      <FormHarvesterConfigSet latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionHarvesterConfigGet}
      />
    </>
  );
};

export default ConfigHarvester;
