import type { DatabaseSeason } from '@phyllome/common';
import { colors } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import type { FC } from 'react';
import calculateAgeInDays from '../../helpers/calculateAgeInDays';
import { Tooltip, Box, Stack } from '@mui/material';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import getColorByAge from '../../helpers/getColorByAge';

const maxWidth = 100;

const constrain = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

export const SeasonTimelineMini: FC<{
  season: FromFirebase<DatabaseSeason>;
}> = ({ season }) => {
  const isSeeded = season.traySeedEarliest !== null;
  const isStarted = season.growthStarted !== null;
  const isEnded = season.growthEnded !== null;
  const isHarvested = season.trayHarvestLatest !== null;
  const isPaused = season.state === 'paused';
  const isStopped = season.state === 'stopped';

  const age = isEnded
    ? calculateAgeInDays(season.growthStarted, season.growthEnded)
    : calculateAgeInDays(season.growthStarted);

  const percentage = constrain(age / (season?.durationDays || 0), 0, 1);
  const width = (maxWidth * percentage).toFixed(0) + 'px';

  const barColor = isStopped
    ? colors.earth3
    : isPaused
      ? colors.sunlight
      : getColorByAge(age);

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Tooltip
          arrow
          title={
            'Seeded ' +
            dateToString(season.traySeedEarliest, dateFormatTypes.DAYMONTH)
          }
        >
          <Box
            sx={{
              height: '18px',
              width: '18px',
              borderRadius: '9px',
              backgroundColor: isSeeded ? colors.earth1 : colors.fog,
            }}
          />
        </Tooltip>
        <Tooltip
          arrow
          title={
            isStarted &&
            'Started ' +
              dateToString(season.growthStarted, dateFormatTypes.DAYMONTH)
          }
        >
          <Box sx={{ position: 'relative', width: '100px' }}>
            <Box
              sx={{
                height: '18px',
                width: '100px',
                borderRadius: '9px',
                backgroundColor: colors.fog,
                position: 'absolute',
              }}
            />
            <Box
              sx={{
                height: '18px',
                width: width,
                borderRadius: '9px',
                backgroundColor: barColor,
                position: 'absolute',
                textAlign: 'center',
                color: '#fff',
                display: isStarted ? 'block' : 'none',
              }}
            >
              {age}
              {percentage > 0.4 && ' days'}
            </Box>
          </Box>
        </Tooltip>
        <Tooltip
          arrow
          title={
            isHarvested
              ? 'Harvested ' +
                dateToString(
                  season.trayHarvestEarliest,
                  dateFormatTypes.DAYMONTH,
                )
              : ''
          }
        >
          <Box
            sx={{
              height: '18px',
              width: '18px',
              borderRadius: '9px',
              backgroundColor: isHarvested ? colors.ripening : colors.fog,
            }}
          />
        </Tooltip>
      </Stack>
    </>
  );
};

export default SeasonTimelineMini;
