import type { DeviceFromSeason } from '../../../data/hooks/collections/useDevicesInSeason';
import hasDesiredState from '../helpers/hasDesiredState';

export const DeviceIconToolTipText = ({ data }: { data: DeviceFromSeason }) => {
  const deviceId = data?.alias ? data.alias[0] : data.deviceId;
  const enabled =
    (data.deviceType === 'nds' && data.properties?.enable) ||
    (data.deviceType === 'zone' && data.properties?.enable);

  return (
    <div>
      {data.deviceType}/{deviceId} ({enabled ? 'enabled' : 'disabled'})
      {data.desiredProperties && hasDesiredState(data.desiredProperties) && (
        <>
          <br /> <br />
          Updating to:
          <br />
          {JSON.stringify(data.desiredProperties)}
        </>
      )}
    </div>
  );
};

export default DeviceIconToolTipText;
