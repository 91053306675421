import type { CloudActionTimeseries } from '@phyllome/common';
import usePoller from '../../data/hooks/usePoller';
import { useContext, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { ChartContext } from './ChartContext/ChartContext';

type TimeSeriesQueryParams = Omit<
  CloudActionTimeseries['payload'],
  'beginDate' | 'endDate'
>;
export type TimeSeriesData = {
  status: ReturnType<typeof usePoller>['status'];
  isInitiating: boolean;
  isUnsufficientData: boolean;
  isUpdating: boolean;
  data: {
    x: Date;
    y: number;
  }[];
};

const useTimeSeriesPoller = (params: TimeSeriesQueryParams): TimeSeriesData => {
  const {
    state: { beginDate, endDate },
  } = useContext(ChartContext);

  const [data, setData] = useState<TimeSeriesData['data']>([]);

  const polled = usePoller(
    {
      action: 'timeseries',
      payload: { ...params, beginDate, endDate },
    },
    600,
    [],
    JSON.stringify({ beginDate, endDate }),
  );

  useEffect(() => {
    if (isArray(polled.msg)) {
      setData(
        polled.msg.map((row) => {
          return {
            x: new Date(row.timestamp),
            y: row.value,
          };
        }),
      );
    }
  }, [polled.msg]);

  const isInitiating = polled.status !== 'DONE' && data.length === 0;
  const isUnsufficientData = polled.status === 'DONE' && data.length === 0;
  const isUpdating = polled.status !== 'DONE' && data.length > 0;

  return {
    data,
    status: polled.status,
    isInitiating,
    isUnsufficientData,
    isUpdating,
  };
};

export default useTimeSeriesPoller;
