import type { FC } from 'react';
import { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Collapse,
  Card,
  CardContent,
  Typography,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { colors } from '@phyllome/common';
import IconClose from '@mui/icons-material/Close';
import ScheduleTableNDS from '../ScheduleTable/ScheduleTableNDS';
import ScheduleTableLighting from '../ScheduleTable/ScheduleTableLighting';

export type StepType = {
  label: string;
  selected: boolean;
  error: boolean;
  linkedToPrevious: boolean;
  completed: boolean;
  schedule: any;
  showCard: boolean;
};

const ScheduleConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ScheduleConnectorLinked = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.budding,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

interface IObjectKeys {
  [key: number]: boolean;
}
interface IScheduleViewProps {
  steps: Array<StepType>;
  type: 'lighting' | 'nds';
}

const ScheduleView: FC<IScheduleViewProps> = ({ steps = [], type = 'nds' }) => {
  const [showSchedule, setShowSchedule] = useState<IObjectKeys>({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const handleShowSchedule = (target: any) => {
    setShowSchedule({ [target]: !showSchedule[target] });
  };

  return (
    <>
      <Box mb={2} textAlign="center">
        <Stepper alternativeLabel nonLinear connector={null}>
          {steps.map((step, index) => (
            <Step
              key={step.label}
              completed={step.completed}
              active={step.selected}
            >
              <StepLabel error={step.error}>
                <ScheduleChip
                  step={step}
                  index={index}
                  onClick={handleShowSchedule}
                />
              </StepLabel>
              {index > 0 &&
                (step.linkedToPrevious ? (
                  <ScheduleConnectorLinked key={index} />
                ) : (
                  <ScheduleConnector key={index} />
                ))}
            </Step>
          ))}
        </Stepper>
      </Box>
      <Card>
        {steps.map((step, index) => (
          <CollapsableCard
            key={index}
            type={type}
            schedule={step.schedule}
            show={showSchedule[index] && step.showCard}
            label={step.label + '  Schedule'}
            index={index}
            onCloseCard={handleShowSchedule}
          />
        ))}
      </Card>
    </>
  );
};

export default ScheduleView;

const CollapsableCard = ({
  schedule,
  show,
  label,
  index,
  type,
  onCloseCard,
}: {
  schedule: any;
  show: boolean;
  label: string;
  index: number;
  type?: 'lighting' | 'nds';
  onCloseCard: (args: number | undefined) => void;
}) => {
  return (
    <Collapse in={show} unmountOnExit style={{ position: 'relative' }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          color="text.secondary"
          style={{ position: 'relative' }}
        >
          {label}
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', right: '0px' }}
            onClick={() => {
              onCloseCard(index as number);
            }}
          >
            <IconClose />
          </IconButton>
        </Typography>
        {type === 'nds' && <ScheduleTableNDS scheduleDataDB={schedule || []} />}
        {type === 'lighting' && (
          <ScheduleTableLighting scheduleDataDB={schedule || []} />
        )}
      </CardContent>
    </Collapse>
  );
};

const ScheduleChip = ({
  step,
  index,
  onClick,
}: {
  step: StepType;
  index: number;
  onClick: (args: number | undefined) => void;
}) => {
  if (step.selected)
    return (
      <Chip
        label={step.label}
        size="small"
        style={{ position: 'relative', top: '-2px' }}
      />
    );
  else if (step.showCard)
    return (
      <Chip
        label={step.label}
        size="small"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0)',
          cursor: 'pointer',
        }}
        style={{ position: 'relative', top: '-2px' }}
        onClick={(e) => {
          e.stopPropagation();
          onClick(index);
        }}
      />
    );
  else
    return (
      <Chip
        label={step.label}
        size="small"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
        style={{ position: 'relative', top: '-2px' }}
      />
    );
};
