import React, { type FC } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import useDevices, {
  includedDeviceTypes,
} from '../../../data/hooks/collections/useDevices';
import { CompassCalibration } from '@mui/icons-material';
import { PhyconMUI } from '../../../components/puregui/Phycon/Phycon';
import useNavSide from '../../../data/hooks/useNavSide';
import {
  Button,
  Menu,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Tooltip,
  Skeleton,
} from '@mui/material';
import type { DeviceType } from '@phyllome/common';
import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import DeviceListPrototype from './DeviceListPrototype';
import { DevicesParent } from './GridSystem';
import useDeviceType from '../../../data/useDeviceType';

type FilterGroupProps = {
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: DeviceType[],
  ) => void;
  selected: DeviceType[];
  isMobile: boolean;
};

const FilterGroup: FC<FilterGroupProps> = ({
  handleChange,
  selected,
  isMobile,
}) => {
  return (
    <ToggleButtonGroup
      size={isMobile ? 'small' : 'large'}
      sx={isMobile ? {} : { height: '60px' }}
      color="primary"
      onChange={handleChange}
      aria-label="Platform"
      value={selected}
      orientation={isMobile ? 'vertical' : 'horizontal'}
    >
      {includedDeviceTypes.map((deviceType) => (
        <Tooltip title={deviceType} key={deviceType} arrow>
          <ToggleButton
            value={deviceType}
            selected={selected.includes(deviceType)}
            sx={{ justifyContent: isMobile ? 'left' : 'center' }}
          >
            <DeviceTypeToIcon deviceType={deviceType} />
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
};

const Devices: FC<{ brokerId: string }> = ({ brokerId }) => {
  const navSide = useNavSide();
  const [selected, setSelected] = React.useState<DeviceType[]>([]);

  const deviceList = useDevices(brokerId);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const deviceType = useDeviceType();

  // adding this sort here as useDevices groups zones with lighting controller
  const sortedDeviceList = deviceList.sort((a, b) => {
    if (a.deviceType === b.deviceType) {
      return a.deviceId.localeCompare(b.deviceId);
    }
    return a.deviceType.localeCompare(b.deviceType);
  });

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    data: DeviceType[],
  ) => {
    setSelected(data);
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <ContainerPrimary
      title="Devices"
      fullWidth={true}
      icon={<PhyconMUI icon={CompassCalibration} />}
    >
      <Box mb={2}>
        {!deviceType.isMobile && (
          <FilterGroup
            handleChange={handleChange}
            selected={selected}
            isMobile={deviceType.isMobile}
          />
        )}
        <Box mb={1}>
          {deviceType.isMobile && (
            <>
              <Button
                onClick={handleFilterClick}
                variant="contained"
                size="small"
              >
                Filter
              </Button>
              <Menu
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
              >
                <FilterGroup
                  handleChange={handleChange}
                  selected={selected}
                  isMobile={deviceType.isMobile}
                />
              </Menu>
            </>
          )}
        </Box>
        {!sortedDeviceList.length && (
          <Box mt={3}>
            <DevicesParent>
              {Array.from({ length: 20 }).map((i) => (
                <Skeleton
                  key={String(i)}
                  variant="rectangular"
                  animation="wave"
                  component="div"
                  sx={{ borderRadius: 1 }}
                  height={68}
                />
              ))}
            </DevicesParent>
          </Box>
        )}
        {sortedDeviceList.length && (
          <DeviceListPrototype
            devicesSelected={selected.length ? selected : includedDeviceTypes}
            deviceData={sortedDeviceList}
            onClick={(_device) =>
              navSide(`${_device.deviceType}/${_device.deviceId}`)
            }
          />
        )}
      </Box>
    </ContainerPrimary>
  );
};

export default Devices;
