import type { FC } from 'react';
import React from 'react';
import {
  faCrosshairs,
  faDatabase,
  faDesktop,
  faElevator,
  faInbox,
  faLayerGroup,
  faScissors,
  faSeedling,
  faThermometerHalf,
  faTint,
  faTractor,
  faWater,
  faWeight,
  faLightbulb,
  faFlask,
  faWind,
  faCamera,
  faBolt,
  faMicrochip,
  faShield,
  faCube,
  faRoute,
} from '@fortawesome/free-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { DeviceType } from '@phyllome/common';
import { PhyconFA } from '../puregui/Phycon/Phycon';

interface IProps {
  deviceType: DeviceType;
  color?: string;
  noMargin?: boolean;
  opacity?: number;
}
export const deviceTypeMap: { [key: string]: IconDefinition } = {
  camera: faCamera,
  traybot: faTractor,
  tray: faInbox,
  lift: faElevator,
  fertigator: faFlask,
  register: faLayerGroup,
  lighting: faLightbulb,
  irrigation: faWater,
  air: faThermometerHalf,
  co2: faWind,
  sentry: faCrosshairs,
  zone: faBolt,
  // Future (might not be named correctly)
  seeder: faSeedling,
  harvester: faScissors,
  nds: faTint,
  weightPacking: faWeight,
  consoleUI: faDesktop,
  cloud: faDatabase,
  packager: faCube,
  guard: faShield,
  transfer: faRoute,
};

const DeviceTypeToIcon: FC<IProps> = ({
  deviceType,
  color,
  noMargin = false,
  opacity = 1,
}) => {
  if (deviceTypeMap[deviceType]) {
    return (
      <PhyconFA
        icon={deviceTypeMap[deviceType]}
        color={color}
        noMargin={noMargin}
        opacity={opacity}
      />
    );
  } else {
    return <PhyconFA icon={faMicrochip} color={color} noMargin={noMargin} />;
  }
};

export default DeviceTypeToIcon;
