import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const ndsGraph: FavouriteDefinition = {
  ndsActivations: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'nds',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[NDS] ${deviceId}/activations`,
    color: colors.earth1,
    field: 'activations',
    brokerId,
    deviceId,
  }),
};

export default ndsGraph;
