import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import FaultMap from '../../components/FaultMap/FaultMap';
import type { LightingDeviceState } from '@phyllome/common';

const faultCodes = [
  'General',
  'Safety Relay',
  'Opearating Mode Conflict',
  'Main Breaker',
  'Current',
];

const LatestLightingFaults = ({
  latest,
}: {
  latest: FromFirebase<LightingDeviceState>;
}) => {
  return (
    <PaperSection
      mb={2}
      heading="Latest Lighting Faults"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <FaultMap labels={faultCodes} faults={latest.faults} />
    </PaperSection>
  );
};

export default LatestLightingFaults;
