import type { FC } from 'react';
import { useContext } from 'react';
import AppDrawerTitle from '../../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Box } from '@mui/material';
import useTraySeason from '../../../../data/hooks/collections/useTraySeason';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useZoneState from '../../../../devicespecific/Zone/hooks/useZoneState';
import SideBarTraySeasonConsumables from './SideBarTraySeasonConsumables';
import SideBarTraySeasonPhoto from './SideBarTraySeasonPhoto';
import SideBarTraySeasonInfo from './SideBarTraySeasonInfo';
import SideBarTraySeasonIrrigationSchedule from './SideBarTraySeasonIrrigationSchedule';
import SideBarTraySeasonEdit from './SideBarTraySeasonEdit';
import SideBarTraySeasonReassign from './SideBarTraySeasonReassign';
import SideBarTraySeasonDebug from './SideBarTraySeasonDebug';
import FormZoneOnOff from '../../../../devicespecific/Zone/forms/FormZoneOnOff';
import ActivityLog from '../../../../components/ActivityLog/ActivityLog';
import useSeason from '../../Seasons/useSeason';
import useSeed from '../../../../data/hooks/collections/useSeed';
import SideBarTraySeasonLightingSchedule from './SideBarTraySeasonLightingSchedule';

type IProps = {
  traySeasonId: string;
};

const SideBarEntryTraySeasonEntryPoint: FC<IProps> = ({ traySeasonId }) => {
  const { brokerId } = useContext(FarmContext);
  const traySeasonData = useTraySeason(traySeasonId);
  const title = `TraySeasonID: ${traySeasonId}`;
  const seasonData = useSeason(traySeasonData?.seasonId);
  const latestZoneInfo = useZoneState(
    brokerId,
    traySeasonData?.lightingZone?.toString() || '',
  );
  const seedData = useSeed(traySeasonData?.seedId);

  if (!traySeasonData) return null;
  if (!seasonData) return null;
  if (!seedData) return null;
  return (
    <>
      <AppDrawerTitle title={title} />
      <Box p={2}>
        <SideBarTraySeasonInfo
          seasonData={seasonData}
          traySeasonData={traySeasonData}
        />
        <SideBarTraySeasonEdit
          traySeasonData={traySeasonData}
          seasonData={seasonData}
        />
        <hr/>
        <SideBarTraySeasonPhoto traySeasonData={traySeasonData} />
        <SideBarTraySeasonConsumables
          seedData={seedData}
          traySeasonData={traySeasonData}
        />
        <SideBarTraySeasonIrrigationSchedule
          seasonData={seasonData}
          traySeasonData={traySeasonData}
        />
        <SideBarTraySeasonLightingSchedule
          seasonData={seasonData}
          seedData={seedData}
          zoneData={latestZoneInfo}
        />
        <ActivityLog
          identifier={`traySeason/${traySeasonData.id}/notes`}
          heading="Activity Log"
        />
        {latestZoneInfo && <FormZoneOnOff latest={latestZoneInfo} />}
        <SideBarTraySeasonReassign
          traySeasonData={traySeasonData}
          brokerId={brokerId}
        />
        <SideBarTraySeasonDebug traySeasonData={traySeasonData} />
      </Box>
    </>
  );
};

export default SideBarEntryTraySeasonEntryPoint;
