import type { DatabaseSeason, ZoneDeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import FormZoneSet from './forms/FormZoneSet';
import LatestZoneInfo from './LatestZoneInfo';
import FormZoneEnable from './forms/FormZoneEnable';
import FormZoneOnOff from './forms/FormZoneOnOff';

const ControlZone = ({
  latest,
  seasonData,
}: {
  latest: FromFirebase<ZoneDeviceState>;
  seasonData: FromFirebase<DatabaseSeason> | undefined;
}) => {
  return (
    <>
      <LatestZoneInfo latest={latest} />
      <FormZoneOnOff latest={latest} />
      <FormZoneEnable latest={latest} />
      <FormZoneSet latest={latest} seasonData={seasonData} />
    </>
  );
};

export default ControlZone;
