import type { AirDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestAir = (brokerId: string, deviceId: string) => {
  return useDeviceState<AirDeviceState>({
    brokerId,
    deviceId,
    deviceType: 'air',
  });
};

export default useLatestAir;
