import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { IrrigationDeviceState } from '@phyllome/common';
import {
  mqttDefinitionIrrigationSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import { Stack, Alert, AlertTitle } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormIrrigationSetModesDischarge = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionIrrigationSet, {
    modes: {
      discharge: latest?.valves_discharge || false,
    },
  });

  return (
    <PaperSection
      heading="Manual discharge mode control"
      mb={2}
      subheading={mqttDefinitionIrrigationSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              modes: { discharge: data.modes.discharge },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set discharge valve to ${data.modes.discharge ? 'Discharge' : 'Supply'}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          modes: yup.object({
            discharge: yup.boolean().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <Alert severity="warning">
            <AlertTitle>Discharge Mode Manual Only</AlertTitle>
            Discharge mode takes up to 10 seconds to change due to motorised
            valves, attempting to change mode during this time will trigger an
            alert. Discharge mode <strong>does not</strong> automatically turn
            off.
          </Alert>
          <SwitchElement label="Discharge Mode" name="modes.discharge" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to Device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationSetModesDischarge;
