import { Stack } from '@mui/system';
import { SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { Co2DeviceState } from '@phyllome/common';
import { mqttDefinitionCo2ConfigSet } from '@phyllome/common';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const kg2litres = (kg: number) => Math.round(kg * 505.8);
const tanks = [
  { id: 0, label: 'D tank', capacity: kg2litres(5.9) },
  { id: 1, label: 'E tank', capacity: kg2litres(14.5) },
  { id: 2, label: 'G tank', capacity: kg2litres(30) },
  { id: 3, label: '6-pack G tanks', capacity: kg2litres(180) },
  { id: 4, label: '12-pack G tanks', capacity: kg2litres(360) },
];

const FormCo2ConfigSetTanks = ({
  latest,
}: {
  latest: FromFirebase<Co2DeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCo2ConfigSet, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const tankId = tanks.find(
    (tank) => tank.capacity === latest.tankCapacity,
  )?.id;

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2ConfigSet, {
    tankCapacity: tankId || 0,
  });

  return (
    <PaperSection
      heading="CO2 Tank Configuration"
      mb={2}
      subheading={mqttDefinitionCo2ConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          const tankValue = tanks.find(
            (tank) => tank.id === parseInt(String(data.tankCapacity)),
          )?.capacity;

          controlLifecycle.run({
            payload: {
              tankCapacity: tankValue,
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set CO2 tank capacity to ${tankValue} litres`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          tankCapacity: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <SelectElement
            label="Gas Tank Capacity"
            name="tankCapacity"
            options={tanks.map((tank) => ({
              id: tank.id,
              label: `${tank.label} (${tank.capacity.toLocaleString()} litres)`,
            }))}
          />
          <p>Select the tank capacity currently installed</p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2ConfigSetTanks;
