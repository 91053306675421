import type { DatabaseTraySeason } from '@phyllome/common';
import { colors } from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import makeColorSelected from '../../../../helpers/makeColorSelected';
import type { FromFirebase } from '../../../../types/types';
import opacitySimulateColour from '../../../../helpers/opacitySimulateColour';
import calculateAgeInDays from '../../../../helpers/calculateAgeInDays';
import getColorByAge from '../../../../helpers/getColorByAge';

type OverlayTraySimpleProps = {
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
  onClick?: (traySeasonData: FromFirebase<DatabaseTraySeason>) => void;
  data: FromFirebase<DatabaseTraySeason>[];
};

const OverlayTraySimple = ({
  shelves,
  selectedTray,
  selectedSeason,
  data,
  onClick = () => null,
}: OverlayTraySimpleProps) => {
  return (
    <>
      {data.map((row, index) => {
        const isRejected = row.isRejected === true;
        const isFlagged = row.isFlagged === true;
        const isStarted = row.timestampGrowthStart ? true : false;
        const isCompleted = row.timestampGrowthEnd ? true : false;

        const toolTip = `${row.locationColumn}/${row.locationShelf}/${row.locationRow}`;

        const age = calculateAgeInDays(
          row.timestampGrowthStart,
          row.timestampGrowthEnd,
        );

        let background = getColorByAge(age);

        if (isCompleted) background = colors.earth2;
        if (isFlagged) background = colors.sunlight;
        if (isRejected) background = colors.earth3;
        if (!isStarted) background = colors.earth1;
        // if (isPaused) background = colors.sunlight;

        if (selectedSeason && row.seasonId !== selectedSeason) {
          background = opacitySimulateColour(background, 0.8);
        }
        return (
          <RackBox
            onClick={() => onClick(row)}
            key={index}
            zIndex={10}
            toolTip={toolTip}
            row={row.locationRow}
            shelf={row.locationShelf}
            shelves={shelves}
            isSelected={row.id === selectedTray}
            isGroupSelected={row.seasonId === selectedSeason}
            rackBoxStyleSelected={{
              border: '1px solid black',
              background: colors.ocean,
            }}
            rackBoxStyleHover={{
              background: makeColorSelected(background),
              transition: 'background 0.2s linear',
            }}
            rackBoxStyleGroupSelected={{
              opacity: 1,
              color: '#ffffff',
            }}
            style={{
              //opacity: selectedSeason ? 0.2 : 1,
              border: '1px solid transparent',
              transition: 'background 0.5s linear',
              cursor: 'pointer',
              background: background,
              color: selectedSeason ? '#aaa' : '#ffffff',
              textAlign: 'center',
            }}
          >
            {age}
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlayTraySimple;
