import { useContext, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Modal,
  TextField,
} from '@mui/material';
import useAction from '../../../data/hooks/useAction';
import { debounce } from 'lodash';
import { useDeepCompareEffect } from 'use-deep-compare';
import StringToIcon from './StringToIcon';
import type { SearchElement } from './useSearch';
import useNavSide from '../../../data/hooks/useNavSide';
import { useNavigate } from 'react-router-dom';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import {
  appStateActions,
  useAppState,
} from '../../../data/AppState/useAppState';
import { useHotkeys } from 'react-hotkeys-hook';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',

  p: 4,
};

const Search = () => {
  const { brokerId } = useContext(FarmContext);
  const { state, dispatch } = useAppState();

  const ref = useHotkeys(
    'esc',
    () => {
      dispatch({ type: appStateActions.CLOSESEARCHMODAL });
    },
    {
      enableOnFormTags: true,
    },
  );

  const _bPrefix = `/broker/${brokerId}`;
  const staticSearches: SearchElement[] = useMemo(
    () => [
      {
        label: 'Seeds (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: '/seeds',
      },
      {
        label: 'Dashboard (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/dashboard`,
      },
      {
        label: 'Seasons (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/seasons`,
      },
      {
        label: 'Trays (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/trays`,
      },
      {
        label: 'Devices (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/device`,
      },
      {
        label: 'Sprinklers (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/sprinklers`,
      },
      {
        label: 'Logs (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/logs/alerts`,
      },
      {
        label: 'Hardware Tasks (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/logs/tasks`,
      },
      {
        label: 'Alerts (page)',
        icon: 'page',
        actionType: 'fullpage',
        actionMeta: `${_bPrefix}/logs/alerts`,
      },
    ],
    [_bPrefix],
  );
  const [options, setOptions] = useState<SearchElement[]>(staticSearches);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const navSide = useNavSide();
  const navigation = useNavigate();

  const searchResults = useAction('getSearchByBroker');

  const debouncedGetResults = useRef(
    debounce((value: string) => getResults(value), 500),
  ).current;

  const selectHandler = (value: SearchElement) => {
    if (value.actionType === 'sidebar') {
      navSide(value.actionMeta);
    }
    if (value.actionType === 'fullpage') {
      navigation(value.actionMeta);
    }
    dispatch({ type: appStateActions.CLOSESEARCHMODAL });
  };

  const getResults = async (value: string) => {
    searchResults.run({
      brokerId,
      searchString: value,
    });
  };

  useDeepCompareEffect(() => {
    if (searchResults.msg) {
      setOptions([...(searchResults.msg as any), ...staticSearches]);
      setLoading(false);
    }
  }, [searchResults.msg]);

  const handleInputChange = async (
    event: React.ChangeEvent<unknown>,
    value: string,
  ) => {
    setInputValue(value);

    if (value && value.length > 1) {
      setLoading(true);
      debouncedGetResults(value);
    } else {
      setLoading(false);
    }

    if (value.length === 0) {
      setOptions(staticSearches);
    }
  };

  const handleChange = (event: any, newValue: any) => {
    selectHandler(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed', inputValue);

      const foo = options.find((d) => {
        return d.label.toLowerCase().includes(inputValue.toLowerCase());
      });

      if (foo) {
        selectHandler(foo);
      }
    }
  };

  return (
    <Modal
      open={state.isSearchModalOpen}
      onClose={() => {}}
      aria-labelledby="modal-modal-search"
      aria-describedby="modal-modal-searchforanything"
    >
      <Box sx={style}>
        <Autocomplete
          options={options}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleChange}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <span style={{ marginRight: '10px' }}>
                  <StringToIcon data={option.icon} />
                </span>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              ref={ref}
              autoFocus
              {...params}
              label="Search"
              variant="outlined"
              onKeyDown={handleKeyDown}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
    </Modal>
  );
};

export default Search;
