import type {
  DatabaseTraySeason,
  NDSSchedule,
} from '@phyllome/common';
import {
  colors,
  hashObject,
} from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import makeColorSelected from '../../../../helpers/makeColorSelected';
import type { FromFirebase } from '../../../../types/types';
import generateUniqueColors from '../../../../helpers/generateUniqueColours';
import { useMemo } from 'react';
import type { UseNDSLatestLookupReturn } from '../../../../devicespecific/NDS/hooks/useNDSLatestWithLookup';

type OverlayTrayIrrigationSchedulesProps = {
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
  onClick?: (traySeasonData: FromFirebase<DatabaseTraySeason>) => void;
  data: FromFirebase<DatabaseTraySeason>[];
  ndsData: UseNDSLatestLookupReturn;
};

const IrrigationSchedule = ({ data }: { data: NDSSchedule[] }) => {
  return data.map((item, key) => {
    return (
      <div key={key}>
        {item.day} / {item.dose} / {item.period}
      </div>
    );
  });
};

const OverlayTrayIrrigationSchedules = ({
  shelves,
  selectedTray,
  selectedSeason,
  data,
  onClick = () => null,
}: OverlayTrayIrrigationSchedulesProps) => {
  const cMap = useMemo(() => {
    const foo = data.map((row) => {
      return hashObject(row.defaultNDSSchedule || []);
    });

    const unique = [...new Set(foo)];
    const colorMap = generateUniqueColors(unique.length, 80);

    return unique.reduce<Record<string, string>>((accumulator, row, index) => {
      accumulator[row] = colorMap[index];
      return accumulator;
    }, {});
  }, [data]);

  return (
    <>
      {data.map((row, index) => {
        const objectHash = hashObject(row.defaultNDSSchedule || []);

        const background = row.defaultNDSSchedule?.length
          ? cMap[objectHash]
          : 'red';

        return (
          <RackBox
            toolTip={<IrrigationSchedule data={row.defaultNDSSchedule || []} />}
            onClick={() => onClick(row)}
            key={index}
            zIndex={10}
            row={row.locationRow}
            shelf={row.locationShelf}
            shelves={shelves}
            isSelected={row.id === selectedTray}
            isGroupSelected={row.seasonId === selectedSeason}
            rackBoxStyleSelected={{
              border: '1px solid black',
              background: colors.ocean,
            }}
            rackBoxStyleHover={{
              background: makeColorSelected(background),
              transition: 'background 0.2s linear',
            }}
            rackBoxStyleGroupSelected={{
              opacity: 1,
              color: '#ffffff',
            }}
            style={{
              border: '1px solid transparent',
              transition: 'background 0.5s linear',
              cursor: 'pointer',
              background: background,
              color: selectedSeason ? '#aaa' : '#ffffff',
              textAlign: 'center',
            }}
          ></RackBox>
        );
      })}
    </>
  );
};

export default OverlayTrayIrrigationSchedules;
