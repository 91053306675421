import {
  TextField,
  IconButton,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  InputAdornment,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import type { NDSSchedule } from '@phyllome/common';
import showNumber from '../../helpers/showNumber';
import { formatThousands } from '../../helpers/showNumber';

type Nullable<T> = { [P in keyof T]: T[P] | null };

const NDSScheduleRow = ({
  data,
  onDelete,
}: {
  data: Nullable<NDSSchedule>;
  onDelete: (args: number | undefined) => void;
}) => {
  return (
    <TableRow
      hover
      key={data.day}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {data.day}
      </TableCell>
      <TableCell>{showNumber(data.dose, formatThousands)}</TableCell>
      <TableCell>{showNumber(data.period, formatThousands)}</TableCell>
      <TableCell>
        <IconButton
          aria-label="delete"
          onClick={() => {
            onDelete(data.day as number);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const NDSScheduleRowForm = ({
  onAdd,
  data,
}: {
  data: Nullable<NDSSchedule>;
  onAdd: (args: NDSSchedule) => void;
}) => {
  const [day, setDay] = useState<number | null>(null);
  const [dose, setDose] = useState<number | null>(null);
  const [period, setPeriod] = useState<number | null>(null);

  useEffect(() => {
    setDay(data.day);
    setDose(data.dose);
    setPeriod(data.period);
  }, [data]);

  const isValid =
    day !== null && dose !== null && period !== null && dose > 0 && period > 0;

  return (
    <TableRow
      selected
      key="formRow"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <TextField
          label="Day"
          value={day !== null ? day : ''}
          size="small"
          onChange={(e) => {
            const result = isNaN(Number(e.target.value))
              ? null
              : Number(e.target.value);

            setDay(result);
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Dose"
          value={dose !== null ? dose : ''}
          size="small"
          onChange={(e) => {
            const result = isNaN(Number(e.target.value))
              ? null
              : Number(e.target.value);

            setDose(result);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">mL</InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Period"
          value={period !== null ? period : ''}
          size="small"
          onChange={(e) => {
            const result = isNaN(Number(e.target.value))
              ? null
              : Number(e.target.value);

            setPeriod(result);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">s</InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          disabled={!isValid}
          aria-label="delete"
          color="primary"
          onClick={() => {
            onAdd({
              day: Number(day),
              dose: Number(dose),
              period: Number(period),
            });
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface NDSScheduleUI {
  [key: number]: NDSSchedule;
}

const convertFromUITODB = (schedule: NDSScheduleUI): NDSSchedule[] => {
  const result: NDSSchedule[] = [];

  Object.keys(schedule).forEach((day) => {
    if (day !== 'undefined') {
      const { dose, period } = schedule[Number(day)];

      result.push({ day: Number(day), dose, period });
    } else {
      console.log('Warning! Day is undefined.');
    }
  });
  return result;
};

const convertFromDBTOUI = (schedule: NDSSchedule[]): NDSScheduleUI => {
  const result: NDSScheduleUI = {};

  schedule.forEach(({ day, dose, period }) => {
    result[day] = { day, dose, period };
  });
  return result;
};

type NDSScheduleProps = {
  scheduleDataDB: NDSSchedule[];
  onUpdate: (schedule: NDSSchedule[]) => void;
};

const NDSScheduleEdit = ({ scheduleDataDB, onUpdate }: NDSScheduleProps) => {
  const [schedule, setSchedule] = useState<NDSScheduleUI>([]);

  useEffect(() => {
    const dbTOUI = convertFromDBTOUI(scheduleDataDB);

    setSchedule(dbTOUI);
  }, [scheduleDataDB]);
  return (
    <>
      {Object.keys(schedule).length === 0 && (
        <Alert severity="warning">No data available.</Alert>
      )}
      <TableContainer>
        <Table aria-label="Sprinkler Schedule" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Dose&nbsp;(mL)</TableCell>
              <TableCell>Period&nbsp;(s)</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(schedule).map((day) => {
              const data = schedule[Number(day)];

              return (
                <NDSScheduleRow
                  key={day}
                  data={{ ...data, day: Number(day) || 0 }}
                  onDelete={(d) => {
                    if (d !== undefined) {
                      const newSchedule = { ...schedule };

                      delete newSchedule[d];
                      onUpdate(convertFromUITODB(newSchedule));
                      setSchedule(newSchedule);
                    }
                  }}
                />
              );
            })}
            <NDSScheduleRowForm
              data={{ day: null, dose: null, period: null }}
              onAdd={(p) => {
                const sch = {
                  ...schedule,
                  [p.day]: { day: p.day, dose: p.dose, period: p.period },
                };

                setSchedule(sch);
                onUpdate(convertFromUITODB(sch));
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default NDSScheduleEdit;
