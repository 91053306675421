import type { AnyObject } from '@phyllome/common';
import type { FC, ReactNode } from 'react';
import {   Chip, Table, TableBody, TableCell, TableRow } from '@mui/material';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import type { Timestamp } from 'firebase/firestore';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import type { ChipProps } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ContactlessIcon from '@mui/icons-material/Contactless';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import LinkIcon from '@mui/icons-material/Link';

type LatestDeviceDataProps = {
  data: AnyObject;
  nested?: boolean;
};

type DataValue = {
  key: string;
  value: unknown;
  type: string;
};

const getType = (key: string, value: unknown): string => {

  if (key === 'brokerId') return 'brokerId';
  if (key === 'deviceId') return 'deviceId';
  if (key === 'deviceType') return 'deviceType';
  if (key === 'alias') return 'alias';

  if (typeof value === 'object' && value !== null && 'nanoseconds' in value) return 'timestamp';

  if (Array.isArray(value)) return 'array';
  return typeof value;
};

const LatestDeviceData: FC<LatestDeviceDataProps> = ({ data, nested = false }) => {
  if (!data && data !== false) {
    return '(empty)';
  }

  if (typeof data !== 'object') {
    return <span>{String(data)}</span>;
  }

  if (Object.keys(data).length === 0) {
    return <span style={{ color: '#bbb' }}>(empty)</span>;
  }

  const formattedData: DataValue[] = Object.entries(data).map(([key, value]) => ({
    key,
    value,
    type: getType(key, value),
  }));

  const priorityKeys = ['brokerId', 'timestamp', 'deviceId', 'alias', 'deviceType'];

  const formattedOrderedByKey = formattedData.sort((a, b) => {
    // Check if either key is in the priority list
    const indexA = priorityKeys.indexOf(a.key);
    const indexB = priorityKeys.indexOf(b.key);

    // If both are priority keys, sort by their order in priorityKeys
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only A is priority, it goes first
    if (indexA !== -1) return -1;
    // If only B is priority, it goes first
    if (indexB !== -1) return 1;

    // For non-priority keys, use the existing numeric/string sort
    const numA = parseInt(a.key.replace(/\D/g, ''));
    const numB = parseInt(b.key.replace(/\D/g, ''));

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    return a.key.localeCompare(b.key);
  });

  const renderValue = (item: DataValue): ReactNode => {
    switch (item.type) {
      case 'brokerId':
        return (
          <DataRow highlighted itemKey={item.key} key={item.key} nested={nested} icon={<AgricultureIcon />}>
            {String(item.value)}
          </DataRow>
        );
      case 'deviceId':
        return (
          <DataRow highlighted itemKey={item.key} key={item.key} nested={nested} icon={<ContactlessIcon />} >
            {String(item.value)}
          </DataRow>
        );

      case 'deviceType':
        return (
          <DataRow
            highlighted itemKey={item.key} key={item.key}
            nested={nested}
            icon={<SettingsRemoteIcon/>}
          >
            {String(item.value).toUpperCase()}
          </DataRow>
        );

      case 'alias':
        return (
          <DataRow
            highlighted itemKey={item.key} key={item.key}
            nested={nested}
            icon={<LinkIcon/>}
          >
            {String(item.value)}
          </DataRow>
        );

      case 'timestamp':
        return (
          <DataRow  highlighted itemKey={item.key} key={item.key} nested={nested} icon={<WatchLaterIcon />}>
            {dateToString(item.value as Timestamp, dateFormatTypes.FULL)}
          </DataRow>

        );
      case 'object':
        return (
          <DataRow itemKey={item.key} key={item.key} nested={nested}>
            <LatestDeviceData data={item.value as AnyObject} nested={true} />
          </DataRow>
        );
      case 'boolean':
        return (
          <DataRow itemKey={item.key} key={item.key} nested={nested}>
            <span style={{ color: !item.value ? 'red' : 'green' }}>{String(item.value)}</span>
          </DataRow>
        );

      case 'array':
        return (
          <DataRow itemKey={item.key} key={item.key} nested={nested}>
            <LatestDeviceData
              data={(item.value as []).reduce((acc: { [key: number]: unknown }, value, index) => {
                acc[index] = value;
                return acc;
              }, {})} nested={true}
            />

          </DataRow>
        );
      default:
        return (
          <DataRow itemKey={item.key} key={item.key} nested={nested}>
            {String(item.value)}
          </DataRow>

        );
    }
  };

  return (
    <Table
      size="small" sx={{
        '& .MuiTableCell-root': {
          py: 0,
          px: nested ? 0 : undefined,
        },
      }}
    >
      <TableBody>
        {formattedOrderedByKey.map(renderValue)}
      </TableBody>
    </Table>
  );
};

const createLabelStyles = (nested: boolean) => ({
  width: '0%',
  padding: 0,
  borderBottom: nested ? 'none' : undefined,
  verticalAlign: 'top',
});

const ItemKey = ({ children }: { children: ReactNode }) => {
  return <div style={{ marginRight: '8px', textAlign: 'left' }}>
    {children}
  </div>;
};

interface DataRowProps {
  itemKey: string;
  nested?: boolean;
  children: ReactNode;
  icon?: ChipProps['icon']
  highlighted?: boolean;
}

const DataRow = ({ itemKey, nested = false, children, icon, highlighted }: DataRowProps) => (
  <TableRow
    key={itemKey} sx={{
      backgroundColor: highlighted ? '#ededed' : undefined,
    }}
  >
    <TableCell
      component="th"
      scope="row"
      sx={createLabelStyles(nested)}
    >
      <ItemKey>
        {icon ? <Chip sx={{ background: 'transparent' }} icon={icon} label={String(itemKey)} size="small"/> : itemKey}
      </ItemKey>

    </TableCell>
    <TableCell sx={{ borderBottom: nested ? 'none' : undefined }}>
      {children}
    </TableCell>
  </TableRow>
);

// const IconText = ({ icon, text }: { icon: ChipProps['icon'],  text: unknown }) => (
//   <Chip sx={{ background: 'transparent' }}  icon={icon} label={String(text)} size="small"/>

// );

export default LatestDeviceData;