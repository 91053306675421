import { Box } from '@mui/system';
import type { FC } from 'react';
import useDeviceType from '../../data/useDeviceType';

type IProps = {
  children?: React.ReactNode;
  minWidth?: number;
};

const Stacker: FC<IProps> = ({ children, minWidth = '600px' }) => {
  const deviceType = useDeviceType();

  minWidth = deviceType.isMobile ? '300px' : '500px';
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit,minmax(max(${minWidth}, 100%/(4) + 0.1%),1fr))`,
        gap: '10px',
      }}
    >
      {children}
    </Box>
  );
};

export default Stacker;
