import { Stack } from '@mui/system';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { ZoneDeviceState } from '@phyllome/common';
import {
  mqttDefinitionZoneOn,
  mqttDefinitionZoneOff,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import type { FromFirebase } from '../../../types/types';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormZoneOnOff = ({
  latest,
}: {
  latest: FromFirebase<ZoneDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycleOn = useControlSend(mqttDefinitionZoneOn, deviceId);
  const controlLifecycleOff = useControlSend(mqttDefinitionZoneOff, deviceId);
  const activityLog = useActivityLog(`device/zone/${deviceId}/notes`);

  const defaultValuesZoneOn = formCreateDefaultValues(mqttDefinitionZoneOn, {});
  const defaultValuesZoneOff = formCreateDefaultValues(
    mqttDefinitionZoneOff,
    {},
  );

  return (
    <PaperSection
      heading="Manual zone control"
      mb={2}
      subheading={
        mqttDefinitionZoneOn.topic + ' and ' + mqttDefinitionZoneOff.topic
      }
    >
      <Stack spacing={2} direction="row">
        {/* ON */}
        <PhormContainer
          defaultValues={defaultValuesZoneOn}
          onSubmit={() => {
            controlLifecycleOn.run({
              payload: {},
              topicParams: {
                deviceId: deviceId,
              },
            });
            activityLog.add(`Turned zone ${deviceId} on`, 'OPERATION');
          }}
          onError={(error) => console.log(error)}
          schema={yup.object({})}
        >
          <SubmitControl
            controlHook={controlLifecycleOn}
            variant={latest.active ? 'outlined' : 'contained'}
            color={latest.active ? 'secondary' : 'primary'}
          >
            Turn ON
          </SubmitControl>
        </PhormContainer>
        {/* OFF */}
        <PhormContainer
          defaultValues={defaultValuesZoneOff}
          onSubmit={() => {
            controlLifecycleOff.run({
              payload: {},
              topicParams: {
                deviceId: deviceId,
              },
            });
            activityLog.add(`Turned zone ${deviceId} off`, 'OPERATION');
          }}
          onError={(error) => console.log(error)}
          schema={yup.object({})}
        >
          <SubmitControl
            controlHook={controlLifecycleOff}
            variant={latest.active ? 'contained' : 'outlined'}
            color={latest.active ? 'primary' : 'secondary'}
          >
            Turn OFF
          </SubmitControl>
        </PhormContainer>
      </Stack>
    </PaperSection>
  );
};

export default FormZoneOnOff;
