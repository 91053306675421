import React from 'react';
import RechartVictoryHeading from '../Rechart/RechartVictoryHeading';
import type { FavouriteName } from './collectionFavourites';
import { getFavouriteGraph } from './collectionFavourites';
import RechartVictoryBoolHeading from '../Rechart/RechartVictoryBoolHeading';
import RechartVictoryEnumHeading from '../Rechart/RechartVictoryEnumHeading';
import ReChartVictoryCumulative from '../Rechart/RechartVictoryCumulative';
import { Alert, AlertTitle } from '@mui/material';
import OnScreen from '../OnScreen/OnScreen';
import type { GraphLimits } from './types';

type IGraphProps = {
  name: FavouriteName;
  brokerId: string;
  deviceId: string;
  height?: number;
  heading?: string;
  limits?: GraphLimits;
  target?: number;
};
const Graph: React.FC<IGraphProps> = ({
  name,
  height,
  brokerId,
  deviceId,
  heading,
  limits,
  target,
}: IGraphProps) => {
  const dataFunc = getFavouriteGraph(name);

  if (!dataFunc) {
    return (
      <Alert severity="error">
        <AlertTitle>Definition Error</AlertTitle>
        Can't find graph defintion for {name}.
      </Alert>
    );
  }
  const data = dataFunc({ brokerId, deviceId });

  switch (data.subType) {
    case 'linear':
      return (
        <OnScreen height={height} debug={{ name }}>
          <RechartVictoryHeading
            name={name}
            height={height}
            heading={heading || data.showHeading({ deviceId, brokerId })}
            color={data.color}
            brokerId={data.brokerId}
            deviceId={data.deviceId}
            deviceType={data.deviceType}
            field={data.field}
            constraints={data.constraints}
            limits={limits || data.limits}
            target={target || data.target}
          />
        </OnScreen>
      );
    case 'bool':
      return (
        <OnScreen height={height} debug={{ name }}>
          <RechartVictoryBoolHeading
            name={name}
            heading={heading || data.showHeading({ deviceId, brokerId })}
            color={data.color}
            brokerId={data.brokerId}
            deviceId={data.deviceId}
            deviceType={data.deviceType}
            field={data.field}
          />
        </OnScreen>
      );
    case 'enum':
      return (
        <OnScreen height={height} debug={{ name }}>
          <RechartVictoryEnumHeading
            name={name}
            mapping={data.mapping || {}}
            heading={heading || data.showHeading({ deviceId, brokerId })}
            color={data.color}
            brokerId={data.brokerId}
            deviceId={data.deviceId}
            deviceType={data.deviceType}
            field={data.field}
          />
        </OnScreen>
      );
    case 'cumulative':
      return (
        <OnScreen height={height} debug={{ name }}>
          <ReChartVictoryCumulative
            name={name}
            height={height}
            heading={heading || data.showHeading({ deviceId, brokerId })}
            color={data.color}
            brokerId={data.brokerId}
            deviceId={data.deviceId}
            deviceType={data.deviceType}
            field={data.field}
          />
        </OnScreen>
      );
    default:
      return (
        <Alert severity="error">
          Unknown graph type {data.subType}. (Graph.tsx line 94.)
        </Alert>
      );
  }
};

export default Graph;
