import { Tabs, Tab, Box, Alert } from '@mui/material';
import type { LiftDeviceState } from '@phyllome/common';
import { colors } from '@phyllome/common';
import React, { useContext, useEffect } from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import TabPanel from '../../components/TabPanel/TabPanel';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useLatestLift from './hooks/useLatestLift';
import LatestLift from './LatestLift';
import GraphLift from './GraphLift';
import ControlLift from './ControlLift';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestLiftInfo from './LatestLiftInfo';
import ConfigLift from './ConfigLift';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import useDeviceStateByAlias from '../../data/hooks/useDeviceStateByAlias';
import type { DeviceStateFirestore } from '../deviceTypes';

type IProps = {
  deviceId: string;
};

const SideBarEntryLift: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const [deviceState, setDeviceState] = React.useState<DeviceStateFirestore<LiftDeviceState>>();
  const [value, setValue] = React.useState<any>(0);
  const latestDeviceInfo = useLatestLift(brokerId, deviceId);
  const latestDeviceInfoByAlias = useDeviceStateByAlias<LiftDeviceState>({
    brokerId,
    alias: deviceId,
    deviceType: 'lift',
  });

  useEffect(() => {
    if (latestDeviceInfo) {
      setDeviceState(latestDeviceInfo);
    }
    if (latestDeviceInfoByAlias) {
      setDeviceState(latestDeviceInfoByAlias);
    }
  }, [latestDeviceInfo, latestDeviceInfoByAlias]);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!deviceState) {
    return (
      <Box p={2}>
        <Alert severity="info">No Latest Data for {deviceId}!</Alert>
      </Box>
    );
  }
  return (
    <>
      <AppDrawerTitle
        title={`lift/${
          deviceState.deviceId
        } (columns: ${deviceState.alias && deviceState.alias.join(', ')})`}
      />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Activity" value={4} />
        <Tab label="Alerts" value={5} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <LatestLiftInfo latest={deviceState} />
        <LatestLift latest={deviceState} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GraphLift latest={deviceState} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ControlLift latest={deviceState} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ConfigLift latest={deviceState} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/lift/${deviceId}/notes`}
          heading="Activity Log"
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType="lift"
        />
      </TabPanel>
    </>
  );
};

export default SideBarEntryLift;
