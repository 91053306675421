import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type {
  IrrigationDeviceState,
  IrrigationDeviceStateUnflattened,
} from '@phyllome/common';
import {
  mqttDefinitionIrrigationSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import { Alert, AlertTitle } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import unflatten from '../../../helpers/unflatten';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormIrrigationSetFillValve = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionIrrigationSet,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/irrigation/${deviceId}/notes`);

  const state = unflatten<IrrigationDeviceStateUnflattened>(latest);

  const defaultValues = formCreateDefaultValues(mqttDefinitionIrrigationSet, {
    valves: {
      mains: state.valves.mains || false,
    },
  });

  return (
    <PaperSection
      heading="Manual fill valve control"
      mb={2}
      subheading={mqttDefinitionIrrigationSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              valves: { mains: data.valves.mains },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set fill valve to ${data.valves.mains ? 'Open' : 'Closed'}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          valves: yup.object({
            mains: yup.boolean().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          {latest.automation_fill && latest.mode === 1 ? (
            <Alert severity="success">
              <AlertTitle>Automation Fill Enabled</AlertTitle>
              Automation fill is enabled, and operating mode is AUTO. When
              opened, valve will close when supply tank reaches{' '}
              <strong>{latest.supply_max}%</strong>.
            </Alert>
          ) : latest.mode === 1 ? (
            <Alert severity="warning">
              <AlertTitle>Automation Fill Disabled</AlertTitle>
              Automation fill is disabled, when opened, valve will not
              automatically close.
            </Alert>
          ) : (
            <Alert severity="warning">
              <AlertTitle>
                Operating Mode set to {latest.mode === 2 ? 'Manual' : 'OFF'}
              </AlertTitle>
              Operating mode panel switch is not set to{' '}
              <strong>Automatic</strong>, when opened, valve will not
              automatically close.
            </Alert>
          )}
          <SwitchElement label="Valves Mains" name="valves.mains" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to Device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormIrrigationSetFillValve;
