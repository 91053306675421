import type { TransferDeviceState } from '@phyllome/common';
import {
  mqttDefinitionTransferConfigGet,
} from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';
import FormTransferConfigSetSpeeds from './forms/FormTransferConfigSetSpeeds';

const ConfigTransfer = ({
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Transfer Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">TBD</LabelValue>
        <LabelValue label="Free memory">TBD</LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormTransferConfigSetSpeeds state={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionTransferConfigGet}
      />
    </>
  );
};

export default ConfigTransfer;
