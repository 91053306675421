import type { Timestamp } from 'firebase/firestore';
import dateToString, { dateFormatTypes } from '../../../helpers/dateToString';

const Date = ({ date }: { date?: Timestamp | null | undefined }) => {
  if (!date) return <span>no date</span>;
  return <UsingDateObject date={date.toDate()} />;
};

const UsingDateObject = ({ date }: { date: Date }) => {
  const string = dateToString(date, dateFormatTypes.DAYMONTHHOURSSECONDS);

  return <span>{string}</span>;
};

export default Date;
