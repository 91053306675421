import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import clsx from "clsx";
//import colors from "../../../styles/colors";
import { useAuth } from 'reactfire';
import { useMeasure } from 'react-use';
import {
  appStateActions,
  useAppState,
} from '../../../data/AppState/useAppState';
import LoggedInAs from './components/LoggedInAs';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  Toolbar,
  Drawer as MuiDrawer,
  Typography,
} from '@mui/material';
import Drawer from './styled/Drawer';
import useSideBar from '../../../sidebar/useSideBar';
import Search from '../../../pages/Broker/Search/Search';
import MenuIcon from '@mui/icons-material/Menu';
import NatureIcon from '@mui/icons-material/Nature';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { colors } from '@phyllome/common';
import DrawerHeader from './styled/DrawerHeader';
import { PhyconMUI } from '../../puregui/Phycon/Phycon';
import {
  CompassCalibration,
  ExitToApp,
  Home,
  ReportProblem,
  Search as SearchIcon,
} from '@mui/icons-material';
import DeviceTypeToIcon from '../../DeviceTypeToIcon/DeviceTypeToIcon';
import ListCustom from './components/ListCustom';
import Logo from '../../Logo/Logo';
import AppBar from './components/AppBar';
import useDeviceType from '../../../data/useDeviceType';
import { useAppDrawer } from '../../../sidebar/AppDrawer/useAppDrawer';
import NewVersion from './NewVersion';

const drawerWidth = 240;

interface IProps {
  children: React.ReactNode;
  title?: string;
  icon?: ReactNode;
  button?: ReactNode;
  fullWidth?: boolean;
}

const ContainerPrimary: FC<IProps> = ({
  children,
  title = 'Title',
  icon = <></>,
  button,
  fullWidth = false,
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const deviceType = useDeviceType();

  //const classes = useStyles();
  useSideBar();
  const { state: { isOpen: drawerIsOpen } } = useAppDrawer();
  const auth = useAuth();
  const { dispatch: appDispatch, state: appState } = useAppState();

  useEffect(() => {
    if (width > 0) {
      appDispatch({ type: appStateActions.SETAPPWIDTH, payload: width });
    }
  }, [appDispatch, width]);

  //const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const contentWidth = drawerIsOpen ? 900 : 1200;

  const containerWidth = fullWidth
    ? '100%'
    : deviceType.width < contentWidth
      ? deviceType.width - contentWidth
      : contentWidth;

  return (
    <>
      <Search />
      <div ref={ref} className="root">

        <AppBar position="fixed" open={open}>

          <Toolbar
            sx={{
              background: colors.fog,
              color: colors.night,
            }}
          >

            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={[
                    {
                      mr: 2,
                      display: open ? 'none' : 'flex',
                    },
                  ]}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              {icon}
              <Typography
                sx={{ display: 'flex', alignItems: 'center' }}
                variant="h6"
                noWrap
                component="div"
                color={colors.night}
              >
                {title}
              </Typography>
              <Grid item xs sx={{ marginTop: '10px' }}>
                <Grid container direction="row-reverse">
                  <Grid item>
                    {button && (
                      <Box
                        sx={{
                          marginRight: '8px',
                          display: 'flex',
                          justifyContext: 'flex-end',
                        }}
                      >
                        {button}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DrawerSelector
          isMobile={deviceType.isMobile}
          open={open}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          <DrawerHeader>
            <Grid
              container
              alignItems="center"
              display={open ? 'flex' : 'none'}
            >
              <Grid item sx={{ marginTop: '20px' }}>
                <Logo
                  width="100px"
                  variant="white"
                  onClick={handleDrawerClose}
                />
              </Grid>
              {/* <Grid item xs sx={{ marginTop: "10px" }}>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <IconButton
                      onClick={handleDrawerClose}
                      sx={{ color: colors.fog, justifyContent: "flex-end" }}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </DrawerHeader>
          <List>
            <ListCustom
              icon={<PhyconMUI icon={Home} />}
              text="Home"
              onClick={() => navigate('/')}
            />
            <ListCustom
              icon={<PhyconMUI icon={NatureIcon} />}
              text="Seeds"
              onClick={() => navigate('/seeds')}
            />
            {appState.brokerId && (
              <>
                <Divider sx={{ borderColor: '#89bb9a' }}></Divider>
                <ListCustom
                  icon={<PhyconMUI icon={ViewModuleIcon} />}
                  text="Dashboard"
                  onClick={() =>
                    navigate(`/broker/${appState.brokerId}/dashboard`)
                  }
                />
                <ListCustom
                  icon={<PhyconMUI icon={WbSunnyIcon} />}
                  text="Seasons"
                  onClick={() =>
                    navigate(`/broker/${appState.brokerId}/seasons`)
                  }
                />
                <ListCustom
                  icon={<DeviceTypeToIcon deviceType="tray" />}
                  text="Trays"
                  onClick={() => navigate(`/broker/${appState.brokerId}/trays`)}
                />
                <Divider sx={{ borderColor: '#89bb9a' }}></Divider>
                <ListCustom
                  icon={<PhyconMUI icon={CompassCalibration} />}
                  text="Devices"
                  onClick={() =>
                    navigate(`/broker/${appState.brokerId}/device`)
                  }
                />
                <ListCustom
                  icon={<DeviceTypeToIcon deviceType="nds" />}
                  text="Sprinklers"
                  onClick={() =>
                    navigate(`/broker/${appState.brokerId}/sprinklers`)
                  }
                />
                <ListCustom
                  icon={<PhyconMUI icon={ReportProblem} />}
                  text="Logs"
                  onClick={() =>
                    navigate(`/broker/${appState.brokerId}/logs/alerts`)
                  }
                />
                <ListCustom
                  icon={<PhyconMUI icon={SearchIcon} />}
                  text="Search"
                  onClick={() =>
                    appDispatch({ type: appStateActions.OPENSEARCHMODAL })
                  }
                />
                {/*
              <ListCustom
                iconOnly={!open}
                icon={<Email />}
                text={"Messaging(test)"}
                onClick={() =>
                  navigate(`/broker/${appState.brokerId}/messaging`)
                }
              /> */}
              </>
            )}
            <Divider sx={{ borderColor: '#89bb9a' }}></Divider>
            <ListCustom
              icon={<PhyconMUI icon={ExitToApp} />}
              text="Log Out"
              onClick={() => auth.signOut()}
            />
          </List>
        </DrawerSelector>
        {appState.appWidth > 0 && (
          <Box
            sx={{
              flexGrow: 1,
              margin: '70px 0 0 0',
              width: containerWidth,
              padding: '20px',
              paddingLeft: deviceType.isMobile ? '20px' : '100px', // pad left on desktop for drawer
            }}
          >
            {appState.isNewVersion && <NewVersion/>}
            {children}
          </Box>
        )}
        <LoggedInAs brokerId={appState.brokerId} visible={open} />
      </div>
    </>
  );
};

export default ContainerPrimary;

// Mick, it's clunky but it works.  I couldn't work out how to get the different variant to work on mobile with the styled drawer
const DrawerSelector: FC<{
  isMobile: boolean;
  open: boolean;
  children: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}> = ({ isMobile, children, open, onMouseEnter, onMouseLeave }) => {
  return isMobile ? (
    <MuiDrawer
      variant="temporary"
      anchor="left"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: colors.ripening,
          color: colors.fog,
        },
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </MuiDrawer>
  ) : (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Drawer>
  );
};
