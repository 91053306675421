type IO = number | string;
type TransformFn = (value: IO) => IO;

const showNumber = (
  number: IO | null | undefined,
  ...fns: TransformFn[]
): IO | undefined => {
  if (number === null || number === undefined) {
    return undefined;
  }
  const foo = fns.reduce((acc, fn) => fn(acc), number);

  return foo;
};

export const round = (decimalPlaces: number) => (num: IO) => {
  const factor = Math.pow(10, decimalPlaces);

  return Math.round(Number(num) * factor) / factor;
};

export function formatThousands(value: IO): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default showNumber;
