import type { FC, ReactElement } from 'react';
import type { colors } from '@phyllome/common';
import { Chip } from '@mui/material';
import { TaskAltSharp, RadioButtonUncheckedSharp } from '@mui/icons-material';
import ToolTipWrapper from '../ToolTipWrapper/ToolTipWrapper';

interface IProps {
  data: any;
  label?: string;
  size?: 'small' | 'medium' | 'tiny';
  color?: keyof typeof colors | 'primary' | 'secondary';
  icon?: ReactElement;
  tooltip?: string;
}

const ChipBool: FC<IProps> = ({
  data,
  label,
  size = 'small',
  color,
  icon = <TaskAltSharp />,
  tooltip,
}) => {
  const isTrue = data === true || data === 1 || data === '1';

  if (label === undefined) {
    label = isTrue === true ? 'true' : 'false';
  }

  let tiny = false;

  if (size == 'tiny') {
    size = 'small';
    tiny = true;
  }

  const sxStyle = tiny
    ? { marginRight: '-4px', marginLeft: '-8px', transform: 'scale(0.75)' }
    : { marginRight: '5px' };

  if (isTrue) {
    const chip = (
      <Chip
        label={label}
        size={size}
        color={color === undefined ? 'primary' : color}
        sx={sxStyle}
        icon={icon}
      />
    );

    return <ToolTipWrapper tooltip={tooltip}>{chip}</ToolTipWrapper>;
  } else {
    const chip = (
      <Chip
        label={label}
        size={size}
        sx={sxStyle}
        icon={<RadioButtonUncheckedSharp />}
      />
    );

    return <ToolTipWrapper tooltip={tooltip}>{chip}</ToolTipWrapper>;
  }
};

export default ChipBool;
