import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import AppDrawerTitle from '../../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Box, IconButton, CircularProgress } from '@mui/material';
import SideBarRackUtilForms from './SideBarRackUtilForms';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import type { DatabaseSeason, DatabaseTraySeason } from '@phyllome/common';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import useSeasons from '../../../../data/hooks/collections/useSeasons';
import DeleteIcon from '@mui/icons-material/Delete';
import useEndpointDelete from '../../../../data/hooks/useEndpointDelete';
import type { FromFirebase } from '../../../../types/types';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import { useEndpointTraySeasonUpdate } from '../../../../data/endpointHooks/endpointHooks';
import type { SelectedTrayItem } from './types';

type IProps = {
  columnId: number;
  refresh: string;
};

const getSeasonNameById = (
  seasonId: string,
  seasons: FromFirebase<DatabaseSeason>[],
): string => {
  const season = seasons.find((season) => season.id === seasonId);

  if (season) {
    return season.name;
  }
  return 'unknown';
};

const searchTraySeason = (
  traySeason: FromFirebase<DatabaseTraySeason>[],
  column: number,
  shelf: number,
  row: number,
) => {
  return traySeason.filter((tray) => {
    return (
      tray.locationRow === Number(row) &&
      tray.locationShelf === Number(shelf) &&
      tray.locationColumn === Number(column)
    );
  });
};

const describeRange = (coordinates: SelectedTrayItem[]): string => {
  if (coordinates.length === 0) {
    return 'Empty range';
  }
  if (coordinates.length === 1) {
    return `Shelf ${coordinates[0].shelf} / Row ${coordinates[0].row}`;
  }
  const rows = Array.from(new Set(coordinates.map((coord) => coord.row))).sort(
    (a, b) => a - b,
  );
  const shelves = Array.from(
    new Set(coordinates.map((coord) => coord.shelf)),
  ).sort((a, b) => a - b);
  const rowRange =
    rows.length > 1 ? `${rows[0]}-${rows[rows.length - 1]}` : `${rows[0]}`;
  const shelfRange =
    shelves.length > 1
      ? `${shelves[0]}-${shelves[shelves.length - 1]}`
      : `${shelves[0]}`;

  return `Shelf ${shelfRange} / Row ${rowRange}`;
};

const SideBarEntryRackUtil: FC<IProps> = ({ columnId, refresh }) => {
  const { brokerId } = useContext(FarmContext);

  const [trayItems, setTrayItems] = useState<SelectedTrayItem[]>([]);
  const [rowDescription, setRowDescription] = useState<string>('');
  const traySeasons = useTraySeasonsCurrentByColID({ brokerId, columnId });
  const seasons = useSeasons({ brokerId, isComplete: false });
  const [foundTrays, setFoundTrays] = useState<
    FromFirebase<DatabaseTraySeason>[]
  >([]);
  const [hasAllocatedSeasons, setHasAllocatedSeasons] = useState<any>(false);
  const trayUpdate = useEndpointTraySeasonUpdate();
  const useDeleteTraySeason = useEndpointDelete('traydelete');

  const getColumns = (
    columnId: number,
    seasons: FromFirebase<DatabaseSeason>[],
  ): GridColDef[] => {
    return [
      { field: 'trayId', headerName: 'ID', width: 90 },
      {
        field: 'seasonId',
        headerName: 'Season',
        width: 200,
        editable: true,
        type: 'singleSelect',
        valueOptions: seasons.map((season) => {
          return { value: season.id, label: season.name };
        }),
        renderCell: (params) => {
          const seasonId = params.value as string;

          return getSeasonNameById(seasonId, seasons);
        },
      },

      {
        field: 'locationColumn',
        headerName: 'col',
        width: 20,
        editable: true,
      },
      {
        field: 'locationShelf',
        headerName: 'shelf',
        width: 20,
        editable: true,
      },
      {
        field: 'locationRow',
        headerName: 'row',
        width: 20,
        editable: true,
      },
      {
        field: 'remove',
        type: 'actions',
        renderCell(params) {
          return (
            <IconButton
              disabled={useDeleteTraySeason.status === 'RUNNING'}
              aria-label="delete"
              size="large"
              onClick={() => {
                useDeleteTraySeason.run({ id: params.row.id });
              }}
            >
              {useDeleteTraySeason.status === 'RUNNING' && (
                <CircularProgress size="1rem" />
              )}
              {useDeleteTraySeason.status !== 'RUNNING' && <DeleteIcon />}
            </IconButton>
          );
        },

        width: 100,
        sortable: false,
        filterable: false,
      },
    ];
  };

  useEffect(() => {
    const _selection = localStorage.getItem('selection');

    if (!_selection) return;
    const parsedSelection = JSON.parse(_selection) as SelectedTrayItem[];

    setTrayItems(parsedSelection);

    setRowDescription(describeRange(parsedSelection));

    const es = parsedSelection
      .map((tray) => {
        return searchTraySeason(traySeasons, columnId, tray.shelf, tray.row);
      })
      .reduce((acc, val) => acc.concat(val), []);

    setFoundTrays(es);
    setHasAllocatedSeasons(es.length > 0);
  }, [refresh, columnId, traySeasons]);

  const title = `Rack Selection (${trayItems.length} selected)`;

  return (
    <>
      <AppDrawerTitle title={title} />
      <Box
        sx={{
          background: '#014D80',
          color: 'white',
          fontSize: '80%',
          textAlign: 'center',
          paddingBottom: '5px',
        }}
      >
        Column {columnId} / {rowDescription}
      </Box>
      {!hasAllocatedSeasons && (
        <Box p={2}>
          <SideBarRackUtilForms columnId={columnId} trays={trayItems} />
        </Box>
      )}
      {foundTrays.length > 0 && (
        <Box p={2}>
          <PaperSection heading="Found Trays">
            <Box>
              <DataGrid
                processRowUpdate={(
                  params: FromFirebase<DatabaseTraySeason>,
                ) => {
                  trayUpdate.run({
                    id: params.id,
                    seasonId: params.seasonId,
                    locationColumn: params.locationColumn,
                    locationRow: params.locationRow,
                    locationShelf: params.locationShelf,
                    brokerId: params.brokerId,
                    trayId: params.trayId,
                  });
                  return params;
                }}
                hideFooter={true}
                rows={foundTrays}
                columns={getColumns(columnId, seasons)}
              />
            </Box>
          </PaperSection>
        </Box>
      )}
    </>
  );
};

export default SideBarEntryRackUtil;
