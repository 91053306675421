import type { SentryDeviceState } from '@phyllome/common';
import type { FC } from 'react';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import type { FromFirebase } from '../../types/types';

type IProps = {
  latest: FromFirebase<SentryDeviceState>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GraphSentry: FC<IProps> = ({ latest }) => {
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphSentry;
