import useDevicesByType from '../../../data/hooks/collections/useDevicesByType';
import type { TraybotDeviceState } from '@phyllome/common';

const useLatestTraybotByColumn = (brokerId: string, columnId: number) => {
  const traybots = useDevicesByType<TraybotDeviceState>(brokerId, 'traybot');

  const tbFound = traybots.find((traybot) =>
    traybot.alias.includes(String(columnId)),
  );

  return tbFound?.properties;
};

export default useLatestTraybotByColumn;
