import type { GuardDeviceState } from '@phyllome/common';
import {
  mqttDefinitionGuardConfigGet,
} from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import { formatBytes } from '../../helpers/formatBytes';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigGuard = ({
  latest,
}: {
  latest: FromFirebase<GuardDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Air Monitor Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">{latest.network_ip}</LabelValue>
        <LabelValue label="Free memory">
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionGuardConfigGet}
      />
    </>
  );
};

export default ConfigGuard;
