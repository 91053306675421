import type { SeederDeviceState } from '@phyllome/common';
import {
  mqttDefinitionSeederConfigGet,
} from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';
import FormSeederConfigTray from './forms/FormSeederConfigTray';
import FormSeederConfigSpeed from './forms/FormSeederConfigSpeed';

const ConfigSeeder = ({
  latest,
}: {
  latest: FromFirebase<SeederDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Seeder Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest?.firmware}</LabelValue>
        <LabelValue label="Build Date">{latest?.compiled}</LabelValue>
        <LabelValue label="Free memory">TBD</LabelValue>
        <LabelValue label="Network">TBD</LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormSeederConfigTray latest={latest} />
      <FormSeederConfigSpeed latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionSeederConfigGet}
      />
    </>
  );
};

export default ConfigSeeder;
