import type { CameraDeviceState } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import type { FromFirebase } from '../../types/types';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphCamera = ({
  latest,
}: {
  latest: FromFirebase<CameraDeviceState>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { deviceId, brokerId } = latest;

  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphCamera;
