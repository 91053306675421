import { styled, TableCell, tableCellClasses } from '@mui/material';
import { colors } from '@phyllome/common';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.night,
    color: colors.fog, //
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default StyledTableCell;
