import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { SeederDeviceState } from '@phyllome/common';
import { mqttDefinitionSeederSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import useActivityLog from '../../../data/hooks/useActivityLog';

const FormSeederSetCount = ({
  latest,
}: {
  latest: FromFirebase<SeederDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionSeederSet, deviceId);
  const activityLog = useActivityLog(`device/seeder/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionSeederSet, {
    count: latest?.count || 0,
  });

  return (
    <PaperSection
      heading="Seeder counter override"
      mb={2}
      subheading={mqttDefinitionSeederSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              count: data.count,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Manually set trays sown to ${data.count}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          count: yup.number().min(0).required(),
        })}
      >
        <Stack spacing={2}>
          <p>Manually override the current tray count value</p>
          <TextFieldElement label="Current Tray Count" name="count" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to Device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormSeederSetCount;
