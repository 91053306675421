import type { FC } from 'react';
import { useContext } from 'react';
import FarmContext from '../../FarmProvider/FarmContext';
import ScaffoldRackContainer from '../components/ScaffoldRackContainer';
import RackIterator from '../components/RackIterator';
import ScaffoldLift from '../components/ScaffoldLift';
import { Box } from '@mui/material';
import ScaffoldRackSection from '../components/ScaffoldRackSection';
import { rackBoxCSS } from '../components/RackBox';

type ScaffoldProps = {
  column: number;
  onClick?: (row: number, shelf: number, column: number) => void;
  children?: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const RackIteratorDefaultRender = ({
  shelf,
  row,
}: {
  shelf: number;
  row: number;
}) => {
  return (
    <div
      style={{
        ...rackBoxCSS,
        color: '#bbb',
      }}
    >
      {shelf}/{row}
    </div>
  );
};

const ScaffoldRack: FC<ScaffoldProps> = ({
  column,
  onClick = () => null,
  children = null,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
}) => {
  const farmConfig = useContext(FarmContext);
  const { rows, shelves } = farmConfig.columnSpec[column - 1];

  return (
    <ScaffoldRackContainer
      shelves={shelves}
      rows={rows}
      zIndex={100}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ScaffoldRackSection rows={rows} shelves={shelves} />
      <ScaffoldLift shelves={shelves} column={column} />
      <RackIterator
        rows={rows}
        shelves={shelves}
        render={RackIteratorDefaultRender}
        onClick={(row, shelf) => onClick(row, shelf, column)}
      />
      {children && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            zIndex: 1000,
          }}
        >
          {children}
        </Box>
      )}
      {children}
    </ScaffoldRackContainer>
  );
};

export default ScaffoldRack;
