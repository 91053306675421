import type { PackagerDeviceState } from '@phyllome/common';
import type { FC } from 'react';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import { Box } from '@mui/material';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';

type IProps = {
  latest: FromFirebase<PackagerDeviceState>;
};

const LatestPackagerInfo: FC<IProps> = ({ latest }) => {
  const heroItems = [
    {
      label: 'Bag Count',
      text: latest.count.toString(),
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Packager Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}></Box>
    </PaperSection>
  );
};

export default LatestPackagerInfo;
