import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { SeederDeviceState } from '@phyllome/common';
import {
  mqttDefinitionSeederConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormSeederConfigTray = ({
  latest,
}: {
  latest: FromFirebase<SeederDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionSeederConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/seeder/${deviceId}/notes`);
  const defaultValues = formCreateDefaultValues(mqttDefinitionSeederConfigSet, {
    start: latest?.start || 0,
    end: latest?.end || 0,
  });

  return (
    <PaperSection
      heading="Seeder tray positions"
      mb={2}
      subheading={mqttDefinitionSeederConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              start: data.start,
              end: data.end,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });

          activityLog.add(
            `Set seeder positions, start: ${data.start} and end: ${data.end}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          start: yup.number().min(0).max(4000).required(),
          end: yup.number().min(0).max(4000).required(),
        })}
      >
        <Stack spacing={2}>
          <p>
            Set the start and end positions for the seeder to deploy seeds.
            (Measured in mm from the laser sensor)
          </p>
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Tray Start" name="start" />
            <TextFieldElement label="Tray End" name="end" />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormSeederConfigTray;
