import type { FC } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

type OnScreenProps = {
  children: React.ReactNode;
  height?: number | string;
  debug?: any;
};

const OnScreen: FC<OnScreenProps> = ({ children, height }) => {
  const { ref, inView } = useInView({
    threshold: 0.4,

    /* Optional options */
  });

  if (inView) return <div>{children}</div>;
  return (
    <div ref={ref} style={{ height: height || '300px' }}>
      {inView ? children : null}
    </div>
  );
};

export default OnScreen;
