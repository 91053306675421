import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import { mqttDefinitionNDSConfigSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { NDSDeviceFromAction } from '../../../types/types';
import parseNDSDeviceId from '../../../helpers/parseNDSDeviceId';

const FormNDSConfigSetWithDeviceHeartBeat = ({
  deviceState,
}: {
  deviceState: NDSDeviceFromAction;
}) => {
  const controlLifecycle = useControlSend(
    mqttDefinitionNDSConfigSet,
    `${deviceState.deviceId}`,
  );

  const { heartbeat } = deviceState.properties;

  const defaultValues = {
    heartbeat: heartbeat || 0,
  };

  const { column, row, shelf } = parseNDSDeviceId(deviceState.deviceId);

  return (
    <PaperSection
      heading="NDS State Heartbeat Configuration"
      mb={2}
      subheading={mqttDefinitionNDSConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              heartbeat: data.heartbeat,
            },
            topicParams: {
              column,
              row,
              shelf,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          heartbeat: yup.number().min(30).required(),
        })}
      >
        <Stack spacing={2}>
          <p>Interval (in seconds) between nds state messages.</p>
          <TextFieldElement label="Heartbeat" name="heartbeat" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormNDSConfigSetWithDeviceHeartBeat;
