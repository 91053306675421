import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import {
  useAppState,
  appStateActions,
} from '../../../data/AppState/useAppState';

interface IProps {
  title: string;
  fullscreenOnly?: boolean;
}

const AppDrawerTitle: FC<IProps> = ({ title, fullscreenOnly = false }) => {
  const appState = useAppState();

  useLayoutEffect(() => {
    const func = appState.dispatch;

    func({
      type: appStateActions.SETSIDEBARTITLE,
      payload: title,
    });

    if (fullscreenOnly) {
      func({
        type: appStateActions.EXPANDSIDEBAR,
      });
    } else {
      func({
        type: appStateActions.CONTRACTSIDEBAR,
      });
    }
  }, [appState.dispatch, fullscreenOnly, title]);
  return null;
};

export default AppDrawerTitle;
