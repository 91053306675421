import type { FC } from 'react';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import { Box, Grid } from '@mui/material';
import type { FromFirebase } from '../../../../types/types';
import type { DatabaseDevices } from '@phyllome/common';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import ModeStandbySharpIcon from '@mui/icons-material/ModeStandbySharp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';
import TaskAltSharp from '@mui/icons-material/TaskAltSharp';
import ErrorIcon from '@mui/icons-material/Error';
import ChipBool from '../../../../components/puregui/ChipBool/ChipBool';
import ChipEnum from '../../../../components/puregui/ChipEnum/ChipEnum';
import HeroGroup from '../../../../components/puregui/HeroItem/HeroGroup';
import type { ChipEnumData } from '../../../../components/puregui/ChipEnum/types';
import type tankDefinition from '../../../../components/TankLevel/TankDefinition';
import TankLevel from '../../../../components/TankLevel/TankLevel';
import calculatePercentageDifference from '../../../../helpers/calculatePercentageDifference';
import { Constants } from '../../../../types/constants';

const IrrigationModeStates: ChipEnumData = [
  {
    label: 'Irrigation Off',
    value: 0,
    color: 'earth3',
    icon: <ModeStandbySharpIcon />,
  },
  {
    label: 'Irrigation Auto',
    value: 1,
    color: 'primary',
    icon: <AutoModeSharpIcon />,
  },
  {
    label: 'Irrigation Manual',
    value: 2,
    color: 'sunlight',
    icon: <PlayCircleOutlineIcon />,
  },
];
const CO2ModeStates: ChipEnumData = [
  {
    label: 'CO2 Off',
    value: 0,
    color: 'earth3',
    icon: <ModeStandbySharpIcon />,
  },
  {
    label: 'CO2 Auto',
    value: 1,
    color: 'primary',
    icon: <AutoModeSharpIcon />,
  },
  {
    label: 'CO2 Manual',
    value: 2,
    color: 'sunlight',
    icon: <PlayCircleOutlineIcon />,
  },
];

const IrrigationSupplyStates: ChipEnumData = [
  {
    label: 'Supply Level',
    value: 0,
    color: 'primary',
    icon: <TaskAltSharp />,
  },
  {
    label: 'Supply Level',
    value: 1,
    color: 'earth3',
    icon: <ReportSharpIcon />,
  },
];

const GuardModeStates: ChipEnumData = [
  {
    label: 'Guard Inhibit',
    value: 0,
    color: 'earth3',
    icon: <ErrorIcon />,
  },
  {
    label: 'Guard Manual Only',
    value: 1,
    color: 'sunlight',
    icon: <PlayCircleOutlineIcon />,
  },
  {
    label: 'Guard Auto',
    value: 2,
    color: 'primary',
    icon: <AutoModeSharpIcon />,
  },
];

interface ICustomConsoleDeviceStatesProps {
  deviceData: FromFirebase<DatabaseDevices>[];
}

const CustomConsoleDeviceStatesAlexandria: FC<
  ICustomConsoleDeviceStatesProps
> = ({ deviceData }) => {
  const fertigatorDeviceData = deviceData.find(
    (deviceData) => deviceData.deviceType === 'fertigator',
  );
  const co2DeviceData = deviceData.find(
    (deviceData) => deviceData.deviceType === 'co2',
  );

  const irrigationDeviceData = deviceData.find(
    (deviceData) => deviceData.deviceType === 'irrigation',
  );

  const lightingDeviceDataGrowroom1 = deviceData.find(
    (deviceData) =>
      deviceData.deviceType === 'lighting' &&
      deviceData.deviceId === 'growroom1',
  );

  const lightingDeviceDataGrowroom2 = deviceData.find(
    (deviceData) =>
      deviceData.deviceType === 'lighting' &&
      deviceData.deviceId === 'growroom2',
  );

  const guardData = deviceData.find(
    (deviceData) => deviceData.deviceType === 'guard',
  );

  const guardMode = guardData?.properties.automate
    ? 2
    : guardData?.properties.move
      ? 1
      : 0;

  const heroItems = [
    {
      label: 'CO2',
      text: co2DeviceData?.properties.ppm?.toString(),
      sup: 'ppm',
    },
    {
      label: 'EC',
      text: fertigatorDeviceData?.properties.sample_ec?.toString(),
      sup: 'mS/cm',
    },
    {
      label: 'Ph',
      text: fertigatorDeviceData?.properties.sample_ph?.toString(),
      sup: 'pH',
    },
    {
      label: 'Oxygen',
      text: fertigatorDeviceData?.properties.sample_do?.toString(),
      sup: 'mg/L',
    },
    {
      label: 'ORP',
      text: fertigatorDeviceData?.properties.sample_orp?.toString(),
      sup: 'mV',
    },
    {
      label: 'Return Pressure',
      text: irrigationDeviceData?.properties.pressures_return?.toString(),
      sup: 'kpa',
      color:
        Number(irrigationDeviceData?.properties.pressures_return) >=
        irrigationDeviceData?.properties.pressure_max
          ? 'error'
          : 'black',
    },
    {
      label: 'Supply Level',
      text: irrigationDeviceData?.properties.levels_supply?.toString(),
      sup: '%',
      color:
        Number(irrigationDeviceData?.properties.levels_supply) <=
        irrigationDeviceData?.properties.supply_min
          ? 'error'
          : 'black',
    },
    {
      label: 'Return Level',
      text: irrigationDeviceData?.properties.levels_return?.toString(),
      sup: '%',
    },
  ];

  const tanks: Array<tankDefinition> = [
    {
      label: 'Acid',
      consumed: calculatePercentageDifference(
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY,
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY - fertigatorDeviceData?.properties.tanks_acid,
      ),
      payload: { acid: true },
    },
    {
      label: 'Nutrient A',
      consumed: calculatePercentageDifference(
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY,
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY - fertigatorDeviceData?.properties.tanks_nutrientA,
      ),
      payload: { nutrientA: true },
    },
    {
      label: 'Nutrient B',
      consumed: calculatePercentageDifference(
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY,
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY - fertigatorDeviceData?.properties.tanks_nutrientB,
      ),
      payload: { nutrientB: true },
    },
    {
      label: 'Peroxide',
      consumed: calculatePercentageDifference(
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY,
        Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY - fertigatorDeviceData?.properties.tanks_peroxide,
      ),
      payload: { peroxide: true },
    },
    {
      label: 'CO2',
      consumed:
        (co2DeviceData?.properties.tank /
          co2DeviceData?.properties.tankCapacity) *
        100,
      payload: { co2: true },
    },
    {
      label: 'Supply',
      consumed: irrigationDeviceData?.properties.levels_supply || 0,
      payload: { supply: true },
    },
    {
      label: 'Return',
      consumed: irrigationDeviceData?.properties.levels_return || 0,
      payload: { supply: true },
    },
  ];

  return (
    <PaperSection mb={2} heading="Farm snapshot" sx={{ textAlign: 'center' }}>
      <Box mb={4}>
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb={4}>
        <Grid container spacing={8} justifyContent="center">
          {tanks.map((tank, index) => (
            <Grid item key={index}>
              <TankLevel label={tank.label} level={tank.consumed} key={index} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={2}>
        <ChipEnum data={GuardModeStates} value={guardMode} />
        <ChipEnum data={CO2ModeStates} value={co2DeviceData?.properties.mode} />
        <ChipEnum
          data={IrrigationModeStates}
          value={irrigationDeviceData?.properties.mode}
        />
        <ChipBool
          data={irrigationDeviceData?.properties.pumps_irrigation}
          label="Irrigation Pump"
        />
        <ChipEnum
          data={IrrigationSupplyStates}
          value={
            Number(irrigationDeviceData?.properties.levels_supply) <=
            irrigationDeviceData?.properties.supply_min
              ? 1
              : 0
          }
        />
        <ChipBool
          data={fertigatorDeviceData?.properties.ec_enable}
          label="EC enabled"
        />
        <ChipBool
          data={fertigatorDeviceData?.properties.acid_enable}
          label="Acid enabled"
        />
        <ChipBool
          data={fertigatorDeviceData?.properties.peroxide_enable}
          label="H2O2 enabled"
        />
        <ChipBool
          data={lightingDeviceDataGrowroom1?.properties.mode === 1}
          label="Lighting 1"
        />
        <ChipBool
          data={lightingDeviceDataGrowroom2?.properties.mode === 1}
          label="Lighting 2"
        />
      </Box>
    </PaperSection>
  );
};

export default CustomConsoleDeviceStatesAlexandria;
