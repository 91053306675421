import LatestDeviceData from '../../components/LatestDeviceData/LatestDeviceData';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import unflatten from '../../helpers/unflatten';
import type { FromFirebase } from '../../types/types';
import type { ZoneDeviceState } from '@phyllome/common';

const LatestZone = ({ latest }: { latest: FromFirebase<ZoneDeviceState> }) => {
  return (
    <PaperSection
      heading="Latest Zone Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <LatestDeviceData data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestZone;
