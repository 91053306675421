import type { FC } from 'react';
import { Chip } from '@mui/material';
import { RadioButtonUncheckedSharp } from '@mui/icons-material';
import type { ChipEnumData } from './types';
import ToolTipWrapper from '../ToolTipWrapper/ToolTipWrapper';

interface IProps {
  value: number;
  data: ChipEnumData;
  size?: 'small' | 'medium';
  tooltip?: string;
}

const ChipEnum: FC<IProps> = ({ value, data, size = 'small', tooltip }) => {
  const chipEnumProps = data.find((item) => item.value === value);

  if (chipEnumProps !== undefined) {
    return (
      <ToolTipWrapper tooltip={tooltip}>
        <Chip
          label={chipEnumProps.label}
          size={size}
          {...(chipEnumProps.color ? { color: chipEnumProps.color } : {})}
          sx={{ marginRight: '5px' }}
          icon={
            chipEnumProps.icon === undefined ? (
              <RadioButtonUncheckedSharp />
            ) : (
              chipEnumProps.icon
            )
          }
        />
      </ToolTipWrapper>
    );
  } else {
    return null;
  }
};

export default ChipEnum;
