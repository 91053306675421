import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { TraybotDeviceState } from '@phyllome/common';
import {
  mqttDefinitionTraybotConfigSet,
} from '@phyllome/common';
import RangeSlider from '../../../components/RangeSlider/RangeSlider';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormTraybotConfigSetLift = ({
  latest,
}: {
  latest: FromFirebase<TraybotDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const columnId = latest.columnId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotConfigSet,
    String(columnId),
  );
  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotConfigSet,
    {
      lift: {
        raisedLimit: latest?.lift_raisedLimit || 0,
        loweredLimit: latest?.lift_loweredLimit || 0,
      },
    },
  );

  return (
    <PaperSection
      heading="Traybot Lift Configuration"
      mb={2}
      subheading={mqttDefinitionTraybotConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              lift: {
                raisedLimit: data?.lift?.raisedLimit,
                loweredLimit: data?.lift?.loweredLimit,
              },
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });

          activityLog.add('Updated traybot lift config}', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          lift: yup.object({
            raisedLimit: yup
              .number()
              .min(700)
              .max(2000)
              .required()
              .test(
                'is-less-than-lowered',
                'Raised limit must be less than lowered limit',
                function (value) {
                  return value < this.parent.loweredLimit;
                },
              ),
            loweredLimit: yup
              .number()
              .min(700)
              .max(2000)
              .required()
              .test(
                'is-more-than-raised',
                'Lowered limit must be more than raised limit',
                function (value) {
                  return value > this.parent.raisedLimit;
                },
              ),
          }),
        })}
      >
        <Stack spacing={2}>
          <RangeSlider min={1000} max={2000} step={20} minDistance={100} />
          <TextFieldElement label="Lift Raised Limit" name="lift.raisedLimit" />
          <TextFieldElement
            label="Lift Lowered Limit"
            name="lift.loweredLimit"
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotConfigSetLift;
