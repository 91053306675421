import type { LiftDeviceState } from '@phyllome/common';
import Alert from '@mui/material/Alert';
import type { FromFirebase } from '../../types/types';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphLift = ({ latest }: { latest: FromFirebase<LiftDeviceState> }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { deviceId, brokerId } = latest;

  console.log('latest', latest);
  return (
    <Alert severity="info" variant="outlined">
      Not yet implemented
    </Alert>
  );
};

export default GraphLift;
