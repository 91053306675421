import RackBox from '../../../../components/factoryui/components/RackBox';
import { useContext, useEffect, useState } from 'react';
import { SprinklerContext } from '../SprinkerContext';
import generateUniqueColors from '../../../../helpers/generateUniqueColours';
import type { NDSDeviceFromAction } from '../../../../types/types';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
};

const OverlaySpinklerFirmware = ({ shelves, data }: OverlayTraySimpleProps) => {
  const sprinklerContext = useContext(SprinklerContext);
  const [colourLookup, setColourLookup] = useState<Record<string, string>>({});

  useEffect(() => {
    const fw = sprinklerContext.state.firmwares;
    const cols = generateUniqueColors(fw.length, 80);
    const x = fw.reduce((acc: Record<string, any>, f, i) => {
      acc[f] = cols[i];
      return acc;
    }, {});

    setColourLookup(x);
  }, [sprinklerContext.state.firmwares]);
  return (
    <>
      {data.map((row) => {
        const { deviceId, firmware, location_row, location_shelf } =
          row.properties;
        const color = colourLookup[firmware];

        return (
          <RackBox
            key={deviceId}
            zIndex={10}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
            style={{
              textAlign: 'center',
              backgroundColor: color,
              color: '#555',
            }}
          >
            {firmware}
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySpinklerFirmware;
