import { calculateTrayZone, type Location, type MCPConfig } from '@phyllome/common';

const CoordOrZone = ({ farmConfig, location, useZone }: {
  farmConfig: MCPConfig,
  location: Location,
  useZone: boolean
}) => useZone
  ? calculateTrayZone(location, farmConfig)
  : `${location.column}/${location.shelf}/${location.row}`;

export default CoordOrZone;