import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFarm from '../../data/hooks/collections/useFarm';
import FarmContext from './FarmContext';
import { useHotkeys } from 'react-hotkeys-hook';
import { appStateActions, useAppState } from '../../data/AppState/useAppState';
import { Box } from '@mui/material';
import type { ColumnSpec, MCPConfig } from '@phyllome/common';
import { MCPConfigColumnSpec } from '@phyllome/common';

function getBrokerFromPath(str: string) {
  const regex = /\/broker\/([^/]+)/;

  const match = str.match(regex);

  return match && match[1]; // Extract the captured data
}
export type FarmState = MCPConfig & {
  columnSpec: ColumnSpec[];
};

const FarmProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { dispatch, state } = useAppState();
  const [farmState, setFarmState] = useState<FarmState>();

  useHotkeys(
    'ctrl+shift+s',
    () => {
      dispatch({
        type: state.isSearchModalOpen
          ? appStateActions.CLOSESEARCHMODAL
          : appStateActions.OPENSEARCHMODAL,
      });
    },
    {
      preventDefault: true,
    },
  );

  const path = useLocation();
  const brokerId = getBrokerFromPath(path.pathname);

  const farmData = useFarm(brokerId || '');

  useEffect(() => {
    if (!farmData) return;
    const newFarmData = MCPConfigColumnSpec(farmData);

    setFarmState({
      ...farmData,
      columnSpec: newFarmData,
    });

  }, [farmData]);

  if (!farmState && brokerId) {
    // Then wait a little longer for the farm data to arrive.
    return (
      <Box>
        loading <strong>{brokerId}</strong>
      </Box>
    );

  }

  if (!farmState) return <>{children}</>;
  return (
    <FarmContext.Provider value={farmState}>{children}</FarmContext.Provider>
  );
};

export default FarmProvider;
