import type { FC } from 'react';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ChipEnum from '../../components/puregui/ChipEnum/ChipEnum';
import { Box } from '@mui/material';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import type { ChipEnumData } from '../../components/puregui/ChipEnum/types';
import type { AirDeviceState } from '@phyllome/common';

const airAreas: ChipEnumData = [
  {
    label: 'Default',
    value: 0,
    color: 'sunlight',
  },
  {
    label: 'Growroom',
    value: 1,
    color: 'primary',
  },
  {
    label: 'External',
    value: 2,
    color: 'earth1',
  },
];

type ILatestAirStateProps = {
  latest: FromFirebase<AirDeviceState>;
};

const LatestAirInfo: FC<ILatestAirStateProps> = ({ latest }) => {
  const heroItems = [
    {
      label: 'Temperature',
      text: (
        Math.round(Number((latest?.temperature || 0) * 10)) / 10
      ).toString(),
      sup: '\u00b0C',
    },
    {
      label: 'Humidity',
      text: (Math.round(Number(latest?.humidity * 10)) / 10).toString(),
      sup: '%',
    },
    {
      label: 'CO2',
      text: (latest.co2 !== undefined
        ? Math.round(Number(latest?.co2 * 10)) / 10
        : '--- '
      ).toString(),
      sup: 'ppm',
    },
    {
      label: 'VPD',
      text: (latest.vpd !== undefined
        ? Math.round(Number(latest?.vpd * 10)) / 10
        : '--- '
      ).toString(),
      sup: 'kPa',
    },
    {
      label: 'Dew Point',
      text: (latest.dewPoint !== undefined
        ? Math.round(Number(latest?.dewPoint * 10)) / 10
        : '--- '
      ).toString(),
      sup: '\u00b0C',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Air Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box mb={2}>
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb={2}>
        <ChipEnum
          data={airAreas}
          value={latest.area}
          tooltip="Location of air monitor"
        />
        <ChipBool
          data={latest.status_error}
          label="Co2 Sensor Error"
          color="earth3"
          icon={<ErrorIcon />}
        />
        <ChipBool
          data={latest.status_warmup}
          label="Co2 Sensor Warming up"
          color="sunlight"
          icon={<WarningAmberIcon />}
        />
      </Box>
    </PaperSection>
  );
};

export default LatestAirInfo;
