import type { FC } from 'react';
import { useState } from 'react';
import { howManyDaysAgo } from '../../../helpers/FStimeAgo';
import type {
  DatabaseDevices,
  DeviceType,
  FertigatorDeviceState,
  GuardDeviceState,
  HarvesterDeviceState,
  IrrigationDeviceState,
  LiftDeviceState,
  LightingDeviceState,
  SentryDeviceState,
  TraybotDeviceState,
  ZoneDeviceState,
  PackagerDeviceState,
  SeederDeviceState,
  TransferDeviceState,
} from '@phyllome/common';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import DeviceTemplate from './DeviceTemplate';
import { DevicesParent } from './GridSystem';
import { Box, useTheme } from '@mui/material';
import HorizontalData from './HorizontalData';
import ChipBool from '../../../components/puregui/ChipBool/ChipBool';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import getDeviceErrorState from '../../../helpers/getDeviceErrorState';
import type { FromFirebase } from '../../../types/types';

interface IRowProps {
  deviceData: FromFirebase<DatabaseDevices>[];
  onClick: (deviceData: FromFirebase<DatabaseDevices>) => void;
  devicesSelected: DeviceType[];
}

const DeviceListPrototype: FC<IRowProps> = ({
  deviceData,
  onClick,
  devicesSelected,
}) => {
  const [selectedRow, setSelectedRow] = useState({});
  const theme = useTheme();

  return (
    <Box mt={2.5}>
      <DevicesParent>
        {deviceData.map((_deviceData, key) => {
          const _selectedKey = `${_deviceData.deviceType}.${_deviceData.deviceId}`;

          if (!devicesSelected.includes(_deviceData.deviceType)) return null;
          if (howManyDaysAgo(_deviceData.lastAccessed) >= 12) return null;
          const label =
            _deviceData.properties.label === undefined
              ? `${_deviceData.deviceType}/${_deviceData.deviceId}`
              : `${_deviceData.deviceType}/${_deviceData.deviceId} (${_deviceData.properties.label})`;

          const commonProps = {
            isSelected: selectedRow === _selectedKey,
            onClick: () => {
              setSelectedRow(_selectedKey);
              onClick(_deviceData);
            },

            lastAccessed: _deviceData?.lastAccessed
              ? _deviceData.lastAccessed.toDate()
              : new Date(),
            deviceType: _deviceData.deviceType,
            deviceId: _deviceData.deviceId,
            aliases: _deviceData.alias,
          };

          switch (_deviceData.deviceType) {
            case 'air':
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  labelRow={`${label}`}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {_deviceData.properties.temperature.toFixed(1)}°C /{' '}
                    {_deviceData.properties.co2?.toFixed(0)}
                  </Box>
                </DeviceTemplate>
              );
            case 'camera':
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  labelRow={`${label}`}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {_deviceData.properties.images_pending} images
                  </Box>
                </DeviceTemplate>
              );
            case 'co2':
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                  labelRow={`${label}`}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {_deviceData.properties.ppm}ppm
                  </Box>
                </DeviceTemplate>
              );
            case 'fertigator': {
              const data =
                _deviceData.properties as unknown as FertigatorDeviceState;

              const heroItems = [
                {
                  label: 'EC',
                  text: (
                    Math.round(Number(data.sample_ec * 10)) / 10
                  ).toString(),
                },
                {
                  label: 'Ph',
                  text: (
                    Math.round(Number(data.sample_ph * 10)) / 10
                  ).toString(),
                },
                {
                  label: 'Temp',
                  text:
                    Math.round(Number(data.sample_temperature * 10)) / 10 +
                    '\u00b0',
                },
                {
                  label: 'Oxygen',
                  text: (
                    Math.round(Number(data.sample_do * 10)) / 10
                  ).toString(),
                },
                {
                  label: 'ORP',
                  text: (
                    Math.round(Number(data.sample_orp))
                  ).toString(),
                },
              ];

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <HorizontalData data={heroItems} />
                </DeviceTemplate>
              );
            }

            case 'guard': {
              const data =
                _deviceData.properties as unknown as GuardDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool size="tiny" data={data.move} label="Move" />
                    <ChipBool
                      size="tiny"
                      data={data.automate}
                      label="Automate"
                    />
                    <ChipBool
                      size="tiny"
                      data={data.alarm}
                      label="Alarm"
                      icon={<NotificationsActiveIcon />}
                    />
                    <ChipBool
                      size="tiny"
                      data={data.flash}
                      label="Flash"
                      icon={<WbTwilightIcon />}
                    />
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'harvester': {
              const data =
                _deviceData.properties as unknown as HarvesterDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.platform_height}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'packager': {
              const data =
                _deviceData.properties as unknown as PackagerDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.count} bags
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'irrigation': {
              const data =
                _deviceData.properties as unknown as IrrigationDeviceState;
              const heroItems = [
                {
                  label: 'Supply Lvl',
                  text: `${String(data.levels_supply)}%`,
                },
                {
                  label: 'Return Lvl',
                  text: `${String(data.levels_return)}%`,
                },
                {
                  label: 'Ret.Pressure',
                  text: `${String(data.pressures_return)}kpa`,
                },
              ];

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <HorizontalData data={heroItems} />
                </DeviceTemplate>
              );
            }
            case 'lighting': {
              const data =
                _deviceData.properties as unknown as LightingDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {`${(data.current_A + data.current_B + data.current_C).toFixed(1)}A`}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'lift': {
              const data = _deviceData.properties as unknown as LiftDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    shelf: {data.shelf}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'traybot': {
              const data =
                _deviceData.properties as unknown as TraybotDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.location_column}/{data.location_shelf}/
                    {data.location_row}
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'zone': {
              const data = _deviceData.properties as unknown as ZoneDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool size="tiny" data={data.enable} label="Enabled" />
                    <ChipBool size="tiny" data={data.active} label="Active" />
                  </Box>
                </DeviceTemplate>
              );
            }
            case 'sentry': {
              const data =
                _deviceData.properties as unknown as SentryDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.tray}
                  </Box>
                </DeviceTemplate>
              );
            }

            case 'seeder': {
              const data =
                _deviceData.properties as unknown as SeederDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    {data.count}/{data.trays}
                  </Box>
                </DeviceTemplate>
              );
            }

            case 'transfer': {
              const data =
                _deviceData.properties as unknown as TransferDeviceState;

              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                  isError={getDeviceErrorState(_deviceData)}
                >
                  <Box
                    style={{
                      fontSize: '28px',
                      color: theme.palette.clouds.main,
                    }}
                  >
                    <ChipBool
                      size="tiny"
                      data={data.conveyors_infeed}
                      label="Infeed"
                    />
                    <ChipBool
                      size="tiny"
                      data={data.conveyors_outfeed}
                      label="Outfeed"
                    />
                  </Box>
                </DeviceTemplate>
              );
            }

            default:
              return (
                <DeviceTemplate
                  key={key}
                  {...commonProps}
                  labelRow={`${label}`}
                />
              );
          }
        })}
      </DevicesParent>
    </Box>
  );
};

export default DeviceListPrototype;
