import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  Alert,
  IconButton,
} from '@mui/material';
import showNumber from '../../helpers/showNumber';
import { formatThousands } from '../../helpers/showNumber';
import type { NDSSchedule } from '@phyllome/common';
import DeleteIcon from '@mui/icons-material/Delete';

type NDSScheduleProps = {
  scheduleDataDB: NDSSchedule[];
};

interface NDSScheduleUI {
  [key: number]: NDSSchedule;
}

type Nullable<T> = { [P in keyof T]: T[P] | null };

const convertFromDBTOUI = (schedule: NDSSchedule[]): NDSScheduleUI => {
  const result: NDSScheduleUI = {};

  schedule.forEach(({ day, dose, period }) => {
    result[day] = { day, dose, period };
  });
  return result;
};

const ScheduleRow = ({
  data,
  onDelete,
}: {
  data: Nullable<NDSSchedule>;
  onDelete?: (args: number | undefined) => void;
}) => {
  return (
    <TableRow
      hover
      key={data.day}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {data.day}
      </TableCell>
      <TableCell>{showNumber(data.dose, formatThousands)}</TableCell>
      <TableCell>{showNumber(data.period, formatThousands)}</TableCell>
      <TableCell>
        {onDelete && (
          <IconButton
            aria-label="delete"
            onClick={() => {
              onDelete(data.day as number);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const ScheduleTableNDS = ({ scheduleDataDB }: NDSScheduleProps) => {
  if (Object.keys(scheduleDataDB).length === 0)
    return <Alert severity="warning">No data available.</Alert>;

  const schedule = convertFromDBTOUI(scheduleDataDB);

  return (
    <TableContainer>
      <Table aria-label="Sprinkler Schedule" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell>Dose&nbsp;(mL)</TableCell>
            <TableCell>Period&nbsp;(s)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(schedule).map((day) => {
            const data = schedule[Number(day)];

            return <ScheduleRow key={day} data={data} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleTableNDS;
