import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useControlSend from '../../../data/hooks/useControlSend';
import type { FertigatorDeviceState } from '@phyllome/common';
import {
  mqttDefinitionFertigatorSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormFertigatorSetBase = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionFertigatorSet, {
    base: {
      setpoint: latest?.base_setpoint || 0,
      enable: latest?.base_enable,
    },
  });

  return (
    <PaperSection
      heading="Base dosing and setpoint Control"
      mb={2}
      subheading={mqttDefinitionFertigatorSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              base: { setpoint: data.base.setpoint, enable: data.base.enable },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set Base dosing to ${data.base.enable ? 'enabled' : 'disabled'} and setpoint to ${data.base.setpoint}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          base: yup
            .object({
              setpoint: yup.number().min(0).max(14).required(),
              enable: yup.boolean().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement label="Base Dosing Enabled" name="base.enable" />
          <TextFieldElement label="Base Setpoint" name="base.setpoint" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorSetBase;
