import type { FC } from 'react';
import { Stack } from '@mui/material';
import TankLevel from './TankLevel';
import PhormContainer from '../../forms/PhormContainer';
import SubmitControl from '../../forms/SubmitControl';
import useControlSend from '../../data/hooks/useControlSend';
import type { PubSubDefinitionAny } from '@phyllome/common';
import * as yup from 'yup';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

type ITankLevelWithClearControlProps = {
  label: string;
  level: number;
  subLabel?: string;
  payload?: any;
  deviceId: string;
  deviceType?: string;
  mqttDefinitionDeviceClear: PubSubDefinitionAny;
  addActivityLog?: (text: string) => void;
};

const TankLevelWithControl: FC<ITankLevelWithClearControlProps> = ({
  label,
  level,
  subLabel,
  payload,
  deviceId,
  mqttDefinitionDeviceClear,
  addActivityLog,
}) => {
  const controlLifecycle = useControlSend(mqttDefinitionDeviceClear, deviceId);

  return (
    <Stack spacing={2} textAlign="center">
      <TankLevel level={level} label={label} />
      <h4>{subLabel}</h4>
      <PhormContainer
        defaultValues={{}}
        onSubmit={() => {
          controlLifecycle.run({
            payload: payload,
            topicParams: {
              deviceId: deviceId,
            },
          });
          if (addActivityLog) addActivityLog(`Reset the ${label} tank level`);
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <SubmitControl
          controlHook={controlLifecycle}
          variant="outlined"
          size="small"
          color="secondary"
          inline={false}
          showStatus="none"
          icon={<RestartAltIcon />}
        >
          Reset
        </SubmitControl>
      </PhormContainer>
    </Stack>
  );
};

export default TankLevelWithControl;
