import { useFirestore, useFirestoreDocData } from 'reactfire';
import { doc } from 'firebase/firestore';
import type { ICollectionCustomConsole } from './useCustomConsole';

const useCustomConsoleByID = ({
  id,
}: {
  id: string;
}): ICollectionCustomConsole => {
  // easily access the Firestore library
  const ref = doc(useFirestore(), 'customConsole', id);

  // subscribe to a document for realtime updates. just one line
  const { data } = useFirestoreDocData(ref);

  const retData = data as ICollectionCustomConsole;

  return retData;
};

export default useCustomConsoleByID;
