import React from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';
import type { UseActionReturn } from '../../../data/hooks/useAction';

const PhormActionErrors = ({
  actionObject,
}: {
  actionObject: UseActionReturn<any>;
}) => {
  if (actionObject.status !== 'ERROR') return null;
  return (
    <Box mb={2}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <div>{actionObject.error}</div>
      </Alert>
    </Box>
  );
};

export default PhormActionErrors;
