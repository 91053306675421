import { colors } from '@phyllome/common';
import type { FC } from 'react';
import { JSONTree } from 'react-json-tree';

const theme = {
  base00: '#ffffff',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: colors.ocean,
  base0C: '#a1efe4',
  base0D: 'black',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

type IProps = {
  src: any;
  label?: string;
  expandAll?: boolean;
};

const ReactJson: FC<IProps> = ({ src, expandAll = false }) => {
  return (
    <div style={{ marginTop: '-10px', marginLeft: '-20px' }}>
      <JSONTree
        sortObjectKeys={true}
        hideRoot={true}
        data={src}
        theme={theme}
        invertTheme={false}
        shouldExpandNodeInitially={() => expandAll}
      />
    </div>
  );
};

export default ReactJson;
