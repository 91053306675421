import type { Co2DeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import FormCo2SetEnableTime from './forms/FormCo2SetEnableTime';
import FormCo2SetSetpoint from './forms/FormCo2SetSetpoint';
import FormCo2ConfigSetAutomation from './forms/FormCo2ConfigSetAutomation';
import LatestCo2Info from './LatestCo2Info';
import GenericPing from '../Generic/control/GenericPing';

const ControlCo2 = ({ latest }: { latest: FromFirebase<Co2DeviceState> }) => {
  return (
    <>
      <LatestCo2Info latest={latest} />
      <FormCo2SetSetpoint latest={latest} />
      <FormCo2SetEnableTime latest={latest} />
      <FormCo2ConfigSetAutomation latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="co2"
        />
      </div>
    </>
  );
};

export default ControlCo2;
