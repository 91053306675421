import { useAppState } from './AppState/useAppState';

enum DEVICETYPES {
  DESKTOP = 'desktop',
  TABLETPORTRAIT = 'tabletPortrait',
  TABLETLANDSCAPE = 'tabletLandscape',
  MOBILE = 'mobile',
}

interface IUseDeviceType {
  width: number;
  isDesktop: boolean;
  isMobile: boolean;
  type: DEVICETYPES;
}

const useDeviceType = (): IUseDeviceType => {
  const { state: appState } = useAppState();

  const width = appState.appWidth;

  let type = DEVICETYPES.MOBILE;

  if (width > 1024) type = DEVICETYPES.DESKTOP;
  if (width > 800) type = DEVICETYPES.TABLETPORTRAIT;
  if (width > 600) type = DEVICETYPES.TABLETLANDSCAPE;
  return {
    width,
    isDesktop: width > 1024,
    isMobile: width < 800,
    type,
  };
};

export default useDeviceType;
