import type { FC } from 'react';
import type { SentryDeviceState } from '@phyllome/common';
import {
  mqttDefinitionSentryConfigGet,
} from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import { formatBytes } from '../../helpers/formatBytes';
import FormSentryConfigSetLabel from './forms/FormSentryConfigSetLabel';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

type IProps = {
  latest: FromFirebase<SentryDeviceState>;
};

const ConfigSentry: FC<IProps> = ({ latest }) => {
  return (
    <>
      <PaperSection heading="Sentry Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Label">{latest.label}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">
          {latest.wifi_ip} ({latest.wifi_rssi}dB)
        </LabelValue>
        <LabelValue label="Free memory">
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormSentryConfigSetLabel latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionSentryConfigGet}
      />
    </>
  );
};

export default ConfigSentry;
