import type {
  CloudSeasonDelete,
  CloudSeasonUpdate,
  CloudSeedDelete,
  CloudSeedInsert,
  CloudSeedUpdate,
  CloudTraySeasonDelete,
  CloudTraySeasonHarvest,
  CloudTraySeasonInsertMultipleTrays,
  CloudTraySeasonUpdate,
  CloudTraySeasonUpdateAllTrays,
  CloudTraySeasonUpdateSelectedTrays,
} from '@phyllome/common';
import { httpsCallableFromURL } from 'firebase/functions';
import { useState } from 'react';
import { useFunctions } from 'reactfire';

type Status = 'IDLE' | 'RUNNING' | 'DONE' | 'ERROR';
export const useEndpointSeasonUpdate = () => {
  return makeEndPointHook<CloudSeasonUpdate>(
    'https://seasonupdate-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointSeasonDelete = () => {
  return makeEndPointHook<CloudSeasonDelete>(
    'https://seasondelete-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointTraySeasonUpdate = () => {
  return makeEndPointHook<CloudTraySeasonUpdate>(
    'https://trayseasonupdate-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointTraySeasonUpdateAllTrays = () => {
  return makeEndPointHook<CloudTraySeasonUpdateAllTrays>(
    'https://trayseasonupdatealltrays-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointTraySeasonHarvest = () => {
  return makeEndPointHook<CloudTraySeasonHarvest>(
    'https://trayseasonharvest-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndPointTraySeasonUpdateSelectedTrays = () => {
  return makeEndPointHook<CloudTraySeasonUpdateSelectedTrays>(
    'https://trayseasonupdateselectedtrays-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointTraySeasonDelete = () => {
  return makeEndPointHook<CloudTraySeasonDelete>(
    'https://trayseasondelete-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointTraySeasonInsertMultipleTrays = () => {
  return makeEndPointHook<CloudTraySeasonInsertMultipleTrays>(
    'https://trayseasoninsertmultipletrays-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointSeedInsert = () => {
  return makeEndPointHook<CloudSeedInsert>(
    'https://seedinsert-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointSeedUpdate = () => {
  return makeEndPointHook<CloudSeedUpdate>(
    'https://seedupdate-hbtwsngtia-ts.a.run.app',
  )();
};

export const useEndpointSeedDelete = () => {
  return makeEndPointHook<CloudSeedDelete>(
    'https://seeddelete-hbtwsngtia-ts.a.run.app',
  );
};

const makeEndPointHook = <ReqType, ResType = any>(endpoint: string) => {
  const funcName = endpoint.match(/https?:\/\/([^-]+)/)?.[1] ?? '';

  const newEndPoint =
    process.env.NODE_ENV === 'production'
      ? endpoint
      : `http://localhost:5001/phyllome-core/us-central1/${funcName}`;

  return () => {
    const [status, setStatus] = useState<Status>('IDLE');
    const [payload, setPayload] = useState<ResType | null>(null);
    const functions = useFunctions();

    const func = httpsCallableFromURL<ReqType, any>(functions, newEndPoint);

    return {
      run: async (data: ReqType) => {
        setStatus('RUNNING');
        return func(data)
          .then((payload) => {
            setPayload(payload.data);
            setStatus('DONE');
            return payload;
          })
          .catch((e) => {
            setStatus('ERROR');
            throw e;
          });
      },
      status,
      payload,
    };
  };
};
