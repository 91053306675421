import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { TransferDeviceState } from '@phyllome/common';
import {  mqttDefinitionTransferManual } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormTransferManualConveyors = ({
  state,
}: {
  state: FromFirebase<TransferDeviceState>;
}) => {
  const { deviceId } = state;

  const controlLifecycle = useControlSend(
    mqttDefinitionTransferManual,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/transfer/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTransferManual,
    {
      conveyors: {
        hatch: state?.conveyors_hatch || false,
        infeed: state?.conveyors_infeed || false,
        outfeed: state?.conveyors_outfeed || false,
      },
    },
  );

  return (
    <PaperSection
      mb={2}
      heading="Conveyor Manual Control"
      subheading={mqttDefinitionTransferManual.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              conveyors: {
                hatch: data?.conveyors?.hatch,
                infeed: data?.conveyors?.infeed,
                outfeed: data?.conveyors?.outfeed,
              },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Manually set conveyors hatch ${data.conveyors.hatch} infeed: ${data.conveyors.infeed} and outfeed: ${data.conveyors.outfeed}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          conveyors: yup.object({
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="body1">
            Start / Stop the conveyors
          </Typography>
          <SwitchElement
            label="Hatch Conveyor"
            name="conveyors.hatch"
          />
          <SwitchElement
            label="Infeed Conveyor"
            name="conveyors.infeed"
          />
          <SwitchElement
            label="Outfeed Conveyor"
            name="conveyors.outfeed"
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTransferManualConveyors;
