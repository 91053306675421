function calculatePercentageDifference(value1: number, value2: number): number {
  if (value1 < value2) return 0;
  if ( isNaN(value1) || isNaN(value2)) return 0;

  const difference = Math.abs(value1 - value2);
  const percentageDifference = (difference / value1) * 100;

  return percentageDifference;
}
export default calculatePercentageDifference;
