import { useCallback, useContext } from 'react';
import useAction from './useAction';
import { useUser } from 'reactfire';
import type { DatabaseActivityLog } from '@phyllome/common';
import {
  query,
  collection,
  where,
  limit,
  orderBy,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import type { FromFirebase } from '../../types/types';
import FarmContext from '../../components/FarmProvider/FarmContext';
import { useEffect, useState } from 'react';

const useActivityLogData = (
  identifier: string,
  brokerId: string,
  range: number = 100,
) => {
  const firestore = getFirestore();
  const [data, setData] = useState<FromFirebase<DatabaseActivityLog>[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const documentReference = query(
      collection(firestore, 'activityLog'),
      where('parent', '==', identifier),
      where('brokerId', '==', brokerId),
      orderBy('timestamp', 'desc'),
      limit(range),
    );

    // Setting up the onSnapshot listener
    const unsubscribe = onSnapshot(
      documentReference,
      (querySnapshot) => {
        const logs: FromFirebase<DatabaseActivityLog>[] =
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as FromFirebase<DatabaseActivityLog>[];

        setData(logs);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      },
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [firestore, identifier, brokerId, range]);
  return { data, loading, error };
};

const useActivityLog = (identifier: string, range: number = 100) => {
  const { brokerId } = useContext(FarmContext);
  const user = useUser();
  const action = useAction('activityLogCreate');
  const data = useActivityLogData(identifier, brokerId, range);

  const add = useCallback(
    (
      description: string,
      originType: DatabaseActivityLog['origin']['type'] = 'USER',
    ) => {
      action.run({
        parent: identifier,
        brokerId,
        description,
        origin: {
          type: originType,
          ident: user.data?.email || 'unknown',
        },
      });
    },
    [action, user.data?.email, identifier, brokerId],
  );

  return { add, addStatus: action.status, data: data?.data };
};

export default useActivityLog;
