import { Button } from '@mui/material';
import type { DeviceType } from '@phyllome/common';
import DeviceTypeToIcon from '../DeviceTypeToIcon/DeviceTypeToIcon';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type IDeviceButtonProps = {
  brokerId: string;
  deviceType: DeviceType;
  deviceId: string;
};

const DeviceButton: FC<IDeviceButtonProps> = ({
  brokerId,
  deviceType,
  deviceId,
}) => {
  const nav = useNavigate();

  if (deviceId != 'undefined') {
    return (
      <Button
        variant="contained"
        onClick={() => {
          nav(`broker/${brokerId}/device/#/${deviceType}/${deviceId}`);
        }}
        startIcon={<DeviceTypeToIcon deviceType={deviceType} />}
      >
        {deviceType}/{deviceId}
      </Button>
    );
  } else return null;
};

export default DeviceButton;
