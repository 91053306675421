import type { TraybotDeviceState } from '@phyllome/common';
import type { FC } from 'react';
import { useContext } from 'react';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import type { FromFirebase } from '../../types/types';
import Graph from '../../components/Favourites/Graph';
import FarmContext from '../../components/FarmProvider/FarmContext';

type IProps = {
  latest: FromFirebase<TraybotDeviceState>;
};

const GraphTraybot: FC<IProps> = ({ latest }) => {
  const { deviceId } = latest;
  const { brokerId } = useContext(FarmContext);

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph name="traybotState" brokerId={brokerId} deviceId={deviceId} />
        <Graph name="traybotBattery" brokerId={brokerId} deviceId={deviceId} />
        <Graph name="traybotNetwork" brokerId={brokerId} deviceId={deviceId} />
        <Graph name="traybotBlower" brokerId={brokerId} deviceId={deviceId} />
        <Graph name="traybotCharger" brokerId={brokerId} deviceId={deviceId} />
        <Graph name="traybotEdge" brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name="traybotLiftRaised"
          brokerId={brokerId}
          deviceId={deviceId}
        />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphTraybot;
