import type { FertigatorDeviceState } from '@phyllome/common';
import {
  mqttDefinitionFertigatorConfigGet,
} from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import FormFertigatorConfigSetMinimums from './forms/FormFertigatorConfigSetMinimums';
import FormFertigatorConfigSetFlowrates from './forms/FormFertigatorConfigSetFlowrates';
import FormFertigatorConfigSetDosing from './forms/FormFertigatorConfigSetDosing';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatBytes } from '../../helpers/formatBytes';
import { formatSeconds } from '../../helpers/formatSeconds';
import { Alert } from '@mui/material';

const ConfigFertigator = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  if (!latest) return <Alert severity="info">No latest data</Alert>;
  return (
    <>
      <PaperSection heading="Fertigator Controller Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.build}</LabelValue>
        <LabelValue label="Network IP">{latest.network_ip}</LabelValue>
        <LabelValue label="Free memory">
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormFertigatorConfigSetFlowrates latest={latest} />
      <FormFertigatorConfigSetMinimums latest={latest} />
      <FormFertigatorConfigSetDosing latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionFertigatorConfigGet}
      />
    </>
  );
};

export default ConfigFertigator;
