import { useContext, useEffect } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import OverlayTrayCurrentDay from '../Overlays/OverlayTrayCurrentDay';
import useNDSLatestByColumnPoller from '../../../../devicespecific/NDS/hooks/useNDSLatestByColumnPoller';
import { TraysContext } from '../TraysContext';
import useNavSide from '../../../../data/hooks/useNavSide';

const OverlayEntryPointCurrentDay = ({ column }: { column: number }) => {
  const { columnSpec, brokerId } = useContext(FarmContext);
  const foo = useNDSLatestByColumnPoller(brokerId, column);
  const trayData = useTraySeasonsCurrentByColID({ brokerId, columnId: column });
  const shelves = columnSpec[column - 1].shelves;
  const navSide = useNavSide();
  const traysContext = useContext(TraysContext);

  const hash = location.hash.substring(1);

  useEffect(() => {
    if (hash.length === 0 && traysContext.state.selectedSeason) {
      traysContext.dispatch({ type: 'SET_SELECTED_TRAY', payload: undefined });
      traysContext.dispatch({
        type: 'SET_SELECTED_SEASON',
        payload: undefined,
      });
    }
  }, [hash, traysContext]);
  return (
    <>
      <OverlayTrayCurrentDay
        data={trayData}
        shelves={shelves}
        ndsData={foo}
        onClick={(trayRowData) => {
          traysContext.dispatch({
            type: 'SET_SELECTED_TRAY',
            payload: trayRowData.id,
          });
          traysContext.dispatch({
            type: 'SET_SELECTED_SEASON',
            payload: trayRowData.seasonId,
          });

          navSide(`tray/${trayRowData.id}`);
        }}
      />
    </>
  );
};

export default OverlayEntryPointCurrentDay;
