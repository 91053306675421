import type { SentryDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestSentry = (brokerId: string, deviceId: string) => {
  return useDeviceState<SentryDeviceState>({
    brokerId,
    deviceId,
    deviceType: 'sentry',
  });
};

export default useLatestSentry;
