import useControlSend from '../../../data/hooks/useControlSend';
import type {
  DatabaseSeason,
  ZoneDeviceState,
} from '@phyllome/common';
import {
  mqttDefinitionZoneSet,
} from '@phyllome/common';
import type { FromFirebase } from '../../../types/types';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { useSnackbar } from 'notistack';
import ScheduleViewLighting from '../../../components/ScheduleView/ScheduleViewLighting';
import useSeed from '../../../data/hooks/collections/useSeed';
import useFindSeasonWithZone from '../hooks/useFindSeasonWithZone';
import { LightingScheduleEdit } from '../../../components/ScheduleEdit/ScheduleEdit';

const FormZoneSet = ({
  latest,
}: {
  latest: FromFirebase<ZoneDeviceState>;
  seasonData: FromFirebase<DatabaseSeason> | undefined;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(mqttDefinitionZoneSet, deviceId);
  const season = useFindSeasonWithZone({
    zoneId: parseInt(deviceId),
    brokerId: latest.brokerId,
  });
  const seed = useSeed(season?.seedId);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <PaperSection
      heading="Zone Schedule"
      mb={2}
      subheading={mqttDefinitionZoneSet.topic}
    >
      <ScheduleViewLighting
        seedSchedule={seed?.defaultLightingSchedule}
        seasonSchedule={season?.defaultLightingSchedule}
        zoneSchedule={latest.schedule}
        context="zone"
      />
      <LightingScheduleEdit
        isSaving={false}
        // isSaving={zoneLightingScheduleUpdate.status === "RUNNING"}
        data={latest.schedule}
        onSubmit={(payload) => {
          controlLifecycle
            .run({
              payload: {
                schedule: payload,
              },
              topicParams: {
                deviceId,
              },
            })
            .then(() => {
              enqueueSnackbar(`Zone ${deviceId} lighting schedule updated`, {
                variant: 'success',
              });
            });
        }}
      />
    </PaperSection>
  );
};

export default FormZoneSet;
