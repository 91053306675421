import type {
  BoxProps,
  ButtonProps,
} from '@mui/material';
import {
  Box,
  CircularProgress,
  Alert,
  Stack,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WarningIcon from '@mui/icons-material/Warning';
import { TaskAlt } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { Button } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import type { ControlSend } from '../data/hooks/useControlSend';
import { formHasErrors } from '../components/Form/helpers';

type showStatus = 'none' | 'small' | 'large';

type IProps = {
  onClick?: () => void;
  controlHook: ControlSend;
  inline?: boolean;
  height?: string;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  showStatus?: showStatus;
  icon?: JSX.Element;
} & BoxProps;

const SubmitControl: FC<PropsWithChildren<IProps>> = ({
  children,
  controlHook,
  onClick = () => null,
  inline = false,
  height = 'auto',
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  showStatus = 'large',
  icon = <PlayArrowIcon />,
  ...boxProps
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const hasErrors = formHasErrors(errors);

  const isBusy =
    controlHook.status === 'error' ||
    controlHook.status === 'mcp' ||
    controlHook.status === 'started';

  const isDone = controlHook.status === 'done';
  const buttonColor = isDone ? color : isBusy ? 'info' : color;

  return (
    <Box sx={{ ...boxProps.sx, display: inline ? 'inline' : 'block' }}>
      <Stack direction="row" spacing={2}>
        <Button
          sx={{ height }}
          onClick={onClick}
          color={buttonColor}
          variant={variant}
          type="submit"
          size={size}
          disabled={isBusy || hasErrors}
          endIcon={<ControlHookIcon icon={icon} controlHook={controlHook} />}
        >
          {children}
        </Button>
        <ControlHookStatus controlHook={controlHook} status={showStatus} />
      </Stack>
    </Box>
  );
};

export default SubmitControl;

type IControlHookIconProps = {
  icon?: JSX.Element;
  controlHook: ControlSend;
};

const ControlHookIcon: FC<IControlHookIconProps> = ({ icon, controlHook }) => {
  switch (controlHook.status) {
    case 'started':
    case 'mcp':
      return <CircularProgress size={15} />;
      break;
    case 'done':
      return <TaskAlt fontSize="small" />;
      break;
    case 'timeout':
      return <WarningIcon fontSize="small" />;
      break;
    case 'error':
      return <ErrorIcon fontSize="small" />;
      break;
    default:
      if (icon) return <>{icon}</>;
      break;
  }
  return null;
};

type IControlHookStatusProps = {
  controlHook: ControlSend;
  status: showStatus;
};

const ControlHookStatus: FC<IControlHookStatusProps> = ({
  controlHook,
  status,
}) => {
  if (status === 'large') {
    switch (controlHook.status) {
      case 'started':
        if (controlHook.retryCount > 1) {
          return (
            <Alert
              severity="warning"
              variant="outlined"
              sx={{ paddingTop: '0', paddingBottom: '0' }}
            >
              Retrying Operation {controlHook.retryCount}
            </Alert>
          );
        } else {
          return (
            <Alert
              severity="info"
              variant="outlined"
              sx={{ paddingTop: '0', paddingBottom: '0' }}
            >
              Connecting to MCP
            </Alert>
          );
        }
        break;
      case 'mcp':
        return (
          <Alert
            severity="info"
            variant="outlined"
            sx={{ paddingTop: '0', paddingBottom: '0' }}
          >
            Waiting for device
          </Alert>
        );
        break;
      case 'done':
        return (
          <Alert
            severity="success"
            variant="standard"
            sx={{ paddingTop: '0', paddingBottom: '0' }}
          >
            Success ({controlHook.runtime}ms)
          </Alert>
        );
        break;
      case 'timeout':
        return (
          <Alert
            severity="error"
            variant="standard"
            sx={{ paddingTop: '0', paddingBottom: '0' }}
          >
            Operation Timed Out
          </Alert>
        );
    }
  }

  if (status === 'small') {
    switch (controlHook.status) {
      case 'done':
        return (
          <Box sx={{ paddingTop: '5px' }}>
            <TaskAlt color="budding" fontSize="small" />
          </Box>
        );
        break;
      case 'timeout':
        return (
          <Box sx={{ paddingTop: '5px' }}>
            <WarningIcon color="earth3" fontSize="small" />
          </Box>
        );
    }
  } else {
    return null;
  }
};
