import { useEffect, useState } from 'react';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import type { DatabaseTraySeason } from '@phyllome/common';
import type { FromFirebase } from '../../../types/types';

const useTraySeason = (id: string) => {
  const firestore = getFirestore();
  const [data, setData] = useState<FromFirebase<DatabaseTraySeason>>();

  useEffect(() => {
    const fetchTraySeason = async () => {
      try {
        const documentReference = doc(
          collection(firestore, 'traySeason'),
          id.toString(),
        );
        const docSnapshot = await getDoc(documentReference);

        if (docSnapshot.exists()) {
          const traySeasonData = {
            id: docSnapshot.id,
            ...docSnapshot.data(),
          } as FromFirebase<DatabaseTraySeason>;

          // If defaultNDSSchedule exists, parse strings to JSON objects
          if (traySeasonData.defaultNDSSchedule) {
            traySeasonData.defaultNDSSchedule =
              traySeasonData.defaultNDSSchedule.map((line) => {
                if (typeof line === 'string') {
                  return JSON.parse(line);
                } else {
                  return line;
                }
              });
          }

          setData(traySeasonData); // Wrap the data in an array
        } else {
          setData(undefined); // Document does not exist
        }
      } catch {
        setData(undefined); // Handle any errors by returning an empty array
      }
    };

    fetchTraySeason();
  }, [firestore, id]);
  return data;
};

export default useTraySeason;
