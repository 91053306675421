import { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore';
import type { AnyObject, DeviceType } from '@phyllome/common';
import type { DeviceStateFirestore } from '../../devicespecific/deviceTypes';

const useDeviceStateByAlias = <T extends AnyObject>({
  brokerId,
  deviceType,
  alias,
}: {
  brokerId: string;
  deviceType: DeviceType;
  alias: string;
}): DeviceStateFirestore<T> => {
  const [state, setState] = useState<DeviceStateFirestore<T>>();

  useEffect(() => {
    const db = getFirestore();
    const devicesRef = collection(db, 'devices');

    // Build query with multiple conditions
    const q = query(
      devicesRef,
      where('brokerId', '==', brokerId),
      where('deviceType', '==', deviceType),
      where('alias', 'array-contains', alias),
      orderBy('lastAccessed', 'desc'),
      limit(1),
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          const data = {
            id: doc.id,
            ...doc.data(),
          } as DeviceStateFirestore<T>;

          setState(data);
        } else {
          setState(undefined);
        }
      },
      (error) => {
        console.error('Error in device state subscription:', error);
        setState(undefined);
      },
    );

    return () => unsubscribe();
  }, [brokerId, deviceType, alias]);

  const ret = state?.properties as DeviceStateFirestore<T>;

  return ret;
};

export default useDeviceStateByAlias;