import type { FC } from 'react';
import useNavSide from '../../../data/hooks/useNavSide';

type ScaffoldLiftProps = {
  column: number;
  shelves: number;
};

const ScaffoldLift: FC<ScaffoldLiftProps> = ({ column, shelves }) => {
  const navSide = useNavSide();

  return (
    <div
      style={{
        position: 'absolute',
        top: '-1px',
        left: 0,
        height: `${shelves * 16 + 16}px`,
        background: '#e6e6e6',
        width: '55px',
        marginRight: '1px',
        color: '#999',
        transition: 'color 0.3s ease, background 0.3s ease', // Added background to transition
        cursor: 'pointer',
        zIndex: 1001,
      }}
      onMouseEnter={(e) => {
        const div = e.currentTarget as HTMLDivElement;

        div.style.color = '#000';
        div.style.background = '#D1EFD5';
      }}
      onMouseLeave={(e) => {
        const div = e.currentTarget as HTMLDivElement;

        div.style.color = '#999';
        div.style.background = '#e6e6e6';
      }}
      onClick={() => {
        navSide(`lift/${column}`);
      }}
    >
      <div
        style={{
          width: `${shelves * 16}px`,
          height: '55px',
          lineHeight: '55px',
          transform: 'rotate(-90deg) scale(1)',
          transformOrigin: 'top left',
          textAlign: 'center',
          top: `${(shelves * 16 + 8)}px`,
          position: 'absolute',
          fontSize: '18px',
          fontWeight: 'bold',

        }}
      >
        {column}
      </div>
    </div>
  );
};

export default ScaffoldLift;
