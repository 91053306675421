import type { DeviceType } from '@phyllome/common';
import type { FC } from 'react';
import PaperSection from '../puregui/PaperSection/PaperSection';
import ReChartVictoryLine from './RechartVictory';
import FavGraph, { FavGraphWrap } from '../FavGraph/FavGraph';
import type { FavouriteName } from '../Favourites/collectionFavourites';
import { Alert, AlertTitle, Box, Skeleton } from '@mui/material';
import useTimeSeriesPoller from './useTimeSeriesPoller';
import type { GraphConstraints, GraphLimits } from '../Favourites/types';

export type RechartVictoryHeadingProps = {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
  field: string;
  color: string;
  heading: string;
  name?: FavouriteName;
  height?: number;
  constraints?: GraphConstraints;
  limits?: GraphLimits;
  target?: number;
};

const ReChartVictoryHeading: FC<RechartVictoryHeadingProps> = ({
  brokerId,
  deviceId,
  deviceType,
  field,
  color,
  heading,
  name,
  height,
  constraints,
  limits,
  target,
}) => {
  const timeSeries = useTimeSeriesPoller({
    brokerId,
    deviceId,
    deviceType,
    origin: field,
    type: 'linear',
  });

  // useEffect(() => {
  //   console.log("0000");
  //   timeSeries.run();
  // }, []);

  if (timeSeries.isInitiating) {
    return (
      <PaperSection heading={heading}>
        <Skeleton
          variant="rectangular"
          height={height || 300}
          animation="wave"
        />
      </PaperSection>
    );
  }

  if (timeSeries.isUnsufficientData) {
    <PaperSection heading={heading}>
      <Box sx={{ height: height || 300 }}>
        <Alert severity="error">
          <AlertTitle>Unable to load chart</AlertTitle>Insufficient data
          available in timeSeries.
        </Alert>
      </Box>
    </PaperSection>;
  }
  return (
    <PaperSection heading={heading}>
      {name && (
        <FavGraphWrap isUpdating={timeSeries.isUpdating}>
          <FavGraph deviceId={deviceId} brokerId={brokerId} name={name} />
        </FavGraphWrap>
      )}
      <ReChartVictoryLine
        color={color}
        timeSeries={timeSeries}
        height={height}
        constraints={constraints}
        limits={limits}
        target={target}
      />
    </PaperSection>
  );
};

export default ReChartVictoryHeading;
