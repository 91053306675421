import type { FromFirebase } from '../../types/types';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import type { ZoneDeviceState } from '@phyllome/common';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphZone = ({ latest }: { latest: FromFirebase<ZoneDeviceState> }) => {
  const { deviceId, brokerId } = latest;

  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph name="zoneEnergy" brokerId={brokerId} deviceId={deviceId} />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphZone;
