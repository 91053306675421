import type { DatabaseTraySeason } from '@phyllome/common';
import RackBox from '../../../../components/factoryui/components/RackBox';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import type { FromFirebase } from '../../../../types/types';

type OverlayTrayZonesProps = {
  shelves: number;
  selectedZone?: number;
  onClick?: (traySeasonData: FromFirebase<DatabaseTraySeason>) => void;
  data: FromFirebase<DatabaseTraySeason>[];
};

const OverlayTrayZones = ({ shelves, data }: OverlayTrayZonesProps) => {
  return (
    <>
      {data.map((row, index) => {
        return (
          <RackBox
            key={index}
            zIndex={10}
            row={row.locationRow}
            shelf={row.locationShelf}
            shelves={shelves}
            style={{
              border: '1px solid transparent',
              background: 'transparent',
              textAlign: 'right',
            }}
          >
            <LightbulbIcon
              sx={{ textAlign: 'left', fontSize: '11px', color: 'yellow' }}
            />
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlayTrayZones;
