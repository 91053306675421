import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import Graph from '../../components/Favourites/Graph';
import type { NDSDeviceFromAction } from '../../types/types';

const GraphNDS = ({
  deviceState,
}: {
  deviceState: NDSDeviceFromAction;
}) => {

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph name="ndsActivations" brokerId={deviceState.brokerId} deviceId={deviceState.deviceId} />

      </Stacker>
    </ChartProvider>
  );
};

export default GraphNDS;
