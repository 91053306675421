import type { GuardDeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GraphGuard = ({ latest }: { latest: FromFirebase<GuardDeviceState> }) => {
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphGuard;
