import { Stack } from '@mui/system';
import {  SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import type { FertigatorDeviceState } from '@phyllome/common';
import {
  mqttDefinitionFertigatorCalibrate,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import ChipBool from '../../../components/puregui/ChipBool/ChipBool';

const FormFertigatorCalibrateDissolvedOxygen = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorCalibrate,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = { select: '0' };

  return (
    <PaperSection
      heading="Calibrate Dissolved Oxygen Probe"
      mb={2}
      subheading={mqttDefinitionFertigatorCalibrate.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              do: data?.select,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Calibrated dissolved oxygen probe at ${data.select}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          ec: yup
            .object({
              do: yup.number().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <ChipBool data={latest.calibration_do_zero} label="Zero" />
            <ChipBool data={latest.calibration_do_air} label="Air" />
          </Stack>
          <SelectElement
            fullWidth
            label="Select"
            name="select"
            options={[
              {
                id: '0',
                label: 'Zero',
              },
              {
                id: '1',
                label: 'Air',
              },
            ]}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorCalibrateDissolvedOxygen;
