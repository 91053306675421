import { colors } from '@phyllome/common';
import type { FavouriteDefinition } from './types';

const irrigGraph: FavouriteDefinition = {
  irrigPressuresSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pressures_supply`,
    color: colors.earth1,
    field: 'pressures_supply',
    brokerId,
    deviceId,
  }),
  irrigPressuresReturn: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pressures_return`,
    color: colors.earth3,
    field: 'pressures_return',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  irrigLevelsSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/levels_supply`,
    color: colors.ocean,
    field: 'levels_supply',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  irrigLevelsSump: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/levels_sump`,
    color: colors.earth1,
    field: 'levels_sump',
    brokerId,
    deviceId,
  }),
  irrigLevelsReturn: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/levels_return`,
    color: colors.river,
    field: 'levels_return',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  irrigPumpsFertigation: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps_fertigation`,
    color: colors.earth1,
    field: 'pumps_fertigation',
    brokerId,
    deviceId,
  }),
  irrigValvesOzone: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves_ozone`,
    color: colors.earth1,
    field: 'valves_ozone',
    brokerId,
    deviceId,
  }),
  irrigOzoneGenerator: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/ozone_generator`,
    color: colors.earth1,
    field: 'ozone_generator',
    brokerId,
    deviceId,
  }),
  irrigPumpsSump: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps_sump`,
    color: colors.earth1,
    field: 'pumps_sump',
    brokerId,
    deviceId,
  }),
  irrigPumpsSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps_supply`,
    color: colors.earth1,
    field: 'pumps_supply',
    brokerId,
    deviceId,
  }),
  irrigPumpsTransfer: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/pumps_transfer`,
    color: colors.earth1,
    field: 'pumps_transfer',
    brokerId,
    deviceId,
  }),
  irrigValvesDischarge: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves_discharge`,
    color: colors.earth1,
    field: 'valves_discharge',
    brokerId,
    deviceId,
  }),
  irrigValvesTransfer: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves_transfer`,
    color: colors.earth1,
    field: 'valves_transfer',
    brokerId,
    deviceId,
  }),
  irrigValvesMains: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves_mains`,
    color: colors.earth1,
    field: 'valves_mains',
    brokerId,
    deviceId,
  }),
  irrigValvesReturn: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves_return`,
    color: colors.earth1,
    field: 'valves_return',
    brokerId,
    deviceId,
  }),
  irrigValvesSupply: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/valves_supply`,
    color: colors.earth1,
    field: 'valves_supply',
    brokerId,
    deviceId,
  }),
  irrigMode: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'irrigation',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[IRRIG] ${deviceId}/mode`,
    color: colors.earth1,
    field: 'mode',
    brokerId,
    deviceId,
    mapping: {
      '0': 'Off',
      '1': 'Auto',
      '2': 'Manual',
    },
  }),
};

export default irrigGraph;
