import type { GuardDeviceState } from '@phyllome/common';
import type { FromFirebase } from '../../types/types';
import LatestGuardInfo from './LatestGuardInfo';
import FormGuardAlarm from './forms/FormGuardAlarm';
import GenericPing from '../Generic/control/GenericPing';

const ControlGuard = ({
  latest,
}: {
  latest: FromFirebase<GuardDeviceState>;
}) => {
  return (
    <>
      <LatestGuardInfo latest={latest} />
      <FormGuardAlarm latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="guard"
        />
      </div>
    </>
  );
};

export default ControlGuard;
