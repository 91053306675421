import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../../../components/ReactJson/ReactJson';
import dateToString, {
  dateFormatTypes,
} from '../../../../helpers/dateToString';
import useAction from '../../../../data/hooks/useAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import useControlAction from './hooks/useControlAction';
import { howLongAgoInEnglish } from '../../../../helpers/FStimeAgo';
import { colors } from '@phyllome/common';

type ISideBarEntryTaskProps = {
  taskId: string;
};

const SideBarEntryTask: FC<ISideBarEntryTaskProps> = ({ taskId }) => {
  const controlRestart = useAction('queueRestart');
  const controlDelete = useAction('queueDelete');

  const task = useControlAction(taskId);

  if (task === undefined) {
    return (
      <>
        <AppDrawerTitle title={taskId} />
        <Box p={2}>
          <PaperSection heading="Hardware task status">
            <Alert severity="warning">
              <AlertTitle>Hardware change id no longer valid</AlertTitle>
              The hardware change you are looking for no longer exists. This
              could be due to a poorly formatted URL or the hardware change has
              been successfully completed and no longer exists in the queue
            </Alert>
          </PaperSection>
        </Box>
      </>
    );
  }

  const removeEnabled =
    task.status === 'queued' ||
    task.status === 'timeout' ||
    task.status === 'waiting';

  const retryEnabled = task.status !== undefined && task.status === 'timeout';

  const paperSectionColor =
    task.status == 'timeout'
      ? colors.earth1
      : task.status === 'waiting'
        ? colors.budding
        : colors.night;

  if (!taskId) return null;
  return (
    <>
      <AppDrawerTitle title={task.id} />
      <Box p={2}>
        <PaperSection
          headerSx={{
            background: paperSectionColor,
          }}
          heading={'Hardware task status: ' + task.status}
          subheading={`Last Updated: ${dateToString(
            task.timestamp,
            dateFormatTypes.TRUNCATED,
          )} (${howLongAgoInEnglish(task.lastUpdated)} ago)`}
        >
          {/* <Box textAlign={"center"}>
            <ControlQueueStatus status={task.status} size="medium" />
          </Box> */}
          <Box mt={1} textAlign="center">
            <Typography variant="h6">
              {task.devicePath.replace(`${task.brokerId}/`, '')} {task.topicId}{' '}
            </Typography>
            <Typography variant="body1">{task.retryCount} retries</Typography>
          </Box>
          <Box mt={2} textAlign="center">
            <ButtonGroup variant="text" size="small">
              <Tooltip title="Remove task from the queue" arrow>
                <Button
                  variant="outlined"
                  value="remove"
                  disabled={!removeEnabled}
                  onClick={() => {
                    controlDelete.run({ id: task.id });
                  }}
                  color="earth3"
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Tooltip>
              <Tooltip
                title={retryEnabled ? 'Retry message' : 'Unable to retry'}
                arrow
              >
                <Button
                  variant={retryEnabled ? 'contained' : 'outlined'}
                  value="retry"
                  disabled={!retryEnabled}
                  onClick={() => {
                    controlRestart.run({
                      id: task.id,
                    });
                  }}
                  endIcon={<ReplayIcon />}
                >
                  retry
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </PaperSection>
        <PaperSection mt={2} heading="Payload">
          <ReactJson src={task.request} expandAll={true} />
        </PaperSection>
        <PaperSection mt={2} heading="Pending Hardware Task Details">
          <ReactJson src={task} expandAll={true} />
        </PaperSection>
      </Box>
    </>
  );
};

export default SideBarEntryTask;
