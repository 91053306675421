import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import showNumber from '../../helpers/showNumber';
import { round, formatThousands } from '../../helpers/showNumber';

function ccyFormat(num: number, numDecimals = 2) {
  return `${num.toFixed(numDecimals)}`;
}

type ConsumableTableDataType = {
  label: string;
  amount?: number;
  unit?: string;
  unitCost: number;
};
export type ConsumableDataType = {
  nutrientA: number;
  nutrientB: number;
  nutrientPeroxide: number;
  nutrientAcid: number;
  nutrientBase: number;
  energy: number;
  water: number;
  co2: number;
  seeds: number;
};

const ConsumablesTable = ({
  consumableData,
  consumableCosts,
  totalTrays,
}: {
  consumableData?: ConsumableDataType;
  consumableCosts: ConsumableDataType;
  totalTrays: number;
}) => {
  const ConsumableDataMap: ConsumableTableDataType[] = [
    {
      label: 'Energy',
      amount: consumableData?.energy,
      unit: 'kWh',
      unitCost: consumableCosts.energy,
    },
    {
      label: 'Water',
      amount: consumableData?.water,
      unit: 'L',
      unitCost: consumableCosts.water,
    },
    {
      label: 'Nutrient A',
      amount: (consumableData?.nutrientA || 0) / 1000,
      unit: 'L',
      unitCost: consumableCosts.nutrientA * 1000,
    },
    {
      label: 'Nutrient B',
      amount: (consumableData?.nutrientB || 0) / 1000,
      unit: 'L',
      unitCost: consumableCosts.nutrientB * 1000,
    },
    {
      label: 'Peroxide',
      amount: (consumableData?.nutrientPeroxide || 0) / 1000,
      unit: 'L',
      unitCost: consumableCosts.nutrientPeroxide * 1000,
    },
    {
      label: 'Acid',
      amount: (consumableData?.nutrientAcid || 0) / 1000,
      unit: 'L',
      unitCost: consumableCosts.nutrientAcid * 1000,
    },
    // {
    //   label: "Base",
    //   consumed: consumableData?.nutrients,
    //   unit: "mL",
    //   unitCost: ConsumablesCost.nutrientBase,
    // },
    {
      label: 'CO2',
      amount: consumableData?.co2,
      unit: 'kg',
      unitCost: consumableCosts.co2,
    },
    {
      label: 'Seeds',
      amount: consumableData?.seeds,
      unitCost: consumableCosts.seeds,
    },
  ];

  let totalCost = 0;

  console.log('ConsumableDataMap', ConsumableDataMap);
  return (
    <TableContainer>
      <Table aria-label="Sprinkler Schedule" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Consumed</TableCell>
            <TableCell>Unit cost</TableCell>
            <TableCell>Total Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ConsumableDataMap.map((consumable, index) => {
            if (consumable.amount && consumable.unitCost)
              totalCost += consumable.amount * consumable.unitCost;
            return (
              <TableRow key={index}>
                <TableCell>{consumable.label}</TableCell>
                <TableCell>
                  {showNumber(consumable.amount, round(2), formatThousands)}{' '}
                  {consumable.unit && consumable.unit}
                </TableCell>
                <TableCell>${ccyFormat(consumable.unitCost, 6)}</TableCell>
                <TableCell>
                  $
                  {consumable.amount &&
                    consumable.unitCost &&
                    ccyFormat(consumable.amount * consumable.unitCost)}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell>
              <strong>Total</strong>
            </TableCell>
            <TableCell>
              <strong>${ccyFormat(totalCost)}</strong>
            </TableCell>
          </TableRow>
          {totalTrays > 1 && (
            <>
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell>Productive Trays</TableCell>
                <TableCell>{totalTrays}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell>
                  <strong>Cost per Tray</strong>
                </TableCell>
                <TableCell>
                  <strong>${ccyFormat(totalCost / totalTrays)}</strong>
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConsumablesTable;
