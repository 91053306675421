import { styled } from '@mui/material';
import { colors } from '@phyllome/common';

export const DrawerHeader = styled('div')(({ theme }) => ({
  backgroundColor: colors.night,
  paddingLeft: theme.spacing(2),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default DrawerHeader;
