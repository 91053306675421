import type { LightingDeviceState } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import Stacker from '../../components/Stacker/Stacker';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import type { FromFirebase } from '../../types/types';
import Graph from '../../components/Favourites/Graph';

const GraphLighting = ({
  latest,
}: {
  latest: FromFirebase<LightingDeviceState>;
}) => {
  const { deviceId, brokerId } = latest;

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <Graph
          name="lightingCurrentA"
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name="lightingCurrentB"
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name="lightingCurrentC"
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph name="lightingMode" brokerId={brokerId} deviceId={deviceId} />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphLighting;
