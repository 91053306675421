import { useContext } from 'react';
import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import ScaffoldRack from '../../../components/factoryui/ScaffoldRack/ScaffoldRack';
import NDSRack from '../../../components/factoryui/elements/NDS/NDSRack';
import { Box } from '@mui/material';

const FactoryUI = () => {
  const { brokerId } = useContext(FarmContext);

  return (
    <ContainerPrimary
      title="FactoryUI"
      icon={<DeviceTypeToIcon deviceType="tray" />}
    >
      <Box sx={{ pt: 1 }}>
        <ScaffoldRack
          column={1}
          onClick={(row, shelf, column) => {
            console.log('WE CLICK', row, shelf, column);
          }}
        >
          <NDSRack brokerId={brokerId} column={1} />
        </ScaffoldRack>
      </Box>
      <Box sx={{ pt: 1 }}>
        <ScaffoldRack column={2}>
          <NDSRack brokerId={brokerId} column={2} />
        </ScaffoldRack>
      </Box>{' '}
      <Box sx={{ pt: 1 }}>
        <ScaffoldRack column={3}>
          <NDSRack brokerId={brokerId} column={3} />
        </ScaffoldRack>
      </Box>{' '}
      <Box sx={{ pt: 1 }}>
        <ScaffoldRack column={4}>
          <NDSRack brokerId={brokerId} column={4} />
        </ScaffoldRack>
      </Box>
    </ContainerPrimary>
  );
};

export default FactoryUI;
