import GenericPing from '../Generic/control/GenericPing';
import type { FromFirebase } from '../../types/types';
import type { TransferDeviceState } from '@phyllome/common';
import LatestTransferInfo from './LatestTransferInfo';
import FormTransferManualConveyors from './forms/FormTransferManualConveyors';
import FormTransferManualActuators from './forms/FormTransferManualActuators';

const ControlAir = ({
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {
  return (
    <div>
      <LatestTransferInfo latest={latest} />
      <FormTransferManualConveyors state={latest} />
      <FormTransferManualActuators state={latest} />
      <GenericPing
        deviceId={latest.deviceId}
        brokerId={latest.brokerId}
        deviceType="transfer"
      />
    </div>
  );
};

export default ControlAir;
