import type { FertigatorDeviceState } from '@phyllome/common';
import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';
import TimeSlider from '../../components/TimeSlider/TimeSlider';
import type { FromFirebase } from '../../types/types';
import Graph from '../../components/Favourites/Graph';
import { AverageInfo } from '../../components/AverageTimeSeries/AverageInfo';
import Stacker from '../../components/Stacker/Stacker';

const GraphFertigator = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const { deviceId, brokerId } = latest;

  const averageItems = [
    {
      field: 'sample_ec',
      label: 'EC',
      sup: 'mS/cm',
    },
    {
      field: 'sample_ph',
      label: 'pH',
      sup: ' ', // space required to keep vertical alignment
    },
    {
      field: 'sample_temperature',
      label: 'Temperature',
      sup: '\u00b0C',
    },
    {
      field: 'sample_do',
      label: 'DO',
      sup: '%',
    },
  ];

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        <AverageInfo
          latest={latest}
          deviceType="fertigator"
          items={averageItems}
        />
        <Graph name="fertSampleEC" brokerId={brokerId} deviceId={deviceId} target={latest.ec_setpoint}/>
        <Graph name="fertSamplePH" brokerId={brokerId} deviceId={deviceId} target={latest.acid_setpoint}/>
        <Graph name="fertSampleDO" brokerId={brokerId} deviceId={deviceId} />
        <Graph name="fertSampleORP" brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name="fertSampleTemperature"
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name="fertDispensedNutrientA"
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph
          name="fertDispensedNutrientB"
          brokerId={brokerId}
          deviceId={deviceId}
        />
        <Graph name="fertDispensedAcid" brokerId={brokerId} deviceId={deviceId} />
        <Graph
          name="fertDispensedPeroxide"
          brokerId={brokerId}
          deviceId={deviceId}
        />
      </Stacker>
    </ChartProvider>
  );
};

export default GraphFertigator;
