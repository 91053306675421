import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Alert, AlertTitle, Button } from '@mui/material';
import { useContext } from 'react';
import useNavSide from '../../../data/hooks/useNavSide';
import { Box } from '@mui/material';
import { useEndpointSeasonDelete } from '../../../data/endpointHooks/endpointHooks';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import useSeason from './useSeason';
import SideBarSeasonInfo from './components/SideBarSeasonInfo';

const SeasonsSidebarDelete = ({ id }: { id: string }) => {
  const seasonData = useSeason(id);
  const deleteSeason = useEndpointSeasonDelete();
  const { brokerId } = useContext(FarmContext);

  const navSide = useNavSide();

  if (!seasonData) return (<Alert severity="error">Season not found</Alert>);
  return (
    <>
      <AppDrawerTitle title="Action Required" />
      <Box p={1}>
        <SideBarSeasonInfo
          seasonData={seasonData}
          brokerId={brokerId}
          seasonId={id}
        />
      </Box>
      <Box p={2}>
        <Alert severity="error" variant="filled">
          <AlertTitle>Confirm Deletion</AlertTitle>
          <p>Selected season: "{seasonData?.name}"</p>
          <p>Are you sure you want to delete this season? All tray data and history with this season will be permanently deleted.</p>
          <p>This cannot be undone</p>
          <Box pt={2}>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                deleteSeason.run({ brokerId, id }).then(() => navSide());
              }}
            >
              {deleteSeason.status === 'RUNNING' && 'Destroying'}
              {deleteSeason.status !== 'RUNNING' && 'Delete Season'}
            </Button>{' '}
            <Button
              variant="contained"
              onClick={() => {
                navSide();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Alert>
      </Box>
    </>
  );
};

export default SeasonsSidebarDelete;
